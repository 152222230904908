import React, { Component } from 'react'
import { CommonEditButton, DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import { withTranslation } from 'react-i18next';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import ShowComponents from '../Common/ShowComponent';
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';
import RepeatIcon from '@mui/icons-material/Repeat';

class DocCounsellingLeft extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDeleteCliked: false,
      SelectedList: {},
      PatientData: this.props?.PatientData,
      clinicId: localGetItem('SelectedDoctorClinicId'),
    }
  }

  componentDidUpdate(prevprops) {
    if (prevprops.GetAPiCall !== this.props.GetAPiCall && this.props.GetAPiCall) { this.GetCounsellingData() }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  GetCounsellingData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_SURGERY_DETAILS + `?patient_id=${this.props.PatientData?.patient_id}&appointment_id=${this.props.PatientData?.appointment_id}&clinic_id=${this.state.clinicId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              counsellorData: response?.data?.data,
              transmit: response?.data?.data?.surgery_details?.id ? true : false,
              status: response?.data?.data?.surgery_details?.status
            }, () => {
              this.setState({
                TansmitDisable: response?.data?.data?.surgery_details?.status === "Transmitted" || response?.data?.data?.surgery_details?.status === "Approved" ? true : false
              })
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onEditHandler(data) {
    this.props.EditAllergyData(data.row)
  }
  onDeleteHandler(data) {
    try {
      this.setState({
        isDeleteCliked: true,
        SelectedList: data.row,
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  popupClose() {
    this.setState({ isDeleteCliked: false })
  }
  DeleteAllergy() {
    try {
      let data = {
        appointment_id: this.props?.PatientData?.appointment_id,
        tests_id: this.state.SelectedList?.id
      }
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_TEST_DELETE).
        then((response) => {
          if (response.data.status === "success") {
            this.successMessage(response.data.message)
            this.setState({ isDeleteCliked: false, SelectedList: {} }, () => { this.GetCounsellingData() })
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  transmitCounsellor = () => {
    try {
      let data = {
        appointment_id: this.props?.PatientData?.appointment_id,
        clinic_id: this.state.clinicId,
        patient_id: this.props?.PatientData?.patient_id,
        id: this.state.counsellorData?.surgery_details?.id,
        surgery_id: this.state.counsellorData?.surgery_details?.surgery_id,
        counsellor_id: this.state.transmitCounsellor?.counsellor_id ? this.state.transmitCounsellor?.counsellor_id : this.state?.counsellor_ID ? this.state.counsellor_ID : this.state.counsellorData?.surgery_details?.id,
        status: 'Transmitted'
      }
      this.setState({ postLoad: true })
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_SURGERY_DETAILS)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.GetCounsellingData()
            this.props.handleDisableTransfer(true) 
            this.setState({ postLoad: false, transmitCounsellor: null, transmit: false, openPopUp: false })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ postLoad: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ postLoad: false })
      this.errorMessage(error.message)
    }
  }

  onEditHandler(){
    this.props.EditCounsellingData(this.state.counsellorData)  
  }


  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  render() {
    try {
      const { t } = this.props
      let states = this.state.counsellorData?.surgery_details ? this.state.counsellorData?.surgery_details : {}
      return (
        <ShowComponents onIntersection={this.GetCounsellingData.bind(this)}>
          <Box height={'30vh'} overflow={'auto'}>
            <Box display={'flex'} justifyContent={'flex-end'}>
              <CommonEditButton size={"1.7vw"} disable={this.state.status === "Transmitted" || this.state.status === "Approved" || this.props.isLocked || Object.keys(states).length === 0} onClick={this.onEditHandler.bind(this)} />
              <Tooltip title={"Transmit to Counsellor"} arrow placement='top'>
                <Button
                  disabled={this.state.status === "Transmitted" || this.state.status === "Approved" || this.props.isLocked || Object.keys(states).length === 0}
                  onClick={() => { this.transmitCounsellor() }}
                  startIcon={<RepeatIcon />}
                  variant='outlined'
                  size='small'
                  sx={{ marginX: '0.5vw', marginBottom: '0.5vw', textTransform: 'capitalize' }}
                >Transmit</Button>
              </Tooltip>
            </Box>
            <Box>
              <Box className='eMed_Vitals_header_div' height={'4vh'}>
                <Typography fontSize={"0.9vw"} width={'15%'}>{'Surgery'}</Typography>
                <Typography fontSize={"0.9vw"} width={'15%'}>{'Package name'}</Typography>
                <Typography fontSize={"0.9vw"} width={'20%'}>{'Surgery date'}</Typography>
                <Typography fontSize={"0.9vw"} width={'25%'}>{'Surgery Time'}</Typography>
                <Typography fontSize={"0.9vw"} width={'25%'}>{'Surgeon name'}</Typography>
                <Typography fontSize={"0.9vw"} width={'25%'}>{'Anaesthetist'}</Typography>
              </Box>
              <Box className='eMed_Vitals_body_div' height={'4vh'}>
                <Typography fontSize={"0.9vw"} width={'15%'}>{states?.surgery_name ? `${states?.surgery_name}` : "-"}</Typography>
                <Typography fontSize={"0.9vw"} width={'15%'}>{states?.package_name ? `${states?.package_name}` : "-"}</Typography>
                <Typography fontSize={"0.9vw"} width={'20%'}>{states?.surgery_date ? `${states?.surgery_date}` : "-"}</Typography>
                <Typography fontSize={"0.9vw"} width={'25%'}>{states?.surgery_time ? `${states?.surgery_time}` : "-"}</Typography>
                <Typography fontSize={"0.9vw"} width={'25%'}>{states?.surgeon_name ? `${states?.surgeon_name}` : "-"}</Typography>
                <Typography fontSize={"0.9vw"} width={'25%'}>{states?.anaesthetist ? `${states?.anaesthetist}` : "-"}</Typography>
              </Box>
              <Box className='eMed_Vitals_header_div' height={'4vh'}>
                <Typography fontSize={"0.9vw"} width={'25%'}>{'Counsellor'}</Typography>
                <Typography fontSize={"0.9vw"} width={'25%'}>{'Amount(₹)'}</Typography>
                <Typography fontSize={"0.9vw"} width={'25%'}>{'Discount(₹)'}</Typography>
                <Typography fontSize={"0.9vw"} width={'25%'}>{'GST(%)'}</Typography>
                <Typography fontSize={"0.9vw"} width={'25%'}>{'Status'}</Typography>
                <Typography fontSize={"0.9vw"} width={'25%'}>{'Remarks'}</Typography>
              </Box>
              <Box className='eMed_Vitals_body_div' height={'4vh'}>
                <Typography fontSize={"0.9vw"} width={'25%'}>{states?.counsellor_name ? `${states?.counsellor_name}` : "-"}</Typography>
                <Typography fontSize={"0.9vw"} width={'25%'}>{states?.net_amount ? `₹ ${states?.net_amount}` : "-"}</Typography>
                <Typography fontSize={"0.9vw"} width={'25%'}>{states?.discount_amount ? `₹ ${states?.discount_amount}` : "-"}</Typography>
                <Typography fontSize={"0.9vw"} width={'25%'}>{states?.gst_percentage ? `${states?.gst_percentage} %` : "-"}</Typography>
                <Typography fontSize={"0.9vw"} width={'25%'}>{states?.status ? `${states?.status}` : "-"}</Typography>
                <Typography fontSize={"0.9vw"} width={'25%'}>{states?.notes ?
                  states?.notes?.length > 18 ?
                    <Tooltip placement='top' title={states?.notes} arrow>
                      <div>{states?.notes.slice(0, 18) + "..."}</div></Tooltip> :
                    states?.notes
                  : "-"}</Typography>
              </Box>
            </Box>
          </Box>
          {this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.isDeleteCliked ? <DeletePopup
            DeletTitle={`${t("deleteMsg")} ${t("Test")} ${this.state.SelectedList ? this.state.SelectedList?.display_label : "-"} ?`}
            deleteAlertPopupClose={this.popupClose.bind(this)}
            removeData={this.DeleteAllergy.bind(this)}
          /> : null}
        </ShowComponents>
      )
    }
    catch (e) {

    }
  }
}
export default withTranslation()(DocCounsellingLeft)

