import { Autocomplete, Box, Button, Chip, CircularProgress, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { CommonPatientCard, CommonPatientDetails, DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import Loader from '../../../../Components/Loader';
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { Colors } from '../../../../Styles/Colors';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import {
    Yes_Or_No, Made_Of_Delivery, Gestational_Age, Birth_Weight, Medical_Problems
} from '../../../../Utility/Constants';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import { DataGrid } from '@mui/x-data-grid';


class DocPidiatricHistory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            patientDetails: [],
            clinicId: '',
            appointmentId: '',
            patientId: '',
            pdHistory: {},
            yesorNoList: Yes_Or_No,
            MadeofDelivery: Made_Of_Delivery,
            Gestationalage: Gestational_Age,
            BirthWeight: Birth_Weight,
            Birth_WeightDup: Birth_Weight,
            MedicalProblems: Medical_Problems,

            //BIRTH HISTROY
            complication_Pregnancy: '',
            complication_Pregnancy_cmt: '',
            complication_afterbirth_cmt: '',
            complication_afterbirth: '',
            madeof_delivery: '',
            gestation_Age: '',
            birth_weight: '',
            development_cmt: '',
            feeding_His: '',
            addWeight: false,
            newWeight: '',
            birthHistoryBox: false,
            loading: false,
            selectedMedicalPros: [],

            // MEDICAL HISTORY 
            selectProbelm: null,
            medicalPro_cmt: '',
            selectedID: null,
            medicalHistory: [],
            isEdit: false,
            deletePop: false,
        }
    }

    componentDidMount() {
        let clinicId = getCachevalue('SelectedDoctorClinic')
        let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
        this.setState({
            clinicId: clinicId,
            appointmentId: appointmentId?.appointment_id,
            patientId: appointmentId?.patient_id
        }, () => {
            this.getPatientDetailData()
            this.getPDHistoryData()
            this.getPDMedicalHis()
        })
    }

    getPatientDetailData = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            patientDetails: response?.data?.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getPDHistoryData = () => {
        try {
            this.setState({ isLoader: true })
            RestAPIService.getAll(Serviceurls.PD_HISTORY_GET + `?patient_id=${this.state.patientId}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({ isLoader: false })
                        let data = response?.data?.data
                        this.setState({
                            pdHistory: data,
                            complication_Pregnancy: data?.pregnancy_complication ? "Yes" : "No",
                            complication_Pregnancy_cmt: data?.pregnancy_comp_desc,
                            complication_afterbirth: data?.after_birth_complication ? "Yes" : "No",
                            complication_afterbirth_cmt: data?.after_birth_comp_desc,
                            nenatal_Period: data?.neonatal_period ? "Yes" : "No",
                            nenatal_Period_cmt: data?.neonatal_period_reason,
                            development_cmt: data?.development,
                            feeding_His: data?.feeding_history,
                            madeof_delivery: data?.mode_of_delivery,
                            gestation_Age: data?.gestational_age,
                            birth_weight: data?.birth_weight,
                            birthHistoryBox: data?.pregnancy_complication ? true : false,
                        })
                    }
                }).catch(error => {
                    if (error?.response?.data?.message) {
                        this.setState({ isLoader: false })
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.setState({ isLoader: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ isLoader: false })
            this.errorMessage(error.message)
        }
    }

    postPDHistoryData = () => {
        try {
            var states = this.state
            if (states.complication_Pregnancy &&
                states.complication_afterbirth &&
                states.nenatal_Period &&
                states.madeof_delivery &&
                states.gestation_Age &&
                states.birth_weight) {
                var data = {
                    "additional_notes": "",
                    "after_birth_comp_desc": states.complication_afterbirth_cmt,
                    "after_birth_complication": states.complication_afterbirth === "Yes" ? true : false,
                    "birth_weight": states.birth_weight,
                    "development": states.development_cmt,
                    "feeding_history": states.feeding_His,
                    "gestational_age": states.gestation_Age,
                    "mode_of_delivery": states.madeof_delivery,
                    "neonatal_period": states.nenatal_Period === "Yes" ? true : false,
                    "neonatal_period_reason": states.nenatal_Period_cmt,
                    "patient_id": states.patientId,
                    "pregnancy_comp_desc": states.complication_Pregnancy_cmt,
                    "pregnancy_complication": states.complication_Pregnancy === "Yes" ? true : false,
                }
                RestAPIService.create(data, Serviceurls.PD_HISTORY_GET)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response?.data?.message)
                            this.getPDHistoryData()
                            let data = [
                                { label: "2kg", value: 2 },
                                { label: "2.5kg", value: 2.5 },
                                { label: "3kg", value: 3 },
                                { label: "3.5kg", value: 3.5 }
                            ]
                            this.setState({
                                BirthWeight: data
                            })
                        }
                    }).catch(error => {
                        if (error?.response?.data?.message) {
                            this.errorMessage(error?.response?.data?.message)
                        } else {
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage('Please Select the Mantetory Feilds')
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getPDMedicalHis = () => {
        try {
            RestAPIService.getAll(Serviceurls.PD_MEDICAL_HIS + `?patient_id=${this.state.patientId}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            medicalHistory: response?.data?.data
                        })
                    }
                }).catch(error => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postPDMedicalHis = () => {
        try {
            var states = this.state

            var data = {
                'description': states.medicalPro_cmt,
                'patient_id': states.patientId,
                'surgery': states.selectProbelm,
            }
            if (this.state.selectedID) {
                data['id'] = this.state.selectedID
            }
            RestAPIService.create(data, Serviceurls.PD_MEDICAL_HIS)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.successMessage(response?.data?.message)
                        this.getPDMedicalHis()
                        this.onClearMedicalHis()
                    }
                }).catch(error => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    deletePDMedicalHis = () => {
        try {

            let data = {
                problem_id: this.state.selectedID,
                patient_id: this.state.patientId
            }
            this.setState({ removeData: true })
            this.LoaderFunction(true)
            RestAPIService.create(data, Serviceurls.PD_MEDICAL_HIS_DEL)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({ removeData: false })
                        this.LoaderFunction(false)
                        this.successMessage(response.data.message)
                        this.onClearMedicalHis()
                        this.getPDMedicalHis()
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ removeData: false })
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ removeData: false })
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ removeData: false })
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }


    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    onClearBirthHis = () => {
        try {
            this.setState({
                complication_Pregnancy: '',
                complication_Pregnancy_cmt: '',
                complication_afterbirth: '',
                complication_afterbirth_cmt: '',
                nenatal_Period: '',
                nenatal_Period_cmt: '',
                madeof_delivery: '',
                gestation_Age: '',
                birth_weight: '',
                newWeight: '',
                addWeight: false,
                development_cmt: '',
                feeding_His: '',
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onClearMedicalHis = () => {
        try {
            this.setState({
                selectProbelm: '',
                medicalPro_cmt: '',
                selectedID: null,
                isEdit: false,
                deletePop: false
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderBirthHistoryBox = () => {
        var states = this.state
        try {
            return (
                <Box id='diabetic' component={'div'} border={'1px solid lightgray'} mb={'1vw'} sx={{ backgroundColor: Colors.white }}>
                    <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} pl={'0.5vw'} alignItems={'center'}>
                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Birth History'}</Typography>
                        {this.state.birthHistoryBox ?
                            <Button className='Common_Btn_Min_Width'
                                onClick={() => this.setState({ birthHistoryBox: false })}
                            >
                                <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.5vw'} width={'1.5vw'} />
                            </Button> :
                            <Box display={'flex'}>
                                <Button variant='contained'
                                    disabled={this.state.loading}
                                    sx={{ textTransform: 'capitalize', height: '2vw', width: '5vw', mr: '1vw' }}
                                    onClick={() => this.postPDHistoryData()}
                                >Add</Button>
                                {Object.keys(this.state.pdHistory).length > 0 ? null : <Button variant='outlined'
                                    sx={{ textTransform: 'capitalize', height: '2vw', width: '5vw', mr: '1vw' }}
                                    onClick={() => this.onClearBirthHis()}
                                >Clear</Button>}
                            </Box>
                        }
                    </Box>
                    {this.state.loading ? <Box component={'div'} pl={'0.5vw'} pr={'0.5vw'} mb={'1vw'} height={'20vw'} display={'flex'} justifyContent={'center'} alignItems={'center'}><CircularProgress color='primary' /></Box> :
                        <Box component={'div'} pl={'0.5vw'} pr={'0.5vw'} mb={'1vw'}>
                            <Box component={'div'} mt={'0.5vw'} width={'100%'}>
                                <Box component={'div'} display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
                                    <Box component={'div'} width={'25vw'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Any complications during pregnancy ? *'}</Typography>
                                        <Box component={'div'}>
                                            {this.state.yesorNoList?.map((item, index) => {
                                                return (
                                                    <Chip
                                                        disabled={this.state.birthHistoryBox}
                                                        className={`eMed_chip ${item.label === this.state.complication_Pregnancy ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                        label={item.label}
                                                        variant={item.label === this.state.complication_Pregnancy ? 'contained' : 'outlined'}
                                                        key={index}
                                                        onClick={() => {
                                                            if (item.label === this.state.complication_Pregnancy) {
                                                                this.setState({ complication_Pregnancy: '' })
                                                            } else {
                                                                this.setState({ complication_Pregnancy: item?.value })
                                                            }
                                                        }}
                                                        // color={'primary'}
                                                        clickable
                                                    />
                                                )
                                            })}
                                        </Box>
                                        <TextField
                                            disabled={this.state.complication_Pregnancy === 'No' || this.state.birthHistoryBox ? true : false}
                                            multiline
                                            rows={2}
                                            label='comments'
                                            sx={{ width: '25vw', mt: '0.5vw' }}
                                            value={this.state.complication_Pregnancy_cmt}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                if (value?.length <= 250) {
                                                    this.setState({ complication_Pregnancy_cmt: e.target.value })
                                                } else {
                                                    this.errorMessage('Allowed Only 250 Characters')
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Box component={'div'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Complications after birth ? *'}</Typography>
                                        <Box component={'div'}>
                                            {this.state.yesorNoList?.map((item, index) => {
                                                return (
                                                    <Chip
                                                        disabled={this.state.birthHistoryBox}
                                                        className={`eMed_chip ${item.label === this.state.complication_afterbirth ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                        label={item.label}
                                                        variant={item.label === this.state.complication_afterbirth ? 'contained' : 'outlined'}
                                                        key={index}
                                                        onClick={() => {
                                                            if (item.label === this.state.complication_afterbirth) {
                                                                this.setState({ complication_afterbirth: '' })
                                                            } else {
                                                                this.setState({ complication_afterbirth: item?.label })
                                                            }
                                                        }}
                                                        // color={'primary'}
                                                        clickable
                                                    />
                                                )
                                            })}
                                        </Box>
                                        <TextField
                                            disabled={this.state.complication_afterbirth === 'No' || this.state.birthHistoryBox ? true : false}
                                            multiline
                                            rows={2}
                                            label='comments'
                                            sx={{ width: '25vw', mt: '0.5vw' }}
                                            value={this.state.complication_afterbirth_cmt}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                if (value?.length <= 250) {
                                                    this.setState({ complication_afterbirth_cmt: e.target.value })
                                                } else {
                                                    this.errorMessage('Allowed Only 250 Characters')
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Box component={'div'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Is there any admission during the Neonatal period ? *'}</Typography>
                                        <Box component={'div'}>
                                            {this.state.yesorNoList?.map((item, index) => {
                                                return (
                                                    <Chip
                                                        disabled={this.state.birthHistoryBox}
                                                        className={`eMed_chip ${item.label === this.state.nenatal_Period ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                        label={item.label}
                                                        variant={item.label === this.state.nenatal_Period ? 'contained' : 'outlined'}
                                                        key={index}
                                                        onClick={() => {
                                                            if (item.label === this.state.nenatal_Period) {
                                                                this.setState({ nenatal_Period: '' })
                                                            } else {
                                                                this.setState({ nenatal_Period: item?.label })
                                                            }
                                                        }}
                                                        // color={'primary'}
                                                        clickable
                                                    />
                                                )
                                            })}
                                        </Box>
                                        <TextField
                                            disabled={this.state.nenatal_Period === 'No' || this.state.birthHistoryBox ? true : false}
                                            multiline
                                            rows={2}
                                            label='comments'
                                            sx={{ width: '25vw', mt: '0.5vw' }}
                                            value={this.state.nenatal_Period_cmt}
                                            onChange={(e) => {
                                                let value = e.target.value
                                                if (value?.length <= 250) {
                                                    this.setState({ nenatal_Period_cmt: e.target.value })
                                                } else {
                                                    this.errorMessage('Allowed Only 250 Characters')
                                                }
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box component={'div'} mt={'1vw'} display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
                                <Box component={'div'} width={'25vw'}>
                                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Made of Delivery *'}</Typography>
                                    <Box component={'div'}>
                                        {this.state.MadeofDelivery?.map((item, index) => {
                                            return (
                                                <Chip
                                                    disabled={this.state.birthHistoryBox}
                                                    className={`eMed_chip ${item.value === this.state.madeof_delivery ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                    label={item.label}
                                                    variant={item.value === this.state.madeof_delivery ? 'contained' : 'outlined'}
                                                    key={index}
                                                    onClick={() => {
                                                        if (item.value === this.state.madeof_delivery) {
                                                            this.setState({ madeof_delivery: '' })
                                                        } else {
                                                            this.setState({ madeof_delivery: item?.value })
                                                        }
                                                    }}
                                                    // color={'primary'}
                                                    clickable
                                                />
                                            )
                                        })}
                                    </Box>
                                </Box>
                                <Box component={'div'} width={'25vw'}>
                                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Gestational Age *'}</Typography>
                                    <Box component={'div'}>
                                        {this.state.Gestationalage?.map((item, index) => {
                                            return (
                                                <Chip
                                                    disabled={this.state.birthHistoryBox}
                                                    className={`eMed_chip ${item.value === this.state.gestation_Age ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                    label={item.label}
                                                    variant={item.value === this.state.gestation_Age ? 'contained' : 'outlined'}
                                                    key={index}
                                                    onClick={() => {
                                                        if (item.value === this.state.gestation_Age) {
                                                            this.setState({ gestation_Age: '' })
                                                        } else {
                                                            this.setState({ gestation_Age: item?.value })
                                                        }
                                                    }}
                                                    // color={'primary'}
                                                    clickable
                                                />
                                            )
                                        })}
                                    </Box>
                                </Box>
                                <Box component={'div'} width={'25vw'}>
                                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Birth Weight (kgs) *'}</Typography>
                                    <Box component={'div'}>
                                        {this.state.BirthWeight?.map((item, index) => {
                                            return (
                                                <Chip
                                                    disabled={this.state.birthHistoryBox}
                                                    className={`eMed_chip ${item.value === this.state.birth_weight ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                                    label={item.label}
                                                    variant={item.value === this.state.birth_weight ? 'contained' : 'outlined'}
                                                    key={index}
                                                    onClick={() => {
                                                        if (item.value === this.state.birth_weight) {
                                                            this.setState({ birth_weight: '' })
                                                        } else {
                                                            this.setState({ birth_weight: item?.value })
                                                        }
                                                    }}
                                                    // color={'primary'}
                                                    clickable
                                                />
                                            )
                                        })}
                                    </Box>
                                    {this.state.addWeight ?
                                        <Box display={'flex'} flexDirection={'row'}>
                                            <TextField
                                                label={(`Add`)}
                                                size="small"
                                                style={{ marginTop: "0.5vw", width: "10vw" }}
                                                variant="outlined"
                                                value={states.newWeight}
                                                onChange={(event) => {
                                                    let value = event.target.value
                                                    let Number = CommonValidation.DecimalNumber(value)
                                                    if (Number && +value <= 10) {
                                                        this.setState({ newWeight: value })
                                                    }
                                                }}
                                            />
                                            <div style={{ marginLeft: "0.5vw", marginTop: "0.65vw", cursor: "pointer" }}
                                                onClick={() => {
                                                    if (this.state.newWeight) {
                                                        let data = { label: `${this.state.newWeight}kg`, value: +this.state.newWeight }
                                                        states.BirthWeight.push(data)
                                                        this.setState({ states }, () => { this.setState({ addWeight: false, newWeight: "", birth_weight: +this.state.newWeight }) })
                                                    }
                                                }}>
                                                <img className="eMed_Tick_img" style={{ height: "2vw", width: "2vw" }} src={ImagePaths.Tick.default} alt={"Tick"} />
                                            </div>
                                            <div style={{ marginLeft: "0.5vw", marginTop: "0.65vw", cursor: "pointer" }}
                                                onClick={() => {
                                                    this.setState({ addWeight: false, newWeight: "" })
                                                }}>
                                                <img className="eMed_Tick_img" style={{ height: "2vw", width: "2vw" }} src={ImagePaths.CancelIcon.default} alt={"Close"} />
                                            </div>
                                        </Box>
                                        : <Button
                                            sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                            variant='contained'
                                            disabled={this.state.birth_weight}
                                            onClick={() => { this.setState({ addWeight: true, newWeight: "" }) }}
                                        >Add</Button>}
                                </Box>
                            </Box>
                            <Box component={'div'} mt={'0.5vw'} border={'1px solid lightgray'}></Box>
                            <Box component={'div'} mt={'0.5vw'} display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
                                <Box component={'div'} width={'25vw'}>
                                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Development'}</Typography>
                                    <TextField
                                        multiline
                                        rows={2}
                                        disabled={this.state.birthHistoryBox}
                                        label='comments'
                                        sx={{ width: '25vw', mt: '0.5vw' }}
                                        value={this.state.development_cmt}
                                        onChange={(e) => {
                                            let value = e.target.value
                                            if (value?.length <= 250) {
                                                this.setState({ development_cmt: e.target.value })
                                            } else {
                                                this.errorMessage('Allowed Only 250 Characters')
                                            }
                                        }}
                                    />
                                </Box>
                                <Box component={'div'} width={'25vw'}>
                                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Feeding History'}</Typography>
                                    <TextField
                                        multiline
                                        rows={2}
                                        disabled={this.state.birthHistoryBox}
                                        label='comments'
                                        sx={{ width: '25vw', mt: '0.5vw' }}
                                        value={this.state.feeding_His}
                                        onChange={(e) => {
                                            let value = e.target.value
                                            if (value?.length <= 250) {
                                                this.setState({ feeding_His: e.target.value })
                                            } else {
                                                this.errorMessage('Allowed Only 250 Characters')
                                            }
                                        }}
                                    />
                                </Box>
                                <Box component={'div'} width={'25vw'}></Box>
                            </Box>
                        </Box>}
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }


    renderMedicalProblemBox = () => {
        var states = this.state
        try {
            return (
                <Box id='nutrition' component={'div'} border={'1px solid lightgray'} mb={'1vw'} sx={{ backgroundColor: Colors.white }}>
                    <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} pl={'0.5vw'} alignItems={'center'}>
                        <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{'Medical History'}</Typography>
                    </Box>
                    <Box component={'div'} ml={'0.5vw'} mt={'0.5vw'} display={'flex'}>
                        <Box width={'50vw'} display={'flex'} flexDirection={'row'}>
                            <Autocomplete
                                clearIcon
                                size='small'
                                freeSolo
                                disabled={this.state.selectedID}
                                sx={{ width: '20vw' }}
                                options={this.state.MedicalProblems.map((item) => item.label)}
                                value={this.state.selectProbelm}
                                autoComplete='off'
                                onChange={(event, newValue) => {
                                    this.setState({ selectProbelm: newValue })
                                }}
                                onInputChange={(event, newValue) => {
                                    this.setState({ selectProbelm: newValue })
                                }}
                                renderInput={(params) => <TextField {...params} label='Select Problems' />}
                            />

                            <TextField
                                size='small'
                                label='comments'
                                sx={{ width: '20vw', marginLeft: '1.5vw' }}
                                value={this.state.medicalPro_cmt}
                                onChange={(e) => {
                                    let value = e.target.value
                                    if (value?.length <= 250) {
                                        this.setState({ medicalPro_cmt: e.target.value })
                                    } else {
                                        this.errorMessage('Allowed Only 250 Characters')
                                    }
                                }}
                            />
                        </Box>
                        <Box width={'16vw'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <Button variant='contained' size='small' disabled={!this.state.selectProbelm}
                                sx={{ textTransform: 'capitalize', height: '2vw', width: '5vw', }}
                                onClick={() => this.postPDMedicalHis()}
                            >Add</Button>
                            <Button variant='outlined' size='small'
                                sx={{ textTransform: 'capitalize', height: '2vw', width: '5vw' }}
                                onClick={() => this.onClearMedicalHis()}
                            >Clear</Button>
                        </Box>
                    </Box>
                    <Box component={'div'} ml={'0.5vw'} mt={'0.5vw'} mb={'.5vw'} width={'90vw'} height={'20vw'} overflow={'scroll'}>
                        {this.renderTbl()}
                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onEditHandler = (item) => {
        this.setState({
            selectedID: item.id,
            selectProbelm: item.surgery,
            medicalPro_cmt: item.description,
            isEdit: true,
        })
    }
    onDeleteHandler = (item) => {
        try {
            if (!this.state.isEdit) {
                this.setState({
                    deletePop: true,
                    selectedID: item?.id
                })
            } else {
                this.errorMessage("You have clicked edit Button.So,You cannot able to delete")
            }

        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    popupClose = () => {
        try {
            this.setState({
                deletePop: false
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderTbl = () => {
        try {
            const columns = [
                {
                    field: 'surgery', headerName: 'Problem', flex: 0.35,
                    renderCell: (params) => (<Box>{params?.row?.surgery ? params?.row?.surgery?.length > 25 ?
                        <Tooltip placement='top' title={params?.row?.surgery}><div>{params?.row?.surgery.slice(0, 25) + '...'}</div></Tooltip> :
                        params?.row?.surgery : '-'}</Box>)
                },
                {
                    field: 'description', headerName: 'Comments', flex: 0.35,
                    renderCell: (params) => (<Box>{params?.row?.description ? params?.row?.description?.length > 30 ?
                        <Tooltip placement='top' title={params?.row?.description}><div>{params?.row?.description.slice(0, 30) + '...'}</div></Tooltip> :
                        params?.row?.description : '-'}</Box>)
                },
                {
                    field: 'action', headerName: 'Action', flex: 0.30, headerAlign: 'center', align: 'center',
                    renderCell: (params) => (<Box>
                        <Button className='Common_Btn_Min_Width'
                            onClick={() => this.onEditHandler(params?.row)}
                        >
                            <Box height={'1.5vw'} width={'1.5vw'} component={'img'} src={ImagePaths.Edit.default} />
                        </Button>
                        <Button className='Common_Btn_Min_Width'
                            onClick={() => this.onDeleteHandler(params?.row)}
                        >
                            <Box height={'1.5vw'} width={'1.5vw'} component={'img'} src={ImagePaths.Delete.default} />
                        </Button>
                    </Box>)
                }
            ]
            return (
                <DataGrid
                    rows={this.state.medicalHistory}
                    sx={{ backgroundColor: Colors.white }}
                    columns={columns}
                    getRowId={(row) => row?.id}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    // localeText={{
                    //   toolbarColumns: "",
                    //   toolbarDensity: "",
                    //   toolbarExport: "",
                    //   toolbarFilters: "",
                    //   toolbarExportPrint: ""
                    // }}
                    components={{
                        // Toolbar: CommonGridHeader.CommonToolBar,
                        NoRowsOverlay: () => (
                            <Box className='eMed_conf_nodata' height={'40vh'}>
                                {'No Records To Be Shown'}
                            </Box>
                        )
                    }}
                    onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                    onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                    rowsPerPageOptions={[10, 20, 30]}
                    pagination
                    hideFooter
                    headerHeight={40}
                    disableSelectionOnClick
                    loading={this.state.loading}
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        let attender = {
            lineOne: "Attender Name",
            lineTwo: `${this.state.patientDetails?.attender_name ? this.state.patientDetails?.attender_name :
                this.state.patientDetails?.caretaker_name ? this.state.patientDetails?.caretaker_name : "-"}`,
            lineFour: 'Contact Number',
            LineFive: `${this.state.patientDetails?.attender_mobile ? this.state.patientDetails?.attender_mobile :
                this.state.patientDetails?.caretaker_mobile ? this.state.patientDetails?.caretaker_mobile : "-"}`
        }
        return (
            <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'74vh'} p={'0.5vw'}>
                <Box component={'div'} display={'flex'}>
                    <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
                        <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} />
                    </Box>
                    {/* <CommonPatientCard details={attender} showDetailed={true} /> */}
                </Box>
                <Box component={'div'} mt={'0.5vw'} height={'61.5vh'} overflow={'scroll'}>
                    {this.renderBirthHistoryBox()}
                    {this.renderMedicalProblemBox()}
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.deletePop ? <DeletePopup
                    DeletTitle={`Are you sure you want to delete ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.deletePDMedicalHis.bind(this)}
                    disable={this.state.removeData}
                /> : null}
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}
export default DocPidiatricHistory;