import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../../ClinicalNotes/DoctorNotes.css'
import { CommonEditButton } from '../../../../../../Components/Common Components/CommonComponents';
import ToastMsg from '../../../../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../../../../Styles/Colors';
import RestAPIService from '../../../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../../../Utility/API/Serviceurls';
import CommonValidation from '../../../../../../Components/CommonFunctions/CommonValidation';
import { CheckAccessFunc } from '../../../../../../Components/CommonFunctions/CommonFunctions';
class ProvDiagnosisRight extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ProvDiagnosisText: "",
      withOutAppointment : this.props?.withOutAppointment,
    }
  }

  componentDidMount() {
    if (this.props.EditProvDiagnosisData !== "") {
      this.setState({
        ProvDiagnosisText: this.props.EditProvDiagnosisData,
      })
      this.props.ClearPropsData()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.EditProvDiagnosisData !== this.props.EditProvDiagnosisData && this.props.EditProvDiagnosisData !== "") {
      this.setState({
        ProvDiagnosisText: this.props.EditProvDiagnosisData,
      })
      this.props.ClearPropsData()
    }
  }

  ClearData() {
    this.setState({
      ProvDiagnosisText: ""
    })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  PostProvDiagnosis() {
    try {
      var states = this.state
      if (CommonValidation.removeSpace(states.ProvDiagnosisText) !== "") {
        var data = {
          "notes": CommonValidation.removeSpace(states.ProvDiagnosisText),
          "ip_admission_id": this.props?.IpPatientData?.id,
        }

        RestAPIService.create(data, Serviceurls.IP_DOCNOTES_PROVISIONAL_DIAG).
          then((response) => {
            if (response.data.status === "success") {
              this.successMessage(response.data.message)
              this.ClearData()
              this.props.CallgetApi('isProvDiagnosis')
            } else {
              this.errorMessage(response.data.message)
            }
          }).catch(error => {
            if (error?.response?.data?.status === "fail") {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      } else {
        this.errorMessage("Enter Provisional Diagnosis")
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  msgClose(){
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  render() {
    const { t } = this.props
    return (
      <Box>
        <Box height={"58.3vh"}>
          <Typography className='eMed_refdoc_title'>{t("ProvDiagnosis")}</Typography>
          <TextField
            size='small'
            className='eMed_DocNotes_TextFields'
            multiline={true}
            rows={9}
            label={t("ProvDiagnosis")}
            inputProps={{ maxLength: 1000 }}
            value={this.state.ProvDiagnosisText}
            onChange={(e) => {
              if (CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value) || e.target.value === "") {
                this.setState({ ProvDiagnosisText: e.target.value })
              }
            }}
          />
        </Box>
        <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
          <Button variant='outlined' size="small" disabled = {this.state.withOutAppointment?.is_ip} id="eMed_Config_btn" onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
          <Button variant='contained' size="small" disabled = {CheckAccessFunc("front_office", "In Patients","Clinical Notes", "Doctor Notes", "SubTab")?.editAccess ? this.state.withOutAppointment?.is_ip : true} id="eMed_Config_btn" onClick={() => { this.PostProvDiagnosis() }}>{t("Add")}</Button>
        </Stack>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}
export default withTranslation()(ProvDiagnosisRight)
