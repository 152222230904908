import { IconButton, Modal, Paper, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { ImagePaths } from '../../Utility/ImagePaths';
import { Box } from '@mui/system';
import '../Common Components/VoiceToText.css'

const VoiceToText = (props) =>{
     const [transcript, setTranscript] = useState('');
     let [listenMic, setListenMic] = useState(false);
    const [textStorage, setTextStorage] = useState('')
    let recognition = null;

    useEffect(()=>{
        converToTxt()
     },[listenMic])
    
    const converToTxt =() => {
        recognition = new window.webkitSpeechRecognition(); 
        recognition.lang = 'en-US';
        recognition.continuous = true;
        recognition.interimResults = true;
        recognition.onstart = () => {
            console.log('Listening started');
            setListenMic(true);
          };
          recognition.onend = () => {
            console.log('Listening stopped');
          };
          recognition.onresult = (event) => {
            let interimTranscript = '';
            for (let i = event.resultIndex; i < event.results.length; ++i) {
              if (event.results[i].isFinal) {
                setTranscript(prevTranscript => prevTranscript + event.results[i][0].transcript + ' ');
                setTextStorage(prevTranscript => prevTranscript + event.results[i][0].transcript + ' ');
              } else {
                interimTranscript += event.results[i][0].transcript + ' ';
              }
            }
            console.log('Transcript:', transcript);
          };
        if(recognition && listenMic === true){
          recognition.start();}  
        };
      
          if (recognition && listenMic === false) {
            recognition.stop(); 
          }
        
       const reset = () => {
         setTranscript("")
         setTextStorage("")
    }
    const handleMic = (transcript) => {
         setListenMic(listenMic ? false : true)        
         setTranscript(textStorage) 
         setTextStorage(transcript)
    }

     const ticker = () => {
        setTranscript("")
        setTextStorage("")
        props.voiceTextConverter(textStorage)
     }
     const handleClose =()=>{
         setTranscript("")
         setTextStorage("")
        props.listenPopUp()
     }

    return (
        <Modal open={true}>
            <Paper elevation={3} id="emedhub_popup_mainBox">
                <div style={{width: '40vw', height: '60vh'}}>
             <Box sx={{display:"flex", justifyContent:"flex-end",alignItems:"end"}}>
                <IconButton  color="primary"  curser="pointer" onClick={() => handleClose() } ><img style={{width:'2vw',height:'2vh'}}   src={ImagePaths.Close.default} alt='close' /></IconButton>
                </Box>
                     {listenMic ? 
                        transcript==""?
                        <Typography sx={{width:'100%', height:"46vh",display:"flex",alignItems:'center',textAlign:"center",justifyContent:"space-around",fontSize:"20px" ,overflowY:"scroll",/*border:"5px solid red"*/}}>{"Listening Now..."}</Typography>:
                        <Typography sx={{width:'100%', height:"46vh",fontSize:"15px",padding:"10px" ,overflow:"scroll"/*border:"5px solid red"*/ }}>{transcript}</Typography>
                      :transcript==""?<Typography sx={{width:'100%', height:"40vh",display:"flex",alignItems:'center',textAlign:"center",justifyContent:"space-around",fontSize:"20px",}}>{"Tap Mic to Speak"}</Typography>:
                                <TextField
                                    variant="standard" 
                                    sx={{width:'100%',height:"46vh",fontSize:"40px",overflowY:"scroll",padding:"10px"}}
                                    className="textarea"
                                    multiline
                                    type='text'
                                    value={textStorage}
                                    onChange={(e) => setTextStorage(e.target.value)}
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                />                   
                    } 
                    <div style={{display:"flex", justifyContent:"center",alignItems:"center"}} >  
                        {listenMic ? " " : <IconButton color="primary"  curser="pointer" ><img  style={{width:'4vw',height:'4vh'}} onClick={() => reset()} src={ImagePaths.reply.default} alt='reset' /></IconButton>}
                        {listenMic ? <IconButton className='eMed_voice_to_Text' color="primary" cursor="pointer">
                            <Box>
                                <img style={{ width: '7vw', height: '7vh', display:"flex", justifyContent:"center",alignItems:"center",marginBottom:"0vh" }} onClick={() => { handleMic(transcript) }} src={ImagePaths.Mic.default} alt='mic' />
                            </Box>
                        </IconButton> :
                            <IconButton color="primary" cursor="pointer">
                                <img style={{ width: '6vw', height: '6vh' }} onClick={() => { handleMic(transcript) }} src={ImagePaths.Mic.default} alt='mic' />
                            </IconButton>
                        }
                        {listenMic ? "" : <IconButton curser="pointer" color="primary" > <img style={{width:'3vw',height:'3vh'}} onClick={() => ticker()} src={ImagePaths.Check.default} alt='tick' /> </IconButton>}

                    </div>
                </div>
            </Paper>
        </Modal>

    )
}


export default VoiceToText