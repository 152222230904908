import { Box, Button, Drawer, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { DataGrid } from '@mui/x-data-grid'
import CommonGridHeader, { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents'
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { DateTime } from 'luxon'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Colors } from '../../../../Styles/Colors'
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation'
import { CurrencySymbol } from '../../../../Utility/Constants'
import dayjs from 'dayjs';

export default class AdvanceReports extends Component {
  constructor(props) {
    super(props)
    // const currentYear = new Date().getFullYear()
    // const minDate = dayjs(`${currentYear}-04-01`)
    const minDate = new Date('2024-04-01')
    const maxDate = new Date('2024-06-30')
    this.state = {
      advanceReportData: [],
      page: 0,
      pageSize: 10,
      loading: false,
      advance: 0,
      cash: 0,
      card: 0,
      upi: 0,
      bank: 0,
      cheque: 0,
      minDate: minDate,
      maxDate: maxDate,
      printLoad: false,

      // Filter States
      FilterOpen: false,
      DatePickerOpen: false,
      datePicker: new Date('2024-04-01'),
      patientName: '',
      mobileNumber: '',
      accountNumber: '',
    }
  }

  componentDidMount() {
    this.getAdvanceReportData()
  }

  getAdvanceReportData = () => {
    try {
      let states = this.state
      let startDate = new Date(states.datePicker)
      let date = DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')
      this.setState({ loading: true })
      RestAPIService.getAll(Serviceurls.TALLY_ADVANCE_RPT_GET +
        "?date=" + date +
        "&patient_name=" + states.patientName +
        "&uhid=" + states.accountNumber +
        "&mob_no=" + states.mobileNumber
      )
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              advanceReportData: response.data.data,
              cash: response.data.total_cash,
              card: response.data.total_card,
              upi: response.data.total_upi,
              bank: response.data.total_bank,
              cheque: response.data.total_cheque,
              advance: response.data.total_amount,
              loading: false,
              FilterOpen: false
            }, () => {
              this.state.advanceReportData?.forEach(element => {
                element.patientDetails = `${element.pt_name} ${element.uhid} ${element.mob_no}`
              })
            })
          }
        }).catch((error) => {
          this.setState({ loading: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ loading: false })
      this.errorMessage(error.message)
    }
  }

  getAdvanceReportDataPrint = () => {
    try {
      let states = this.state
      let startDate = new Date(states.datePicker)
      let date = DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')
      RestAPIService.getAll(Serviceurls.TALLY_ADVANCE_RPT_GET +
        "?date=" + date +
        "&patient_name=" + states.patientName +
        "&uhid=" + states.accountNumber +
        "&mob_no=" + states.mobileNumber +
        '&export_type=pdf'
      ).then((response) => {
        const file = new Blob(
          [response.data],
          { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.setState({ printLoad: false })
      }).catch((error) => {
        this.setState({ printLoad: false })
        if (error?.response?.data?.message) {
          this.errorMessage(error.response.data.message)
        } else {
          this.errorMessage(error.message)
        }
      })
    } catch (error) {
      this.setState({ printLoad: false })
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }
  gridToolBar = () => {
    var { t } = this.props
    var states = this.state
    let datepic = new Date(states.datePicker)
    let formatDate = DateTime.fromJSDate(datepic).toFormat('dd-MM-yyyy')
    let TextArray = [
      { label: "Date", value: formatDate }
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>
    )
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  closeFilter = () => {
    this.setState({ FilterOpen: false })
  }

  onChangeHandler = (e, names) => {
    try {
      let value = e.target.value
      switch (names) {
        case 'patientName':
          if (CommonValidation.alphabetOnly(value) || value === '') {
            this.setState({ patientName: value })
          }
          break;
        case 'accountNumber':
          this.setState({ accountNumber: value })
          break;
        case 'mobileNumber':
          if (CommonValidation.numberOnly(value) && value?.length <= 10 || value === '') {
            this.setState({ mobileNumber: value })
          }
          break;
        default:
          this.setState({ [names]: value })
          break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  textBox = (names, values, labels, width) => {
    try {
      return (
        <TextField
          autoComplete='off'
          size='small'
          inputProps={{ emed_tid: labels }}
          sx={{ width: width ? width : '15vw', mb: '1vw' }}
          value={values}
          label={labels}
          name={names}
          onChange={(e) => this.onChangeHandler(e, names)}
          InputProps={{ emed_tid: names }}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearHandler = () => {
    try {
      this.setState({
        page: 0,
        pageSize: 10,
        FilterOpen: false,
        // Filter State
        DatePickerOpen: false,
        datePicker: new Date('2024-04-01'),
        patientName: '',
        mobileNumber: '',
        accountNumber: ''
      }, () => this.getAdvanceReportData())
    } catch (error) {
      this.errorMessage(error.message)
    }
  }


  renderFilterScreen = () => {
    const { t } = this.props
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{("Filter")}</Typography>
          <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn' emed_tid='flt_cls_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box component={'div'} p={'0.5vw'} maxHeight={'75dvh'} overflow={'auto'}>
          <Box component={'div'} mb={'1vw'} display={'flex'} justifyContent={'flex-end'} mr={'0.5vw'}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                open={this.state.DatePickerOpen}
                onOpen={() => { this.setState({ DatePickerOpen: true }) }}
                onClose={() => { this.setState({ DatePickerOpen: false }) }}
                value={this.state.datePicker}
                minDate={this.state.minDate}
                maxDate={this.state.maxDate}
                name={"datePicker"}
                inputFormat='DD-MM-YYYY'
                // maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                onChange={(value) => this.setState({ datePicker: value })}
                renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} style={{ width: '12vw' }} size='small' {...params} inputProps={{ ...params.inputProps, 'emed_tid': 'datePicker_TestID', }} onClick={() => { this.setState({ DatePickerOpen: true }) }} />}
              />
            </LocalizationProvider>
          </Box>
          <Box component={'div'}>
            {this.textBox('patientName', this.state.patientName, 'Patient Name', '23.5vw')}
            {this.textBox('accountNumber', this.state.accountNumber, 'Account Number', '23.5vw')}
            {this.textBox('mobileNumber', this.state.mobileNumber, 'Mobile Number', '23.5vw')}
          </Box>
        </Box>
        <Box component={'div'} display={'flex'} justifyContent={'center'} mt={"45vh"} alignItems={'center'}>
          <Button variant='outlined' emed_tid={"onClearHandler_Testid"}
            sx={{ textTransform: 'capitalize', mr: '1vw', height: '2vw' }}
            onClick={() => this.onClearHandler()}
          >Clear</Button>
          <Button variant='contained' emed_tid={"getAdvanceReportData_Testid"}
            sx={{ textTransform: 'capitalize', mr: '1vw', height: '2vw' }}
            onClick={() => { this.setState({ FilterOpen: false }, () => this.getAdvanceReportData()) }}
          >Search</Button>
        </Box>
      </Box>
    )
  }

  render() {
    this.state.advanceReportData?.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: 'patientDetails', headerName: "Patient Details", flex: 0.125,
        renderCell: (params) => {
          let patientName = params?.row?.pt_name + "/" + params?.row?.patient_age
          let accNumAndMob = params?.row?.uhid + "|" + params?.row?.mob_no
          let gender = params?.row?.pt_gender === 'm' ? "M" : params?.row?.pt_gender === 'f' ? 'F' : params?.row?.pt_gender === 't' ? 'T' : '-'
          return (
            <Box component={'div'} display={'flex'} emed_tid='pat_dtls'>
              {/* <Box component={'div'}>
                <Box component={'img'} mr={'0.5vw'} src={gender === 'M' ? ImagePaths.Male.default : ImagePaths.Female.default} height={'2.5vw'} width={'2.5vw'} borderRadius={'50px'} />
              </Box> */}
              <Box component={'div'}>
                {patientName ? patientName?.length > 15 ?
                  <Tooltip placement='top' title={patientName}><Typography fontSize={'0.9vw'} fontWeight={600}>{patientName?.slice(0, 15) + "..."}</Typography></Tooltip> :
                  <Typography fontSize={'0.9vw'} fontWeight={600}>{patientName}</Typography> : '-'}
                {accNumAndMob ? accNumAndMob?.length > 15 ?
                  <Tooltip placement='top' title={accNumAndMob}><Typography fontSize={'0.8vw'} color={Colors.grayShade}>{accNumAndMob?.slice(0, 15) + "..."}</Typography></Tooltip> :
                  <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{accNumAndMob}</Typography> : '-'}
              </Box>
            </Box>
          )
        }
      },
      {
        field: 'receipt_number', headerName: "Receipt No.", flex: 0.125, align: 'center', headerAlign: 'center',
        renderCell: (params) => {
          return (
            <Box component={'div'} emed_tid='rcp_num'>
              <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.receipt_number ?
                params?.row?.receipt_number?.length > 15 ?
                  <Tooltip placement='top' title={params?.row?.receipt_number}><div style={{ fontSize: '0.9vw', fontWeight: 600 }}>{"..." + params?.row?.receipt_number?.slice(-10)}</div></Tooltip> :
                  params?.row?.receipt_number : '-'}</Typography>
            </Box>
          )
        }
      },
      {
        field: 'amount_received', headerName: `Advance (${CurrencySymbol})`, flex: 0.125, type: 'number',
        renderCell: (params) => {
          return (
            <Box emed_tid='amt_rcv'>{params?.row?.amount_received}</Box>
          )
        }
      },
      {
        field: 'amount_cash', headerName: `Cash (${CurrencySymbol})`, flex: 0.125, type: 'number',
        renderCell: (params) => {
          return (
            <Box emed_tid='amt_cash'>{params?.row?.amount_cash}</Box>
          )
        }
      },
      {
        field: 'amount_card', headerName: `Card (${CurrencySymbol})`, flex: 0.125, type: 'number',
        renderCell: (params) => {
          return (
            <Box emed_tid='amt_card'>{params?.row?.amount_card}</Box>
          )
        }
      },
      {
        field: 'upi_amount', headerName: `UPI (${CurrencySymbol})`, flex: 0.125, type: 'number',
        renderCell: (params) => {
          return (
            <Box emed_tid='amt_upi'>{params?.row?.upi_amount}</Box>
          )
        }
      },
      {
        field: 'bank_transfer_amount', headerName: `Bank Transfer (${CurrencySymbol})`, flex: 0.125, type: 'number',
        renderCell: (params) => {
          return (
            <Box emed_tid='amt_bank'>{params?.row?.bank_transfer_amount}</Box>
          )
        }
      },
      {
        field: 'cheque_amount', headerName: `Cheque (${CurrencySymbol})`, flex: 0.125, type: 'number',
        renderCell: (params) => {
          return (
            <Box emed_tid='amt_cheque'>{params?.row?.cheque_amount}</Box>
          )
        }
      },
    ]
    return (
      <Box component={'div'}>
        <Box component={'div'} className='eMed_Main_Container'>
          <Box component={'div'} className='eMed_Header_Container'>
            <Box component={'div'} className='eMed_Header_Rgt_Content'>
              {AmountsCard('Advance', this.state.advance, false, 'white', false, 'white', 'advance')}
              {AmountsCard('Cash', this.state.cash, false, 'white', false, 'white', 'cash')}
              {AmountsCard('Card', this.state.card, false, 'white', false, 'white', 'card')}
              {AmountsCard('UPI', this.state.upi, false, 'white', false, 'white', 'upi')}
              {AmountsCard('Bank TFR', this.state.bank, false, 'white', false, 'white', 'bank')}
              {AmountsCard('Cheque', this.state.cheque, false, 'white', false, 'white', 'cheque')}
            </Box>
            <Box component={'div'} className='eMed_Header_Lft_Content'>
              <Button className='Common_Btn_Min_Width eMed_Header_Btn' emed_tid='filter_btn'
                onClick={() => this.setState({ FilterOpen: true })}
              >
                <Box component={'img'} src={ImagePaths.Filter.default} height={'2vw'} width={'2vw'} />
              </Button>
              <Button className='Common_Btn_Min_Width eMed_Header_Btn' emed_tid='prt_btn' disabled={this.state.printLoad}
                onClick={() => {
                  this.setState({ printLoad: true }, () => this.getAdvanceReportDataPrint())
                }}
              >
                <Box component={'img'} src={ImagePaths.LabPrintIcon.default} height={'2vw'} width={'2vw'} />
              </Button>
            </Box>
          </Box>
          <Box component={'div'} className='eMed_Table_Content'>
            <DataGrid
              sx={{ backgroundColor: 'white' }}
              columns={columns}
              rows={this.state.advanceReportData}
              getRowId={(row) => row['sno']}
              page={this.state.page}
              pageSize={this.state.pageSize}
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              components={{
                Toolbar: this.gridToolBar,
                NoRowsOverlay: () => (
                  <Box className='eMed_conf_nodata'>
                    {'No Records To Be Shown'}
                  </Box>
                )
              }}
              onPageChange={(newPage) => { this.setState({ page: newPage }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              rowsPerPageOptions={[10, 20, 30]}
              pagination
              headerHeight={40}
              disableSelectionOnClick
              loading={this.state.loading}
            />
          </Box>
        </Box>
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}
