import React, { Component } from 'react';
import './Billings.css';
import { IconButton, Box, Stack, Button, Autocomplete, Typography, TextField, Modal, Tooltip, InputAdornment } from '@mui/material';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { withTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTime } from "luxon";
import { Bucket_Name, CurrencySymbol, paymentList } from '../../../Utility/Constants';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import { Colors } from '../../../Styles/Colors';
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation";
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';

let AWS = require('aws-sdk');
let s3 = new AWS.S3({
  accessKeyId: "AKIAT7TG52UHOEIYAJH4",
  secretAccessKey: "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",
  region: "ap-south-1"
});

class ExpensePopUp extends Component {
    constructor(props) {
        super(props)
        let viewData = this.props.View && this.props.viewData !== undefined && Object.keys(this.props.viewData)?.length > 0 ? this.props.viewData : {};
        let modifyData = this.props.Modify && this.props.modifyData !== undefined && Object.keys(this.props.modifyData)?.length > 0 ? this.props.modifyData : {};
        this.state = {
            isAdd: this.props.Add ? this.props.Add : false,
            isView: this.props.View ? this.props.View : false,
            isModify: this.props.Modify ? this.props.Modify : false,
            expCategoryList: this.props.expCategoryList?.length > 0 ? this.props.expCategoryList : [],
            addExp_date: new Date(),
            add_category: "",
            category_id: null,
            add_amount: 0,
            add_payment: "Cash",
            add_payment_mode: 1,
            add_notes: "",
            imgUpload: null,
            modify_id: null,
            viewDetail: viewData,
            modifyDetails: modifyData,
        }
        this.uploadImage = React.createRef();
    }

    componentDidMount() {
        this.assignDetails();
    }

    lodaerFunction = (data) => {
        this.props.LoaderFunction(data)
    }

    successMessage = (msg) => {
        this.props.successMessage(msg);
    }
    errorMessage = (msg) => {
        this.props.errorMessage(msg);
    }

    getS3Key(doc_key) {

        return new Promise(resolve => {
          var parms = {
            Bucket: Bucket_Name,
            Key: doc_key, Expires: 300
          };
          s3.getSignedUrl("getObject", parms, (err, url) => {
            if (err) {
              resolve(err)
            } else {
              var data = {
                "data_url": url
              }
              resolve(data)
            }
          })
        })
      }
    
      async renderImage(image) {
            if (image !== null) {
              let doc_key = image;
              let getData = await this.getS3Key(doc_key)
              getData["type"] = image.split('.').pop() === "pdf" ?  "pdf" : "image"
              this.setState({
                imgUpload: getData
                })
            }
      }

    assignDetails = () => {

        let states = this.state;
        let availData = {}
        if (states?.isAdd === false && states?.isView && Object.keys(states.viewDetail)?.length > 0) {
            availData = { ...states.viewDetail }
            if(states.viewDetail?.document_url){
                this.renderImage(states.viewDetail.document_url)
            }
        } else if (states?.isAdd === false && states?.isModify && Object.keys(states.modifyDetails)?.length > 0) {
            availData = { ...states.modifyDetails }
            if(states.modifyDetails?.document_url){
                this.renderImage(states.modifyDetails.document_url)
            }
        }

        this.setState({
            modify_id: availData.id,
            addExp_date: availData?.bill_date ? new Date(availData.bill_date) : new Date(),
            add_category: availData.category_name,
            category_id: availData.category_id,
            add_amount: +(availData.amount),
            add_payment: availData.payment_type ? availData.payment_type : "Cash",
            add_payment_mode: availData.payment_mode ? availData.payment_mode : 1,
            add_notes: availData.description,
        })
    }

    CheckAllFields = () => {
        let states = this.state;
        if (states.addExp_date === "Invalid Date" || !states.category_id || !states.add_amount || !states.add_notes) {
            return "Fillmandatory";
        }
        return "";
    }

    handlePayExpense = () => {
        let states = this.state;
        let isAvl = this.CheckAllFields();
        let to_date = new Date();
        let method = "create";
        let StateDate = new Date(states.addExp_date)

        if (isAvl === "") {
            const dataForm = new FormData();
            dataForm.append("document", states.imgUpload ? states.imgUpload : "")
            dataForm.append("receiver_name", "");
            dataForm.append("bill_date", states.addExp_date ? DateTime.fromJSDate(StateDate).toFormat('yyyy-MM-dd') : DateTime.fromJSDate(to_date).toFormat('yyyy-MM-dd'));
            dataForm.append("payment_type", states.add_payment_mode ? states.add_payment_mode : 1);
            dataForm.append("amount", states.add_amount ? states.add_amount : 0);
            dataForm.append("description", states.add_notes ? states.add_notes : "");
            dataForm.append("expense_category", states.category_id ? states.category_id : null);

            if (states.modify_id) {
                dataForm.append("id", states.modify_id ? states.modify_id : null);
                method = "updateWithOutId"
            }
            try {
                this.lodaerFunction(true)
                this.setState({disableBtn: true})
                RestAPIService[method](dataForm, Serviceurls.EXPENSE_BILL_GET)
                    .then(response => {
                        if (response.data.status === "success") {
                            this.handleClose();
                            this.props.getDetails();
                            this.lodaerFunction(false)
                            this.successMessage(response.data.message);
                            this.setState({disableBtn: false})
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.lodaerFunction(false)
                            this.setState({disableBtn: false})
                            this.errorMessage(error.response.data.message);
                        }
                    });
            } catch (e) {
                this.errorMessage(e.message);
            }
        } else {
            this.errorMessage(isAvl)
        }
    }

    renderImgCard = () => {
        const { t } = this.props;
        let states = this.state;

        return (
            <Stack className="eMed_IP_ExpPop_imgDiv" justifyContent="center" alignItems="center" 
                 sx={{ backgroundColor: (states.isView || states.isModify) ? "" : Colors.Background }}>
                    <input
                        type="file"
                        accept="application/pdf, image/jpg, image/jpeg, image/png"
                        onChange={(e) => {
                            this.setState({ imgUpload: e.target.files[0] })
                            e.target.value = ''
                        }}
                        ref={this.uploadImage}
                        style={{ display: "none" }}
                    />
                {
                    states.imgUpload === null || !states.imgUpload || states.imgUpload === undefined ?
                        <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
                            <Stack className="eMed_IP_ExpPop_imgSubDiv" justifyContent="center" alignItems="center" spacing={0.5}>
                                <IconButton size="small" disabled={states.isView ? true : false} onClick={() => this.uploadImage.current.click()}>
                                    <img id="eMed_upload_img" src={states.isView ? ImagePaths.LabUploadButton.default : ImagePaths.Export.default} alt="Upload" />
                                </IconButton>
                                <Typography id="eMed_img_lblTxt" >{t("ClkUploadFile")}</Typography>
                                <Typography id="eMed_img_subTxt" sx={{ marginLeft: "0.5vw" }}>{t("imgFormat")}</Typography>
                            </Stack>
                        </Stack> :
                        states.imgUpload || ((states.isView || states.isModify) && states.imgUpload !== undefined) ?
                            <Box>
                                <div className={states.imgUpload?.type === "image" ? "eMed_IP_ExpPop_img" : "eMed_Testrep_Img"}>
                                    <Tooltip title={typeof (states.imgUpload) === "object" ? null : states.imgUpload?.name} arrow placement='top'>
                                        <img
                                            className={states.imgUpload?.type === "image" ? "eMed_IP_ExpPop_img" : 'eMed_Testrep_Img'}
                                            onClick={() => {
                                                if (states.imgUpload?.type === "pdf") {
                                                    window.open(states.imgUpload?.data_url)
                                                }
                                            }}
                                            src={states.imgUpload?.type === "image" ? states.imgUpload?.data_url : (states.imgUpload?.type === "image/jpeg" || states.imgUpload?.type === "image/png") ?
                                                ImagePaths.ImagePreview.default : ImagePaths.DocumentPreview.default}
                                        />
                                    </Tooltip>
                                    {states.isView ? null : <div>
                                        <IconButton id={states.imgUpload?.type === "image" ? "eMed_IP_ExpPop_Icon" : "eMed_Testrep_Icon"}
                                            size="small"
                                            onClick={() => {
                                                this.setState({ imgUpload: null })
                                            }}>
                                            <CancelTwoToneIcon fontSize='small' color="error" />
                                        </IconButton>
                                    </div>}
                                </div>
                            </Box> 
                            :null
                }
            </Stack>
        )
    }

    renderDatePicker = (label, statekey) => {
        let states = this.state;
        const { t } = this.props;
        return (
            <div className="eMed_IP_ExpPop_txtbox">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        value={states[statekey] ? states[statekey] : new Date()}
                        inputFormat={"dd-MM-yyyy"}
                        label={t(label) + " *"}
                        readOnly={states.isView}
                        autoComplete="off"
                        onChange={
                            (newDate) => {
                                states[statekey] = newDate
                                this.setState({
                                    states
                                });
                            }}
                        renderInput={(params) => <TextField sx={{ width: "13vw" }} size='small' autoComplete='off' {...params} />}
                    />
                </LocalizationProvider>
            </div>
        )
    }

    renderAutoSlt = (label, stateKey1, stateKey2, showkey, valueKey, Data = [], width) => {
        let states = this.state;
        const { t } = this.props;
        return (
            <div className="eMed_IP_ExpPop_txtbox">
                <Autocomplete
                    freeSolo = {states.isView}
                    size='small'
                    clearIcon={false}
                    readOnly={states.isView}
                    sx={{ width: width ? width : '13.8vw' }}
                    options={Data}
                    getOptionLabel={(option) => (typeof (option[showkey]) === "string" ? option[showkey] : option)}
                    value={states[stateKey1] ? states[stateKey1] : ""}
                    ListboxProps={{ style: { maxHeight: 300 } }}
                    onChange={(event, newValue) => {
                        states[stateKey1] = newValue[showkey] // value to be shown
                        states[stateKey2] = newValue[valueKey] // actual value
                        this.setState({ states })
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t(label) + " *"}
                            size="small"
                        />
                    )}
                />
            </div>
        )
    }

    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value.trimStart();
        let states = this.state;
        switch (name) {
            case "add_amount":
                let isValid = CommonValidation.NumberWithDot(value);
                if (value === "" || (value > 0 && isValid)) {
                    states[name] = +(value);
                }
                break;
            default:
                if (value === "" || value.length <= 150) {
                    states[name] = value;
                }
                break;

        }
        this.setState({ states });
    }

    renderTextBox(label, statekey, width) {
        const { t } = this.props;
        let states = this.state;
        return (
            <div className="eMed_IP_ExpPop_txtbox">
                <TextField
                    sx={{ width: width }}
                    fullWidth
                    size="small"
                    autoComplete="off"
                    label={t(label)}
                    name={statekey}
                    required
                    inputProps={{ readOnly: states.isView }}
                    InputProps={{
                        endAdornment: statekey === "add_amount" ? (
                            <InputAdornment position="end">
                                {`${CurrencySymbol}`}
                            </InputAdornment>
                        ) : null
                    }}
                    multiline={statekey === "add_notes"}
                    rows={3}
                    value={states[statekey] ? states[statekey] : ""}
                    onChange={this.handleChange}
                />
            </div>
        )
    }

    handleClear = () => {
        this.setState({
            addExp_date: new Date(),
            add_category: null,
            add_amount: 0,
            add_category: "",
            category_id: null,
            add_amount: 0,
            add_payment: "Cash",
            add_payment_mode: 1,
            add_notes: "",
            imgUpload: null,
            modify_id: null
        })
    }

    handleClose = () => {
        this.handleClear();
        this.props.popUpClose();
    }

    render() {
        const { t } = this.props;
        let states = this.state;
        let disablebtn = this.CheckAllFields();

        return (
            <Modal open={true}>
                <Box className="eMed_IP_ExpPop_MainDiv">
                    <Box component={'div'} className="eMed_IP_ExpPop_Header">
                        <Typography id="eMed_IP_ExpPop_TltTxt">{t("ExpenseBilling")}</Typography>
                        <IconButton size="small" onClick={this.handleClose}>
                            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                        </IconButton>
                    </Box>
                    <Box component={'div'} className="eMed_IP_ExpPop_wrapper">
                        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
                            {this.renderDatePicker("Date", "addExp_date")}
                            {this.renderAutoSlt("ExpenseCategory", "add_category", "category_id", "name", "id", states.expCategoryList)}
                        </Stack>
                        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
                            {this.renderTextBox("Amount", "add_amount", "13vw")}
                            {this.renderAutoSlt("PaymentMode", "add_payment", "add_payment_mode", "label", "value", paymentList)}
                        </Stack>
                        <Stack>
                            {this.renderTextBox("Notes", "add_notes")}
                        </Stack>

                        {this.renderImgCard()}

                    </Box>
                    <Stack component={'div'} className="eMed_IP_ExpPop_btnBox" spacing={3} direction={"row"} justifyContent={states.isView ? "flex-end" : "center"} alignItems={"center"}>
                        {
                            states.isView ? null
                                :
                                <Button variant='contained' size="small" id="eMed_Config_btn"
                                    onClick={() => { this.handleClear() }}>
                                    {t("Clear")}
                                </Button>
                        }

                        <Button variant='contained' size="small" id="eMed_Config_btn" emed_tid="Expense_PayExpense_Btn"
                            sx={{ marginRight: states.isView ? "1.5vw" : "0vw" }}
                            disabled={states.disableBtn ? true : states.isView || disablebtn.length > 0 ? true : false}
                            onClick={() => { this.handlePayExpense() }}>
                            {states.isModify ? t("ModifyExpense") : t("PayExpense")}
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        )
    }
}
export default withTranslation()(ExpensePopUp);