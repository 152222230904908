import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Button, Card, Grid, Paper, Stack, TextField, Typography } from '@mui/material';
import './DoctorNotes.css';
import RestAPIService from '../../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../../Utility/API/Serviceurls';
import { getCachevalue } from '../../../../../Utility/Services/CacheProviderService';
import { CommonPatientCard, CommonPatientDetails, ConsultantPatientCard } from '../../../../../Components/Common Components/CommonComponents';
import { Colors } from '../../../../../Styles/Colors';
import SymptomsLeft from './DocNotesComponents/SymptomsLeft';
import ExaminationLeft from './DocNotesComponents/ExaminationLeft';
import ExaminationRight from './DocNotesComponents/ExaminationRight';
import SymptomsRight from './DocNotesComponents/SymptomsRight'
import DiagnosisRight from './DocNotesComponents/DiagnosisRight';
import DiagnosisLeft from './DocNotesComponents/DiagnosisLeft';
import ProvDiagnosisLeft from './DocNotesComponents/ProvDiagnosisLeft';
import ProvDiagnosisRight from './DocNotesComponents/ProvDiagnosisRight';
import '../IpPatientDetails.css'
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg';

const IPdoctorNotesTabs = [
  { Label: "Symptoms", value: "isSymptoms" },
  { Label: "Examination", value: "isExamination" },
  { Label: "Provisional Diagnosis", value: "isProvDiagnosis" },
  { Label: "ICD 10 Code Diagnosis", value: "isDiagnosis" },
]
class DoctorNotes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ipPatientData: {},
      SelectedComponent: IPdoctorNotesTabs[0]?.value,
      IPadmissionDetails: {},
      CallGetComponent: "",
      EditSymptomsData: {},
      EditExaminationsData: {},
      EditProvDiagnosisData: "",
      withOutAppointment : {}
    }
  }

  componentDidMount() {
    let ipPatientData = getCachevalue("IppatientData")
    let patientData = JSON.parse(ipPatientData)
    let appointmentDetail = getCachevalue("withoutAppointment");
    let details = JSON.parse(appointmentDetail);
    if(details?.patient_id === patientData?.patient_id){
      this.setState({
        withOutAppointment: details
      },() => this.getPatientData())
    }
    this.setState({
      ipPatientData: patientData
    }, () => this.getPatientData())
  }

  getPatientData = () => {
    let admission_id =   this.state.ipPatientData?.id || this.state.withOutAppointment?.ip_admission_id;
    try {
      RestAPIService.getAll(Serviceurls.IP_PATIENT_DETAILS + '?admission_id=' + admission_id)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              IPadmissionDetails: response.data.data[0]
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  TriggerGetApi(component) {
    this.setState({
      CallGetComponent: component
    }, () => {
      setTimeout(() => { this.setState({ CallGetComponent: "" }) }, 500)
    })
  }

  EditSymptomData(data) {
    this.setState({
      EditSymptomsData: data
    })
  }
  EditExaminationsData(data) {
    this.setState({
      EditExaminationsData: data
    })
  }
  EditProvDiagnosisData(data) {
    this.setState({
      EditProvDiagnosisData: data
    })
  }
  ClearPropsData() {
    this.setState({
      EditSymptomsData: {},
      EditExaminationsData: {},
      EditProvDiagnosisData: "",
    })
  }

  renderComponent(Component) {
    switch (Component) {

      case "isSymptoms":
        return <SymptomsLeft
          IpPatientData={this.state.ipPatientData}
          EditSymptomData={this.EditSymptomData.bind(this)}
          GetAPiCall={this.state.CallGetComponent === 'isSymptoms'}
          IPadmissionDetails={this.state.IPadmissionDetails}
          withOutAppointment = {this.state.withOutAppointment}
           />

      case "isExamination":
        return <ExaminationLeft
          IpPatientData={this.state.ipPatientData}
          EditExaminationsData={this.EditExaminationsData.bind(this)}
          GetAPiCall={this.state.CallGetComponent === 'isExamination'}
          IPadmissionDetails={this.state.IPadmissionDetails} 
          withOutAppointment = {this.state.withOutAppointment}
          />

      case "isProvDiagnosis":
        return <ProvDiagnosisLeft
          IpPatientData={this.state.ipPatientData}
          EditProvDiagnosisData={this.EditProvDiagnosisData.bind(this)}
          GetAPiCall={this.state.CallGetComponent === 'isProvDiagnosis'}
          IPadmissionDetails={this.state.IPadmissionDetails}
          withOutAppointment = {this.state.withOutAppointment}
          />

      case "isDiagnosis":
        return <DiagnosisLeft
          IpPatientData={this.state.ipPatientData}
          GetAPiCall={this.state.CallGetComponent === 'isDiagnosis'}
          IPadmissionDetails={this.state.IPadmissionDetails} 
          withOutAppointment = {this.state.withOutAppointment}
          />
    }
  }

  renderRightComponent(Component) {
    switch (Component) {
      case "isSymptoms":
        return <SymptomsRight
          EditSymptomsData={this.state.EditSymptomsData}
          CallgetApi={this.TriggerGetApi.bind(this)}
          IpPatientData={this.state.ipPatientData}
          IPadmissionDetails={this.state.IPadmissionDetails}
          ClearPropsData={this.ClearPropsData.bind(this)} 
          withOutAppointment = {this.state.withOutAppointment}/>

      case "isExamination":
        return <ExaminationRight
          EditExaminationsData={this.state.EditExaminationsData}
          CallgetApi={this.TriggerGetApi.bind(this)}
          IpPatientData={this.state.ipPatientData}
          IPadmissionDetails={this.state.IPadmissionDetails}
          ClearPropsData={this.ClearPropsData.bind(this)}
          withOutAppointment = {this.state.withOutAppointment} />

      case "isProvDiagnosis":
        return <ProvDiagnosisRight
          EditProvDiagnosisData={this.state.EditProvDiagnosisData}
          CallgetApi={this.TriggerGetApi.bind(this)}
          IpPatientData={this.state.ipPatientData}
          IPadmissionDetails={this.state.IPadmissionDetails}
          ClearPropsData={this.ClearPropsData.bind(this)} 
          withOutAppointment = {this.state.withOutAppointment}/>

      case "isDiagnosis":
        return <DiagnosisRight
          CallgetApi={this.TriggerGetApi.bind(this)}
          IpPatientData={this.state.ipPatientData}
          IPadmissionDetails={this.state.IPadmissionDetails} 
          withOutAppointment = {this.state.withOutAppointment}/>
    }
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
    })
  }
  render() {
    const { t } = this.props
    var { ipPatientData } = this.state
    let attender = {
      lineOne: t("AttenderName"),
      lineTwo: `${ipPatientData.attender_name != null ? ipPatientData.attender_name : '-'}`,
      lineFour: t('ContactNumber'),
      LineFive: `${ipPatientData.attender_mobile != null ? ipPatientData.attender_mobile : '-'}`
    }
    let consultant = {
      lineOne: t("ConsultantName"),
      lineTwo: `${ipPatientData.doctor_name != null ? ipPatientData.doctor_name : '-'}`,
    }
    return (
      <Box sx={{ backgroundColor: Colors.ComponentBgColor }}>
        <Box component={'div'} marginLeft={'0.5vw'} className='eMed_patient_header'>
          <Box display={'flex'} alignItems='center'>
            <Box className='eMed_Patient_Details_Long eMed_patient_box'>
              <CommonPatientDetails data={this.state.ipPatientData} showDetailed={true} />
            </Box>
            <CommonPatientCard details={attender} showDetailed={true} />
            <ConsultantPatientCard name={consultant} />
          </Box>
        </Box>
        <Box display={'flex'} flexDirection={'row'} marginTop={"0.5vw"}>
          <Paper elevation={3} className='eMed_DocNotes_Left_Main'>
            {
              IPdoctorNotesTabs.map((item, index) => (
                <Card key={index} onClick={() => { this.setState({ SelectedComponent: item.value }) }} elevation={3} sx={{ minHeight: '10vw', margin: '1vw', border: `0.15vw solid ${item.value === this.state.SelectedComponent ? Colors.themeDark : Colors.white}`, }}>
                  <Typography color={item.value === this.state.SelectedComponent ? Colors.themeDark : Colors.SecondaryText} padding={"0.3vw"} fontWeight={600} marginLeft={"1vw"}>{item.Label}</Typography>
                  {this.renderComponent(item.value)}
                </Card>
              ))
            }

          </Paper>
          <Paper elevation={3} className='eMed_DocNotes_Right_Main'>
            {this.renderRightComponent(this.state.SelectedComponent)}
          </Paper>
        </Box>
        {
          this.state.isErrorMsg ?
            <ToastMsg
              severity={"error"}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            /> : null
        }
      </Box>
    )
  }
}
export default withTranslation()(DoctorNotes)
