import React, { Component } from "react";
import '../Configuration.css';
import { Box, Paper, TextField, Button, Stack, Grid, Select, IconButton, Menu, MenuItem, FormControl, InputLabel } from "@mui/material";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import CommonGridHeader, { CommonEditButton } from "../../../Components/Common Components/CommonComponents";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { ImagePaths } from "../../../Utility/ImagePaths";
import { Colors } from "../../../Styles/Colors";
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";
class TokenPrefix extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tokenDetail: [],
            selectedIndex: -1,
            selectedLineItem: {},
            token_for: 'doctor',
            data: [],
            spl_data: [],
            doc_data: [],
            editId: null,
            selectedItem: {},
            prefix: "",
            anchorEl: null,
            cellModesModel: {},
            current_page: 0,
            rowsPerPage: 10,
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: ""
        }
    }
    componentDidMount() {
        this._getTokenPrefixDetails();
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    _getTokenPrefixDetails = () => {
        this.setState({
            tokenDetail: [],
            current_page: 0,
            rowsPerPage: 10
        })
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPTOKENPREFIX)
                .then(response => {
                    if (response.data.status === "successs") {
                        let values = response.data.data
                        this.setState({
                            tokenDetail: values,
                            data: JSON.parse(JSON.stringify(values[0].data)),
                            doc_data: JSON.parse(JSON.stringify(values[0].data)),
                            spl_data: JSON.parse(JSON.stringify(values[1].data))
                        }, () => {
                            this.state.tokenDetail?.forEach((item, i) => {
                                item.index = i;
                            })
                            this.LoaderFunction(false)
                        });
                    }
                })
                .catch(error => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.responseSuccessErrorMessage(error.response.data.message, false);
                    }
                });
        } catch (e) {
            this.LoaderFunction(false)
            this.responseSuccessErrorMessage(e.message, false);
        }
    }
    handleAccordion = () => {
        const { t } = this.props;
        let { selectedLineItem } = this.state;
        let accordionColumn = [
            { field: 's.no', headerName: t('SNo'), sortable: false, flex: 0.13, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
            { field: selectedLineItem["title"] === "doctor" ? 'docter_name' : 'specialization_name', headerName: selectedLineItem["title"] === "doctor" ? t("Doctor") : t('Specialization'), sortable: false, hideable: false, flex: 0.57 },
            { field: 'prefix', headerName: t('Prefix'), flex: 0.30, sortable: false, hideable: false },
        ]

        return (
            selectedLineItem.data?.length > 0 ?
                <div id="eMed_srvConfig_accTbl">
                    <DataGrid
                        rows={selectedLineItem.data}
                        columns={accordionColumn}
                        getRowId={(row) => row.id}
                        autoHeight
                        disableColumnMenu
                        hideFooter
                        components={{
                            NoRowsOverlay: () => ""
                        }}
                        headerHeight={40}
                    />
                </div>
                : null
        )

    }

    responseSuccessErrorMessage(message, type) {
        this.setState({
            showSuccessResponse: message ? type : false,
            showErrorResponse: message ? !type : false,
            responseMessage: message
        })
    }

    //Start of left table Function
    handleEdit = (item) => {
        let states = this.state
        states["token_for"] = item.title;
        states["data"] = item.data;
        this.setState({
            states
        })
    }
    // End of left table Function

    //Start of right div Function
    handleEditTbl = (item) => {
        this.setState({
            editId: item.id,
            selectedItem: item,
            prefix: item.prefix
        })
    }

    handleSaveClick = (id) => {
        let { data, selectedItem } = this.state;
        let temp = JSON.parse(JSON.stringify(selectedItem));
        let newList = data.map((item) => {
            return item.id === id ? temp : item
        })
        data = JSON.parse(JSON.stringify(newList))
        this.setState({
            data,
            selectedItem: {},
            prefix: "",
            editId: null
        })
    };

    handleCancelClick = (id) => {
        let { data, selectedItem, prefix } = this.state;
        selectedItem["prefix"] = prefix
        let temp = JSON.parse(JSON.stringify(selectedItem));
        let newList = data.map((item) => {
            return item.id === id ? temp : item
        })
        data = JSON.parse(JSON.stringify(newList))
        this.setState({
            data,
            selectedItem: {},
            prefix: "",
            editId: null
        })
    };

    handleClear = () => {
        this.setState({
            token_for: "doctor",
            data: JSON.parse(JSON.stringify(this.state.doc_data))
        })
    }

    handleSave = () => {
        let { data, editId } = this.state;
        data.forEach(item => {
            item["specialization_id"] ? item["doctor_id"] = null : item["specialization_id"] = null;
        })
        if(editId === null){
        let datas = {
            "line_item": JSON.parse(JSON.stringify(data))
        }
        try {
            this.LoaderFunction(true)
            this.setState({disableBtn: true})
            RestAPIService.create(datas, Serviceurls.FO_CONFIG_OPTOKENPREFIX)
                .then(response => {
                    if (response.data.status === "successs") {
                        this.handleClear();
                        this._getTokenPrefixDetails();
                        this.LoaderFunction(false)
                        this.setState({disableBtn: false})
                        this.responseSuccessErrorMessage(response.data.message, true);
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.LoaderFunction(false)
                        this.setState({disableBtn: false})
                        this.responseSuccessErrorMessage(error.response.data.message, false);
                    }
                });
        } catch (e) {
            this.LoaderFunction(false)
            this.setState({disableBtn: false})
            this.responseSuccessErrorMessage(e.message, false);
        }}
    }

    renderTextBox(label, statekey, value, width) {
        const { t } = this.props;
        let states = this.state;
        const SpecList = [
            { label: "Doctor", value: "doctor" },
            { label: "Specialization", value: "specialization" },
        ]
        return (
            <div id="eMed_srvConfig_divs">
                <FormControl sx={{ width: width ? width : "29.8vw" }} size="small">
                    <InputLabel>{t(label)}</InputLabel>
                    <Select
                        value={value ? value : ""}
                        name={statekey}
                        label={t(label)}
                        onChange={(event) => {
                            if (event.target.value === "doctor") {
                                states["data"] = JSON.parse(JSON.stringify(states["doc_data"] ? states["doc_data"] : []))
                            } else {
                                states["data"] = JSON.parse(JSON.stringify(states["spl_data"] ? states["spl_data"] : []))
                            }
                            states["token_for"] = event.target.value
                            this.setState({
                                states
                            })
                        }}
                    >
                        {
                            SpecList.map((list, index) => (
                                <MenuItem key={index} value={list.value} >{list.label}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </div>
        )
    }
    // End of right div Function

    msgClose(){
        this.setState({
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: ''
        })
      }
    render() {
        const { t } = this.props;
        let { rowsPerPage, current_page, tokenDetail, anchorEl, token_for, data, cellModesModel } = this.state;
        let open = Boolean(anchorEl);
        const columns = [
            { field: 's.no', headerName: t('SNo'), sortable: false, flex: 0.10, renderCell: (index) => index.api.getRowIndex(index.row.title) + 1 },
            {
                field: 'title', headerName: t('TokenFor'), flex: 0.70, renderCell: (cellvalues) => {
                    let value = cellvalues.row.title;
                    let tokenFor = value.charAt(0).toUpperCase() + value.slice(1);
                    return tokenFor;
                }
            },
            {
                field: 'actions', headerAlign: 'center', headerName: t('Action'), flex: 0.20, sortable: false, hideable: false, align: "center", renderCell: (cellvalues) => {
                    return (
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                            <CommonEditButton disable={!CheckAccessFunc("front_office", "Settings", "OP Service Configuration",  "Token Prefix", "SubTab")?.editAccess} size="1.5vw" onClick={this.handleEdit.bind(this, cellvalues.row)} />
                            <div>
                                <IconButton size="small" onClick={(event) => { this.setState({ anchorEl: event.currentTarget, selectedIndex: cellvalues.row.index, selectedLineItem: cellvalues.row }) }}><img className="eMed_arrowBtn" src={ImagePaths.downArrow.default} style={{ transform: this.state.selectedIndex === cellvalues.row.index ? "rotate(180deg)" : null, }} alt="Arrow" /></IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={(e) => {
                                        this.setState({
                                            anchorEl: null,
                                            selectedIndex: -1,
                                            selectedLineItem: {}
                                        })
                                    }}
                                    PaperProps={{
                                        style: {
                                            height: "fitContent",
                                            width: '55vw',
                                            marginLeft: "-36.5vw",
                                            marginTop: "0.80vw",
                                            boxShadow: "none"
                                        },
                                    }}
                                    MenuListProps={{
                                        disablePadding: true,

                                    }}
                                >
                                    {this.handleAccordion()}
                                </Menu>
                            </div>
                        </Stack>
                    )
                }
            }
        ]
        const rightColumn = [
            { field: token_for === "specialization" ? 'specialization_name' : 'docter_name', headerName: token_for === "specialization" ? t('Specialization') : t('Doctor'), sortable: false, hideable: false, flex: 0.28 },
            {
                field: 'prefix', headerName: t('Prefix'), flex: 0.25, sortable: false, hideable: false, renderCell: (cellvalues) => {
                    let { selectedItem } = this.state
                    if (selectedItem["id"] === cellvalues.row.id) {
                        return (
                            <TextField value={selectedItem["prefix"] ? selectedItem["prefix"] : ""}
                                onChange={(event) => {
                                    selectedItem["prefix"] = event.target.value.trimStart();
                                    this.setState({ selectedItem })
                                }}  variant="standard" />
                        )
                    }
                    return cellvalues.row.prefix
                }
            },
            {
                field: 'actions', type: 'actions', headerAlign: "center", headerName: t('Action'), flex: 0.17, sortable: false, hideable: false, align: "center", getActions: ({ id, row }) => {
                    if (this.state.selectedItem["id"] === id) {
                        return [
                            <GridActionsCellItem
                                icon={<SaveIcon />}
                                label="Save"
                                onClick={() => { this.handleSaveClick(id) }}
                                color="secondary"
                            />,
                            <GridActionsCellItem
                                icon={<CancelIcon />}
                                label="Cancel"
                                className="textPrimary"
                                onClick={() => { this.handleCancelClick(id) }}
                                color="error"
                            />
                        ]
                    }
                    return [
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label="Edit"
                            className="textPrimary"
                            size="large"
                            onClick={() => { this.handleEditTbl(row) }}
                            color="primary"
                        />
                    ];
                }
            }
        ]
        return (
            <Box id="eMed_srvConfig_Container" sx={{ backgroundColor: Colors.configHomeBg }}>
                <Grid container spacing={3} className="eMed_srvConfig_Box">
                    <Grid item xs={7.6}>
                        <Paper className="eMed_srvConfig_Div" >
                            <DataGrid
                                rows={tokenDetail}
                                columns={columns}
                                getRowId={(row) => row.title}
                                localeText={{
                                    toolbarColumns: "",
                                    toolbarDensity: "",
                                    toolbarExport: "",
                                    toolbarFilters: "",
                                    toolbarExportPrint: ""
                                }}
                                disableSelectionOnClick
                                components={{
                                    Toolbar: CommonGridHeader.CommonToolBar,
                                    NoRowsOverlay: () => {
                                        return (
                                            <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "53vh" }}>
                                                {t("NoRecordsFound")}
                                            </Stack>
                                        )
                                    }
                                }}
                                rowCount={tokenDetail.length}
                                page={current_page}
                                onPageChange={(newPage) => this.setState({ current_page: newPage })}
                                pageSize={rowsPerPage}
                                onPageSizeChange={(newPageSize) => this.setState({ rowsPerPage: newPageSize })}
                                rowsPerPageOptions={[10, 20, 30]}
                                pagination
                                headerHeight={40}
                                loading={this.state.isLoader}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={4.4}>
                        <Paper className="eMed_srvConfig_Div">
                            <div className='eMed_srvConfig_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                <p className='eMed_srvConfig_rightHead_txt'>{t("TokenPrefix")}</p>
                            </div>
                            <div className="eMed_srvConfig_subDiv">
                                {this.renderTextBox("TokenFor", "token_for", token_for, "29.8vw")}
                                <div id="eMed_srvConfig_rightTbl2">
                                    <DataGrid
                                        rows={data}
                                        columns={rightColumn}
                                        getRowId={(row) => row.id}
                                        disableColumnMenu
                                        hideFooter
                                        components={{
                                            NoRowsOverlay: () => ""
                                        }}
                                        headerHeight={40}
                                    />
                                </div>
                            </div>
                            <Stack spacing={2} direction="row" id='eMed_srvConfig_rightBtnCon' sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center">
                                <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                                <Button disabled={this.state.disableBtn ? true : !CheckAccessFunc("front_office", "Settings", "OP Service Configuration",  "Token Prefix", "SubTab")?.editAccess} variant='contained' size="small" id="eMed_Config_btn" emed_tid="TokenPrefix_Save_Btn" onClick={() => { this.handleSave() }}>{t("Save")}</Button>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
                {
                    this.state.showSuccessResponse ? <ToastMsg severity={"success"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)}/> : null
                }
                {
                    this.state.showErrorResponse ? <ToastMsg severity={"error"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)}/> : null
                }
            </Box>
        )
    }
}
export default withTranslation()(TokenPrefix);
