import React, { Component } from 'react'
import { CommonDeleteButton, CommonEditButton, DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import { withTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import ShowComponents from '../Common/ShowComponent';

class DocPlanLeft extends Component {
  constructor(props) {
    super(props)
    this.state = {
      PlanDatas: [],
      isDeleteCliked: false,
      SelectedList: {},
      PatientData: this.props?.PatientData,
      InHouseTreatmentList: [],
      PrecedureList: [],
      EditItem: {}
    }
  }

  componentDidUpdate(prevprops) {
    if (prevprops.GetAPiCall !== this.props.GetAPiCall && this.props.GetAPiCall) { this.GetPlanDatas() }
    if (prevprops.SelectedComponent !== this.props.SelectedComponent && this.props.SelectedComponent) {
      this.setState({ EditItem: {} })
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  GetPlanDatas() {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_PLAN_GET + `?patient_id=${this.state.PatientData?.patient_id}&appointment_id=${this.state.PatientData?.appointment_id}`).
        then((response) => {
          if (response.data.status === "success") {
            // let FinalData = []
            // let Proceduredata = response.data?.data?.procedures ? response.data?.data?.procedures : []
            // let InHouseTreatmentData = response.data?.data?.treatments ? response.data?.data?.treatments : []
            //   InHouseTreatmentData.forEach((item, index)=>{
            //       item.procedure_id = Proceduredata[index]?.id ? Proceduredata[index]?.id : ""
            //       item.procedure_name = Proceduredata[index]?.name ? Proceduredata[index]?.name : ""
            //       item.procedure_description = Proceduredata[index]?.description ? Proceduredata[index]?.description : ""
            //       FinalData.push(item)
            //   })
            this.setState({ PlanDatas: response?.data?.data ? response?.data?.data : [] })

          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  onEditHandler(data) {
    this.setState({
      EditItem: data.row
    }, () => { this.props.EditPlanDatas(data.row) })
  }
  onDeleteHandler(data) {
    try {
      this.setState({
        isDeleteCliked: true,
        SelectedList: data.row,
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  popupClose() {
    this.setState({ isDeleteCliked: false })
  }
  DeletePlan() {
    try {
      var states = this.state
      let data = {
        "treatment_id": states?.SelectedList?.treatment_id ? states?.SelectedList?.treatment_id : null,
        "procedure_id": states?.SelectedList?.procedure_id ? states?.SelectedList?.procedure_id : null,
        "appointment_id": this.props?.PatientData?.appointment_id
      }
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_PLAN_DELETE).
        then((response) => {
          if (response.data.status === "success") {
            this.successMessage(response.data.message)
            this.setState({ isDeleteCliked: false }, () => { this.GetPlanDatas() })
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }




  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  render() {
    try {
      const { t } = this.props
      this.state.PlanDatas.forEach((element, index) => { element.sno = index + 1 })
      const PlanListColumn = [
        {
          field: "treatment_name", flex: 0.25, headerName: t("Treatment"), headerAlign: "center", align: 'center',
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.treatment_name ? params?.row?.treatment_name?.length > 12 ?
              <Tooltip placement='top' title={params?.row?.treatment_name} arrow>
                <div>{params?.row?.treatment_name.slice(0, 12) + "..."}</div></Tooltip> :
              params?.row?.treatment_name : "-"}
          </Box>)
        },
        {
          field: "dosage", flex: 0.30, headerName: t("Dosage"), headerAlign: "center", align: 'center',
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.dosage ? params?.row?.dosage?.length > 12 ?
              <Tooltip placement='top' title={params?.row?.dosage} arrow>
                <div>{params?.row?.dosage.slice(0, 12) + "..."}</div></Tooltip> :
              params?.row?.dosage : "-"}
          </Box>)
        },
        {
          field: "route", flex: 0.30, headerName: t("Route"), headerAlign: "center", align: 'center',
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.route ? params?.row?.route?.length > 12 ?
              <Tooltip placement='top' title={params?.row?.route} arrow>
                <div>{params?.row?.route.slice(0, 12) + "..."}</div></Tooltip> :
              params?.row?.route : "-"}
          </Box>)
        },
        {
          field: "procedure_name", flex: 0.30, headerName: t("Procedure"), headerAlign: "center", align: 'center',
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.procedure_name ? params?.row?.procedure_name?.length > 12 ?
              <Tooltip placement='top' title={params?.row?.procedure_name} arrow>
                <div>{params?.row?.procedure_name.slice(0, 12) + "..."}</div></Tooltip> :
              params?.row?.procedure_name : "-"}
          </Box>)
        },
        {
          field: "description", flex: 0.30, headerName: t("Description"), headerAlign: "center", align: 'center',
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.description ? params?.row?.description?.length > 12 ?
              <Tooltip placement='top' title={params?.row?.description} arrow>
                <div>{params?.row?.description.slice(0, 12) + "..."}</div></Tooltip> :
              params?.row?.description : "-"}
          </Box>)
        },
        {
          field: "action", flex: 0.15, headerName: t("Action"), headerAlign: "center", align: "center",
          renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <CommonEditButton size={"1.2vw"} disable={this.props?.isLocked} onClick={this.onEditHandler.bind(this, params)} />
            <CommonDeleteButton size={"1.2vw"} disable={this.props?.isLocked} onClick={this.onDeleteHandler.bind(this, params)} />
          </Box>
        }
      ]
      return (
        <ShowComponents onIntersection={this.GetPlanDatas.bind(this)}>
          <Box height={'30vh'} overflow={'auto'}>
            <DataGrid
              density='compact'
              rows={this.state.PlanDatas}
              columns={PlanListColumn}
              getRowId={(row) => row['sno']}
              headerHeight={40}
              hideFooter={true}
              components={{
                NoRowsOverlay: () => (
                  <Stack className='eMed_DocNotes_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              disableSelectionOnClick
            />
          </Box>
          {this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.isDeleteCliked ? <DeletePopup
            DeletTitle={`${t("deleteMsg")} ${t("Plan")} ${this.state.SelectedList ? this.state.SelectedList?.treatment_name : "-"} ?`}
            deleteAlertPopupClose={this.popupClose.bind(this)}
            removeData={this.DeletePlan.bind(this)}
          /> : null}
        </ShowComponents>
      )
    }
    catch (e) {

    }
  }
}
export default withTranslation()(DocPlanLeft)
