import React, { Component } from 'react'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { Autocomplete, Box, Button, CircularProgress, Modal, Tab, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import CommonGridHeader, { CommonPatientCard, CommonPatientDetails } from '../../../../Components/Common Components/CommonComponents'
import { getCachevalue, localGetItem, setCachevalue } from '../../../../Utility/Services/CacheProviderService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Colors } from '../../../../Styles/Colors'
import { DataGrid } from '@mui/x-data-grid'
import { splitDate, timeOnlyConvert } from '../../../../Components/CommonFunctions/CommonFunctions'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from 'luxon';
import Loader from "../../../../Components/Loader"

export default class DocCollabDoctorNotes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      patientDetails: {},
      clinicId: '',
      patientId: '',
      appointmentId: '',
      doctorNotesData: [],
      page: 0,
      pageSize: 10,
      loading: false,
      specializationList: [],
      doctorNameData: [],
      selectedSpecialization: null,
      speclizationName: '',
      doctorServiceType: '',
      isOPT: false,
      selectedTemplateName: [],
      createMealPlan: false,
      mealDaysData: [],
      addendumPopup: false,
      Fromdate: new Date(),
      Todate: new Date(),
      SelectedDoctor: null,
      logPopup: false,
      OPTCollabData: [],
      CollabHistory: [],
      historyPopup: false,
      OPTTable: false
    }
  }

  componentDidMount() {

    let clinicId = getCachevalue('SelectedDoctorClinic')
    let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
    let logginedData = localGetItem('loggedInUserInfo')
    let loggedInUserInfo = JSON.parse(logginedData)
    this.setState({
      clinicId: clinicId,
      appointmentId: appointmentId?.appointment_id,
      patientId: appointmentId?.patient_id,
      doctorServiceType: loggedInUserInfo?.service_type,
      LogedDoctorID: loggedInUserInfo?.doctor_id ? loggedInUserInfo?.doctor_id : "",
      isOPT: loggedInUserInfo?.service_type === "OPT" ? true : false,
    }, () => {
      this.getPatientDetailData()
      this.getDoctorSpecializationList()
      this.getDoctorListData()
    })
  }

  getlogData = (id) => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_COLLABRATIVE_LOG + '?appointment_id=' + id + "&clinic_id=" + this.state?.clinicId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              CollabHistory: response?.data?.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPatientDetailData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              patientDetails: response?.data?.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getDoctorSpecializationList = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_SPECIALIZATION_LIST)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              specializationList: response?.data?.data
            }, () => {
              this.state.specializationList?.forEach(element => {
                if (this.state.doctorServiceType === element?.role_name) {
                  this.setState({
                    selectedSpecialization: element,
                    speclizationName: element?.role_name
                  }, () => { this.getCollabDoctorNotesReport() })
                }
              })
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getDoctorListData = () => {
    try {
      RestAPIService.getAll(Serviceurls.HOME_GET_CLINIC_DOCTORS + `?clinic_id=${this.state.clinicId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              doctorNameData: response?.data?.data
            }, () => {
              this.state.doctorNameData?.forEach(element => {
                if (this.state.LogedDoctorID === element?.doctor_id) {
                  this.setState({
                    SelectedDoctor: element,
                  }, () => { this.getCollabDoctorNotesReport() })
                }
              })
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getCollabDoctorNotesReport = () => {
    try {
      let specialization = this.state.selectedSpecialization?.role_name ? this.state?.selectedSpecialization?.role_name : this.state?.speclizationName
      let doctor = this.state?.SelectedDoctor?.doctor_id ? this.state?.SelectedDoctor?.doctor_id : ""
      var Fromdate = this.state?.Fromdate ? DateTime?.fromJSDate(new Date(this.state?.Fromdate)).toFormat('yyyy-MM-dd') : ""
      var Todate = this.state?.Todate ? DateTime?.fromJSDate(new Date(this.state?.Todate)).toFormat('yyyy-MM-dd') : ""
      this.LoaderFunction(true)
      if (this.state?.patientId && specialization && doctor && Fromdate && Todate && this.state?.clinicId) {
        RestAPIService.getAll(Serviceurls.DOC_COLLAB_DOCTOR_NOTES_REPORT + `?patient_id=${this.state?.patientId}&specialization=${specialization}&doctor=${doctor}&from_date=${Fromdate}&to_date=${Todate}&clinic_id=${this.state?.clinicId}`)
          .then((response) => {
            if (response.data.status === 'success') {
              this.LoaderFunction(false)
              this.state.selectedSpecialization?.role_name === "OPT" ?
                this.setState({
                  OPTTable: true,
                  OPTCollabData: response?.data?.datas
                }) : this.setState({
                  OPTTable: false,
                  doctorNotesData: response?.data?.datas
                })
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.LoaderFunction(false)
              this.errorMessage(error?.response?.data?.message)
            } else {
              this.LoaderFunction(false)
              this.errorMessage(error?.message)
            }
          })
      } else {
        this.LoaderFunction(false)
      }
    } catch (error) {
      this.LoaderFunction(false)
      this.errorMessage(error.message)
    }
  }

  PostAddendum = () => {
    try {
      let data = {
        addendum_notes: this.state.Addcomments
      }
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_LOCK + `${this.state.appointmentId}/lock/`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response.data.message)
            this.setState({ addendumPopup: false, Addcomments: "" }, () => {
              this.getCollabDoctorNotesReport()
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  Specialisation = (spec) => {
    if (spec == "DOCTOR") {
      return "General Physician"
    }
    else if (spec == "DB") {
      return "Diabetology"
    }
    else if (spec == "PD") {
      return "Pediatric"
    }
    else if (spec == "OBGYN") {
      return "Gynaecology"
    }
    else if (spec == "PSY") {
      return "Psychiatric"
    }
    else if (spec == "OPT") {
      return "Ophthalmology"
    }
    else {
      return spec
    }
  }

  renderVisionTable = (Heading, value, width, Top) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", height: "3.5vw", width: width }}>
        <div style={{ fontSize: "0.95vw", marginBottom: "0.5vw", marginTop: Top, }}>{Heading}</div>
        <div style={{ paddingLeft: "-0.35vw", fontSize: "0.85vw", fontWeight: "600" }}>{value ? value : "-"}</div>
      </div>
    )
  }

  renderGP = (Heading, LE, RE) => {
    return (
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: "0.5vw", paddingLeft: "0.75vw", paddingRight: "0.75vw" }}>
        <div>{Heading}</div>
        <div>{LE ?
          LE?.length > 5 ?
            <Tooltip placement='top' title={LE}>
              <Typography fontSize={'0.9vw'} fontWeight={600} mr={'0.5vw'}>{LE?.slice(0, 5) + '...'}</Typography>
            </Tooltip> :
            <Typography fontSize={'0.9vw'} fontWeight={600} mr={'0.5vw'}>{LE}</Typography> : '-'
        }</div>
        <div>{RE ?
          RE?.length > 5 ?
            <Tooltip placement='top' title={RE}>
              <Typography fontSize={'0.9vw'} fontWeight={600} mr={'0.5vw'}>{RE?.slice(0, 5) + '...'}</Typography>
            </Tooltip> :
            <Typography fontSize={'0.9vw'} fontWeight={600} mr={'0.5vw'}>{RE}</Typography> : '-'
        }</div>
      </div>
    )
  }

  HistoryPopup = () => {
    return (
      <div>
        <Modal open={this.state.historyPopup}>
          <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "70vw", height: "70vh", maxHeight: "100%" }}>
            <Box component={'div'} p={'0.75vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{ borderBottom: "1px solid gray" }}>
              <Typography fontSize={'0.95vw'} fontWeight={600} color={Colors.SecondaryText}>History</Typography>
              <Button
                className='Common_Btn_Min_Width'
                onClick={() => this.setState({ historyPopup: false })}
              >
                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
              </Button>
            </Box>
            <Box sx={{ maxHeight: '60vh', overflow: 'auto', border: '1px solid lightgray' }} component={'div'}>
              <Table stickyHeader size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell width={'16%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Modified By</TableCell>
                    <TableCell width={'20%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Modified Date and Time</TableCell>
                    <TableCell width={'16%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Modified Column</TableCell>
                    <TableCell width={'16%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Modified Fieds</TableCell>
                    <TableCell width={'16%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Modified From</TableCell>
                    <TableCell width={'16%'} sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Modified To</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.CollabHistory?.length > 0 ? this.state.CollabHistory?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.modified_by ? item?.modified_by : "-"}</TableCell>
                      <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.modified_date ? item?.modified_date : '-'}</TableCell>
                      <TableCell style={{ fontSize: "0.9vw", fontWeight: 600 }}>{item?.modified_column ? item?.modified_column : '-'}</TableCell>
                      <TableCell>
                        {item?.modified_fields ? item?.modified_fields?.length > 20 ?
                          <Tooltip placement='top' title={item?.modified_fields}>
                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.modified_fields?.slice(0, 20) + '...'}</Typography>
                          </Tooltip> :
                          <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.modified_fields}</Typography> : '-'}
                      </TableCell>
                      <TableCell>
                        {item?.modified_from ? JSON.stringify(item?.modified_from)?.length > 20 ?
                          <Tooltip placement='top' title={JSON.stringify(item?.modified_from)}>
                            <Typography fontSize={'0.9vw'} fontWeight={600}>{(JSON.stringify(item?.modified_from)?.slice(1, -1))?.slice(0, 20) + '...'}</Typography>
                          </Tooltip> :
                          <Typography fontSize={'0.9vw'} fontWeight={600}>{JSON.stringify(item?.modified_from)}</Typography> : '-'}
                      </TableCell>
                      <TableCell>
                        {item?.modified_to ? JSON.stringify(item?.modified_to)?.length > 20 ?
                          <Tooltip placement='top' title={JSON.stringify(item?.modified_to)}>
                            <Typography fontSize={'0.9vw'} fontWeight={600}>{(JSON.stringify(item?.modified_to)?.slice(1, -1))?.slice(0, 20) + '...'}</Typography>
                          </Tooltip> :
                          <Typography fontSize={'0.9vw'} fontWeight={600}>{JSON.stringify(item?.modified_to)}</Typography> : '-'}
                      </TableCell>
                    </TableRow>
                  )) : null}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Modal>
      </div>
    )
  }

  addendumPopup = () => {
    let states = this.state
    return (
      <div>
        <Modal open={this.state.addendumPopup}>
          <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up' style={{ width: "33vw", height: "40vh", maxHeight: "100%" }}>
            <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography fontSize={'0.9vw'} fontWeight={600}>Addendum</Typography>
              <Button
                className='Common_Btn_Min_Width'
                onClick={() => this.setState({ addendumPopup: false, Addcomments: "" })}
              >
                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
              </Button>
            </Box>
            <div style={{ fontWeight: 600, marginLeft: "0.5vw", color: Colors.SecondaryText }}>Reason</div>
            <div style={{ height: "10vw", width: "95%", paddingLeft: "1vw" }}>
              <TextField
                label={("Enter Description")}
                style={{ height: "8vw", marginTop: "0.75vw", width: "100%" }}
                multiline={true}
                rows={4}
                variant="outlined"
                value={this.state.Addcomments}
                onChange={(event) => {
                  let value = event.target.value
                  if (value?.length <= 250) {
                    this.setState({ Addcomments: value })
                  } else {
                    this.errorMessage('Allowed only 250 characters')
                  }
                }}
              />
            </div>
            <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
              <Button
                sx={{ height: '2vw', width: '7vw', textTransform: 'capitalize', mr: '1vw' }}
                variant='contained'
                onClick={() => { this.setState({ addendumPopup: false, Addcomments: "" }) }}
              >Cancel</Button>
              <Button
                sx={{ height: '2vw', width: '7vw', textTransform: 'capitalize' }}
                variant='contained'
                onClick={() => {
                  if (this.state.Addcomments != "") {
                    this.PostAddendum()
                  } else {
                    this.errorMessage('Enter Addendum Comments')
                  }
                }}
              >Addendum</Button>
            </Box>
          </Box>
        </Modal>
      </div>
    )
  }

  renderTableData = () => {
    try {
      this.state.doctorNotesData?.forEach((element, index) => element.sno = index + 1)
      return (
        <Box component={'div'} width={'100vw'}>
          <Box component={'div'} height={'25vw'} overflow={'scroll'} border={'1px solid lightgray'} m={'0.5vw'}>
            <Table stickyHeader sx={{ backgroundColor: Colors.white }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: Colors.SecondaryText, fontWeight: 600, fontSize: '0.9vw', backgroundColor: Colors.Background, width: '10vw' }}>Date</TableCell>
                  <TableCell sx={{ color: Colors.SecondaryText, fontWeight: 600, fontSize: '0.9vw', backgroundColor: Colors.Background, width: '16.3vw' }}>Vitals</TableCell>
                  <TableCell sx={{ color: Colors.SecondaryText, fontWeight: 600, fontSize: '0.9vw', backgroundColor: Colors.Background, width: '14.3vw' }}>Symptoms</TableCell>
                  <TableCell sx={{ color: Colors.SecondaryText, fontWeight: 600, fontSize: '0.9vw', backgroundColor: Colors.Background, width: '14.3vw' }}>Examination</TableCell>
                  <TableCell sx={{ color: Colors.SecondaryText, fontWeight: 600, fontSize: '0.9vw', backgroundColor: Colors.Background, width: '14.3vw' }}>Diagnosis</TableCell>
                  <TableCell sx={{ color: Colors.SecondaryText, fontWeight: 600, fontSize: '0.9vw', backgroundColor: Colors.Background, width: '14.3vw' }}>Prescription</TableCell>
                  <TableCell sx={{ color: Colors.SecondaryText, fontWeight: 600, fontSize: '0.9vw', backgroundColor: Colors.Background, width: '14.3vw' }}>In House Treatment</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.loading ?
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'27vw'}>
                      <CircularProgress color='primary' />
                    </Box>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                  :
                  this.state.doctorNotesData?.length > 0 && this.state.doctorNotesData?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item?.appointment_date ? splitDate(item?.appointment_date) : '-'}</TableCell>
                      <TableCell>{item?.vitals?.length ? item?.vitals?.map((vitals, vIndex) => (
                        <Box component={'div'} key={vIndex}>
                          <Box component={'div'} display={'flex'}>
                            <Box flex={0.5}>
                              <Box component={'div'} display={'flex'} justifyContent={'space-between'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Height</Typography>
                                <Typography fontSize={'0.9vw'} pr={'0.3vw'}>{vitals?.height_cm}</Typography>
                              </Box>
                              <Box component={'div'} display={'flex'} justifyContent={'space-between'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>
                                  <Tooltip placement='top' title='Systolic Blood Presure'><div>Sys.BP</div></Tooltip>
                                </Typography>
                                <Typography fontSize={'0.9vw'} pr={'0.3vw'}>{vitals?.blood_pressure_systolic}</Typography>
                              </Box>
                              <Box component={'div'} display={'flex'} justifyContent={'space-between'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>
                                  <Tooltip placement='top' title='Temperature'><div>Temp</div></Tooltip>
                                </Typography>
                                <Typography fontSize={'0.9vw'} pr={'0.3vw'}>{vitals?.temperature}</Typography>
                              </Box>
                              <Box component={'div'} display={'flex'} justifyContent={'space-between'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>
                                  <Tooltip placement='top' title='Pulse Rate'><div>Pul.Rate</div></Tooltip>
                                </Typography>
                                <Typography fontSize={'0.9vw'} pr={'0.3vw'}>{vitals?.pulse_rate}</Typography>
                              </Box>
                            </Box>
                            <Box flex={0.5}>
                              <Box component={'div'} display={'flex'} justifyContent={'space-between'}>
                                <Typography pl={'0.3vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Weight</Typography>
                                <Typography fontSize={'0.9vw'}>{vitals?.weight_kg}</Typography>
                              </Box>
                              <Box component={'div'} display={'flex'} justifyContent={'space-between'}>
                                <Typography pl={'0.3vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>
                                  <Tooltip placement='top' title='Diastolic Blood Presure'><div>Dia.BP</div></Tooltip>
                                </Typography>
                                <Typography fontSize={'0.9vw'} >{vitals?.blood_pressure_diastolic}</Typography>
                              </Box>
                              <Box component={'div'} display={'flex'} justifyContent={'space-between'}>
                                <Typography pl={'0.3vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>
                                  <Tooltip placement='top' title='Pulse Oximeter'><div>Pulse Oxi</div></Tooltip>
                                </Typography>
                                <Typography fontSize={'0.9vw'}>{vitals?.pulse_oximetry}</Typography>
                              </Box>
                              <Box component={'div'} display={'flex'} justifyContent={'space-between'}>
                                <Typography pl={'0.3vw'} fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>
                                  <Tooltip placement='top' title='Blood Glucose'><div>Blood Glu</div></Tooltip>
                                </Typography>
                                <Typography fontSize={'0.9vw'}>{vitals?.blood_sugar}</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      )) : '-'}</TableCell>
                      <TableCell>
                        {item?.symptoms?.length ? item?.symptoms?.map((symp, sysIndex) => (
                          <Box component={'div'} key={sysIndex}>
                            <Typography fontSize={'0.9vw'}>
                              {`${symp?.symptom} (${symp?.duration} ${symp?.duration_mode === '1' ? 'Day' : symp?.duration_mode === '2' ? 'Weeks' :
                                symp?.duration_mode === '3' ? 'Months' : 'Year'})`}
                            </Typography>
                          </Box>
                        )) : '-'}
                      </TableCell>
                      <TableCell>
                        {item?.examination?.length ? item?.examination?.map((exam, exaIndex) => (
                          <Box component={'div'} key={exaIndex}>
                            <Typography fontSize={'0.9vw'}>
                              {`${exam?.examination_name}`}
                            </Typography>
                          </Box>
                        )) : '-'}
                      </TableCell>
                      <TableCell>
                        {item?.assessment?.length ? item?.assessment?.map((assess, assIndex) => (
                          <Box component={'div'} key={assIndex}>
                            <Typography>
                              <Box component={'div'} display={'flex'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>ICD</Typography>
                                <Typography fontSize={'0.9vw'} pl={'0.5vw'}>{assess?.icd_code}</Typography>
                              </Box>
                            </Typography>
                          </Box>
                        )) : '-'}
                      </TableCell>
                      <TableCell>
                        {item?.prescription?.length ? item?.prescription?.map((prescrp, preIndex) => (
                          <Box component={'div'} key={preIndex}>
                            {(prescrp?.brand_name + prescrp?.dosage_strength + prescrp?.dosage_type)?.length > 20 ?
                              <Tooltip placement='top' title={prescrp?.brand_name + prescrp?.dosage_strength + prescrp?.dosage_type}><div>{
                                <Typography fontSize={'0.9vw'} fontWeight={600}>{`${prescrp?.brand_name ? prescrp?.brand_name?.slice(0, 10) + '...' : '-'}`}
                                  <Typography component={'span'} fontSize={'0.8vw'}>{` ${(prescrp?.dosage_strength + prescrp?.dosage_type)?.slice(0, 10) + '...'}`}</Typography>
                                </Typography>}</div></Tooltip>
                              :
                              <Typography fontSize={'0.9vw'} fontWeight={600}>{`${prescrp?.brand_name}`}
                                <Typography component={'span'} fontSize={'0.8vw'}>{` ${prescrp?.dosage_strength} ${prescrp?.dosage_type}`}</Typography>
                              </Typography>
                            }

                          </Box>
                        )) : '-'}
                      </TableCell>
                      <TableCell>
                        {item?.treatment?.inhouse?.length ? item?.treatment?.inhouse?.map((inhouse, inhouIndex) => (
                          <Box component={'div'} key={inhouIndex}>
                            <Typography fontSize={'0.9vw'}>
                              {inhouse?.name}
                            </Typography>
                          </Box>
                        )) : '-'}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </Box >
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderCommonFilter = () => {
    try {
      return (
        <Box component={'div'} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} mt={'0.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
            <Autocomplete
              clearIcon
              size='small'
              sx={{ width: '15vw' }}
              options={this.state.doctorNameData}
              getOptionLabel={(option) => option?.first_name}
              value={this.state.SelectedDoctor}
              limitTags={3}
              autoComplete='off'
              onChange={(event, newValue) => {
                this.setState({
                  SelectedDoctor: newValue
                })
              }}
              renderInput={(params) => <TextField {...params} label='Select Doctor *' />}
              renderOption={(props, option) => (
                <li {...props} key={option?.doctor_id}>
                  {option?.first_name}
                </li>
              )}
            />
          </Box>
          <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} mt={'0.5vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
            <Autocomplete
              clearIcon
              size='small'
              sx={{ width: '15vw' }}
              options={this.state.specializationList}
              getOptionLabel={(option) => this.Specialisation(option?.role_name)}
              value={this.state.selectedSpecialization}
              limitTags={3}
              autoComplete='off'
              onChange={(event, newValue) => {
                this.setState({
                  selectedSpecialization: newValue
                })
              }}
              renderInput={(params) => <TextField {...params} label='Select Specialization *' />}
            />
          </Box>
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                open={this.state.DatePickerOpen}
                onOpen={() => { this.setState({ DatePickerOpen: true }) }}
                onClose={() => { this.setState({ DatePickerOpen: false }) }}
                value={this.state.Fromdate}
                name={"datePicker"}
                inputFormat='DD-MM-YYYY'
                views={["year", "month", "day"]}
                onChange={
                  (newDate) => {
                    this.setState({
                      Fromdate: newDate,
                    });
                  }}
                renderInput={(params) => <TextField
                  onKeyDown={(e) => e.preventDefault()}
                  sx={{ marginTop: '0.5vw' }}
                  size='small'
                  {...params}
                  onClick={() => { this.setState({ DatePickerOpen: true }) }}
                  label="From Date" />}
              />
            </LocalizationProvider>
          </Box>
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                open={this.state.DatePickerOpen}
                onOpen={() => { this.setState({ DatePickerOpen: true }) }}
                onClose={() => { this.setState({ DatePickerOpen: false }) }}
                value={this.state.Todate}
                name={"datePicker"}
                inputFormat='DD-MM-YYYY'
                views={["year", "month", "day"]}
                onChange={
                  (newDate) => {
                    this.setState({
                      Todate: newDate,
                    });
                  }}
                renderInput={(params) => <TextField
                  onKeyDown={(e) => e.preventDefault()}
                  size='small'
                  sx={{ marginTop: '0.5vw' }}
                  {...params}
                  onClick={() => { this.setState({ DatePickerOpen: true }) }}
                  label="To Date"
                />}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button onClick={() => {
              this.setState({
                Fromdate: new Date(),
                Todate: new Date(),
                SelectedDoctor: null,
                selectedSpecialization: null,
              }, () => { setTimeout(() => { this.getCollabDoctorNotesReport() }, 2000) })
              this.state.specializationList?.forEach(element => {
                if (this.state.doctorServiceType === element?.role_name) {
                  this.setState({
                    selectedSpecialization: element,
                    speclizationName: element?.role_name
                  })
                }
                this.state.doctorNameData?.forEach(element => {
                  if (this.state.LogedDoctorID === element?.doctor_id) {
                    this.setState({
                      SelectedDoctor: element,
                    })
                  }
                })
              })
            }}
              variant='outlined'
              size='small'
              sx={{ textTransform: 'capitalize', marginRight: '1vw' }}
            >Clear</Button>
            <Button
              variant='contained'
              size='small'
              sx={{ textTransform: 'capitalize', marginRight: '1vw' }}
              onClick={() => { this.getCollabDoctorNotesReport() }}>
              Filter</Button>
          </Box>
        </Box>
      )
    } catch (e) {

    }
  }

  renderOPTCollabTable = () => {
    let { history } = this.props
    try {
      return (
        <Box component={'div'} width={'100vw'}>
          <Box component={'div'} mt={'0.5vw'} display={'flex'} height={'70vh'} border={'1px solid lightgray'} sx={{ backgroundColor: Colors.Background }}>
            <Box component={'div'} flex={1.5}>
              <Box component={'div'} p={'0.5vw'} textAlign={'center'} borderBottom={'1px solid lightgray'} position={'sticky'}>
                <Typography color={Colors.SecondaryText} pt={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>Date & Dr Name</Typography>
              </Box>
              {this.state.OPTCollabData?.length > 0 && this.state.OPTCollabData?.map((item, index) => (
                <Box component={'div'} key={index} display={'flex'} height={'150vh'} border={'1px solid lightgray'} sx={{ backgroundColor: Colors.white, paddingTop: "20vw", paddingLeft: "1vw" }}>
                  <Box component={'div'} flex={2}>
                    <div style={{ marginTop: "0.5vw", marginLeft: "0.5vw" }}>
                      <div style={{ fontSize: "0.9vw" }}>{item?.appointment_date ? `${(item?.appointment_date.split("T")[0]).split("-").reverse().join("-")} | ${timeOnlyConvert(item?.appointment_date.split("T")[1])}` : "-"}</div>
                      <div style={{ fontSize: "0.9vw", fontWeight: "600" }}>{item?.from_doctor ? item?.from_doctor : "-"}</div>
                    </div>
                    <div style={{ marginTop: "2vw", marginLeft: "0.5vw" }}>
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ backgroundColor: Colors.grayShade, display: "flex", alignItems: "center", justifyContent: "center" }}><Box component={'img'} src={ImagePaths.Append.default} height={'1.3vw'} width={'1.3vw'} /></div>
                        <div style={{ fontWeight: 600, color: "black", marginLeft: "0.65vw" }}>Append Notes</div>
                      </div>
                      <div style={{ marginTop: "0.65vw" }}>{item?.addendum_notes ? item?.addendum_notes : null}</div>
                    </div>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box component={'div'} flex={7.5} borderLeft={'1px solid lightgray'}>
              <Box component={'div'} display={'flex'} p={'0.5vw'} justifyContent={'space-around'} alignItems={'center'} borderBottom={'1px solid lightgray'} position={'sticky'}>
                <Typography color={Colors.SecondaryText} pt={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>Doctor Notes</Typography>
              </Box>
              {this.state.OPTCollabData?.length > 0 && this.state.OPTCollabData?.map((item, index) => (
                <div>
                  <Box component={'div'} display={'flex'} key={index} height={'50vh'} border={'1px solid lightgray'} sx={{ backgroundColor: Colors.white }}>
                    <Box component={'div'} flex={2} borderRight={'1px solid lightgray'}>
                      <Box component={'div'} p={'0.5vw'} textAlign={'center'} borderBottom={'1px solid lightgray'} position={'sticky'}>
                        <Typography color={Colors.SecondaryText} pt={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>Complaints</Typography>
                      </Box>
                      <div style={{ height: "40vh", overflow: "scroll" }}>
                        {item?.complaint?.length > 0 ? item?.complaint.map((complaint, CompIndex) => (
                          <div key={CompIndex} style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center" }}>
                            <div style={{ marginTop: "1.5vw", marginLeft: "2vw" }}>
                              <div>Problem</div>
                              <Typography fontSize={'0.8vw'} fontWeight={600}>{complaint?.complaints == 1 ? "OD" : complaint?.complaints == 2 ? "OS" : "OU"}</Typography>
                            </div>
                            <div style={{ marginTop: "1.5vw", marginLeft: "2vw" }}>
                              <div>Complaints</div>
                              {complaint?.complaint_description ?
                                complaint?.complaint_description?.length > 10 ?
                                  <Tooltip placement='top' title={complaint?.complaint_description}>
                                    <Typography fontSize={'0.8vw'} fontWeight={600} mr={'0.5vw'}>{complaint?.complaint_description?.slice(0, 10) + '...'}</Typography>
                                  </Tooltip> :
                                  <Typography fontSize={'0.8vw'} fontWeight={600} mr={'0.5vw'}>{complaint?.complaint_description}</Typography> : '-'
                              }
                            </div>
                            <div style={{ marginTop: "0.2vw", marginLeft: "2vw" }}>
                              <div>Duration</div>
                              {complaint?.duration_time ?
                                complaint?.duration_time?.length > 10 ?
                                  <Tooltip placement='top' title={complaint?.duration_time}>
                                    <Typography fontSize={'0.8vw'} fontWeight={600} mr={'0.5vw'}>{complaint?.duration_time?.slice(0, 10) + '...'}</Typography>
                                  </Tooltip> :
                                  <Typography fontSize={'0.8vw'} fontWeight={600} mr={'0.5vw'}>{complaint?.duration_time + " " + (complaint?.duration_type == 1 ? "Days" : complaint?.duration_type == 2 ? "Weeks" : complaint?.duration_type == 3 ? "Months" : "Years")}</Typography> : '-'
                              }
                            </div>
                            <div style={{ marginTop: "0.2vw", marginLeft: "2vw" }}>
                              <div>Comments</div>
                              {complaint?.comments ?
                                complaint?.comments?.length > 10 ?
                                  <Tooltip placement='top' title={complaint?.comments}>
                                    <Typography fontSize={'0.8vw'} fontWeight={600} mr={'0.5vw'}>{complaint?.comments?.slice(0, 10) + '...'}</Typography>
                                  </Tooltip> :
                                  <Typography fontSize={'0.8vw'} fontWeight={600} mr={'0.5vw'}>{complaint?.comments}</Typography> : '-'
                              }
                            </div>
                          </div>
                        )) : <div style={{ marginTop: "2vw", paddingLeft: "8vw" }}>{"-"}</div>}
                      </div>
                    </Box>
                    <Box component={'div'} flex={2} borderRight={'1px solid lightgray'}>
                      <Box component={'div'} p={'0.5vw'} textAlign={'center'} borderBottom={'1px solid lightgray'} position={'sticky'}>
                        <Typography color={Colors.SecondaryText} pt={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>Ocular History</Typography>
                      </Box>
                      <div style={{ height: "40vh", overflow: "scroll" }}>
                        {Object.keys(item?.ocular).length > 0 ?
                          <div style={{ display: "flex", flexDirection: "Column", alignItems: "flex-start", justifyContent: "center" }}>
                            {item?.ocular?.medication?.length > 0 ? item?.ocular?.medication.map((Medic, MedicIndex) => (
                              <div key={MedicIndex} style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%" }}>
                                <div style={{ marginTop: "0.5vw", marginLeft: "1vw", width: "50%" }}>
                                  <div>Medication</div>
                                  <Typography fontSize={'0.8vw'} fontWeight={600}>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                      <div style={{ fontSize: "0.9vw" }}>{Medic?.drug_name}</div>
                                      <div style={{ fontSize: "0.8vw" }}>{`${Medic?.years ? Medic.years : "0"}Y ${Medic?.months ? Medic?.months : "0"}M ${Medic.days ? Medic.days : "0"}D`}</div>
                                    </div>
                                  </Typography>
                                </div>
                                <div style={{ marginTop: "0.5vw", marginLeft: "1vw", width: "50%" }}>
                                  <div>Comments</div>
                                  <Typography fontSize={'0.8vw'} fontWeight={600}>
                                    {Medic?.comments ? Medic?.comments?.length > 15 ?
                                      <Tooltip placement='top' title={Medic?.comments}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{Medic?.comments?.slice(0, 15) + '...'}</Typography>
                                      </Tooltip> :
                                      <Typography fontSize={'0.9vw'} fontWeight={600}>{Medic?.comments}</Typography> : '-'}
                                  </Typography>
                                </div>
                              </div>
                            )) : <div style={{ marginTop: "2vw", paddingLeft: "8vw" }}>{"-"}</div>}
                            {item?.ocular?.surgery?.length > 0 ? item?.ocular?.surgery.map((Surgery, SurgeryIndex) => (
                              <div key={SurgeryIndex} style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%" }}>
                                <div style={{ marginTop: "0.5vw", marginLeft: "1vw", width: "50%" }}>
                                  <div>Surgery</div>
                                  <Typography fontSize={'0.8vw'} fontWeight={600}>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                      <div style={{ fontSize: "0.9vw" }}>{Surgery?.surgery_name}</div>
                                      <div style={{ fontSize: "0.8vw" }}>{`${Surgery?.no_of_years ? Surgery.no_of_years : "0"}Y`}</div>
                                    </div>
                                  </Typography>
                                </div>
                                <div style={{ marginTop: "0.5vw", marginLeft: "1vw", width: "50%" }}>
                                  <div>Comments</div>
                                  <Typography fontSize={'0.8vw'} fontWeight={600}>
                                    {Surgery?.reason ? Surgery?.reason?.length > 15 ?
                                      <Tooltip placement='top' title={Surgery?.reason}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{Surgery?.reason?.slice(0, 15) + '...'}</Typography>
                                      </Tooltip> :
                                      <Typography fontSize={'0.9vw'} fontWeight={600}>{Surgery?.reason}</Typography> : '-'}
                                  </Typography>
                                </div>
                              </div>
                            )) : <div style={{ marginTop: "4vw", paddingLeft: "8vw" }}>{"-"}</div>}
                          </div>
                          : <div style={{ marginTop: "2vw", paddingLeft: "8vw" }}>{"-"}</div>}
                      </div>
                    </Box>
                    <Box component={'div'} flex={2} borderRight={'1px solid lightgray'}>
                      <Box component={'div'} p={'0.5vw'} textAlign={'center'} borderBottom={'1px solid lightgray'} position={'sticky'}>
                        <Typography color={Colors.SecondaryText} pt={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>Systemic illness</Typography>
                      </Box>
                      <div style={{ height: "40vh", overflow: "scroll" }}>
                        {Object.keys(item?.illness).length > 0 ?
                          <div style={{ display: "flex", flexDirection: "Column", alignItems: "flex-start", justifyContent: "center" }}>
                            {item?.illness?.allergie?.length > 0 ? item?.illness?.allergie.map((allergie, allergIndex) => (
                              <div key={allergIndex} style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%" }}>
                                <div style={{ marginTop: "0.5vw", marginLeft: "1vw", width: "50%" }}>
                                  <div>Allergies</div>
                                  <Typography fontSize={'0.8vw'} fontWeight={600}>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                      <div style={{ fontSize: "0.9vw" }}>{allergie?.name}</div>
                                      <div style={{ fontSize: "0.8vw" }}>{`${allergie?.no_of_years ? allergie.no_of_years : "0"}Y ${allergie?.no_of_months ? allergie?.no_of_months : "0"}M`}</div>
                                    </div>
                                  </Typography>
                                </div>
                                <div style={{ marginTop: "0.5vw", marginLeft: "1vw", width: "50%" }}>
                                  <div>Comments</div>
                                  <Typography fontSize={'0.8vw'} fontWeight={600}>
                                    {allergie?.comments ? allergie?.comments?.length > 15 ?
                                      <Tooltip placement='top' title={allergie?.comments}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{allergie?.comments?.slice(0, 15) + '...'}</Typography>
                                      </Tooltip> :
                                      <Typography fontSize={'0.9vw'} fontWeight={600}>{allergie?.comments}</Typography> : '-'}
                                  </Typography>
                                </div>
                              </div>
                            )) : <div style={{ marginTop: "2vw", paddingLeft: "8vw" }}>{"-"}</div>}
                            {item?.illness?.illnesses?.length > 0 ? item?.illness?.illnesses.map((illness, IllIndex) => (
                              <div key={IllIndex} style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%" }}>
                                <div style={{ marginTop: "0.5vw", marginLeft: "1vw", width: "50%" }}>
                                  <div>Illnesses</div>
                                  <Typography fontSize={'0.8vw'} fontWeight={600}>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                      <div style={{ fontSize: "0.9vw" }}>{illness?.illness_name}</div>
                                      <div style={{ fontSize: "0.8vw" }}>{`${illness?.year ? illness.year : "0"}Y ${illness?.month ? illness?.month : "0"}M`}</div>
                                    </div>
                                  </Typography>
                                </div>
                                <div style={{ marginTop: "0.5vw", marginLeft: "1vw", width: "50%" }}>
                                  <div>Comments</div>
                                  <Typography fontSize={'0.8vw'} fontWeight={600}>
                                    {illness?.treatment ? illness?.treatment?.length > 15 ?
                                      <Tooltip placement='top' title={illness?.treatment}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{illness?.treatment?.slice(0, 15) + '...'}</Typography>
                                      </Tooltip> :
                                      <Typography fontSize={'0.9vw'} fontWeight={600}>{illness?.treatment}</Typography> : '-'}
                                  </Typography>
                                </div>
                              </div>
                            )) : <div style={{ marginTop: "4vw", paddingLeft: "8vw" }}>{"-"}</div>}
                          </div>
                          : <div style={{ marginTop: "2vw", paddingLeft: "8vw" }}>{"-"}</div>}
                      </div>
                    </Box>
                    <Box component={'div'} flex={2}>
                      <Box component={'div'} p={'0.5vw'} textAlign={'center'} borderBottom={'1px solid lightgray'} position={'sticky'}>
                        <Typography color={Colors.SecondaryText} pt={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>Vision Assessment</Typography>
                      </Box>
                      <div style={{ height: "40vh", overflow: "scroll" }}>
                        {item?.vision_assessment?.length > 0 ? item?.vision_assessment.map((vission, vissionIndex) => (
                          <div key={vissionIndex}>
                            {vission?.eye_type == "RE" ? <div>
                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingTop: "1vw", paddingBottom: "1vw" }}>
                                <Box component={'div'} style={{ marginLeft: "0.3vw" }}>
                                  <Typography fontSize={'1vw'} fontWeight={600}>{"Right Eye"}</Typography>
                                </Box>
                              </div>
                              <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", marginLeft: "0.3vw" }}>
                                {this.renderVisionTable("UCVA", vission.UCVA, "5vw", 0)}
                                {this.renderVisionTable("PH", vission.PH, "5vw", 0)}
                                {this.renderVisionTable("PG/CL", vission.PGCL, "3vw", 0)}
                                {this.renderVisionTable("NV", vission.NV, "5vw", "0.65vw")}
                                {this.renderVisionTable("CV", vission.CV, "7vw", "0.65vw")}
                              </div>
                            </div> : null}
                            {vission?.eye_type == "LE" ? <div>
                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingTop: "1vw", paddingBottom: "1vw" }}>
                                <Box component={'div'} display={'flex'} style={{ marginLeft: "0.3vw" }}>
                                  <Typography fontSize={'1vw'} fontWeight={600}>{"Left Eye"}</Typography>
                                </Box>
                              </div>
                              <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", marginLeft: "0.25vw" }}>
                                {this.renderVisionTable("UCVA", vission.UCVA, "5vw", 0)}
                                {this.renderVisionTable("PH", vission.PH, "5vw", 0)}
                                {this.renderVisionTable("PG/CL", vission.PGCL, "3vw", 0)}
                                {this.renderVisionTable("NV", vission.NV, "5vw", "0.65vw")}
                                {this.renderVisionTable("CV", vission.CV, "8vw", "0.65vw")}
                              </div>
                            </div> : null}
                          </div>
                        )) : <div style={{ marginTop: "2vw", paddingLeft: "8vw" }}>{"-"}</div>}
                      </div>
                    </Box>
                  </Box>
                  <Box component={'div'} display={'flex'} key={index} height={'50vh'} border={'1px solid lightgray'} sx={{ backgroundColor: Colors.white }}>
                    <Box component={'div'} flex={2} borderRight={'1px solid lightgray'}>
                      <Box component={'div'} p={'0.5vw'} pl={"2vw"} position={'sticky'}>
                        <Typography color={Colors.SecondaryText} pt={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>Auto Refractometry</Typography>
                      </Box>
                      <div style={{ height: "40vh", overflow: "scroll" }}>
                        <div>
                          {item?.auto_refractometry.before_dilation?.length > 0 ? item?.auto_refractometry?.before_dilation.map((Before, BeforeIndex) => (
                            Before?.eye_type === "RE" ? <div key={BeforeIndex}>
                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingTop: "1vw", paddingBottom: "1vw" }}>
                                <Box component={'div'} style={{ marginLeft: "0.3vw" }}>
                                  <Typography fontSize={'1vw'} fontWeight={600}>{"Before Dilation"}</Typography>
                                </Box>
                              </div>
                              <Box className="eMed_App_reqest_table_head" sx={{ width: "100%", paddingLeft: "2vw", paddingRight: "3vw" }}>
                                <Typography fontWeight={'600'} style={{ paddingLeft: 0.3 }} fontSize={"0.8vw"}>Dilation</Typography>
                                <Typography fontWeight={'600'} fontSize={"0.8vw"}>Right</Typography>
                              </Box>
                              <Box>
                                <Box component={'div'} className="eMed_Header_Request_item" style={{ width: "100%", height: "2vw", paddingLeft: "2vw", paddingRight: "3vw" }}>
                                  <Box>
                                    <Typography fontWeight={'600'} style={{ paddingLeft: 0.3 }} fontSize={"0.85vw"}>{"SPH"}</Typography>
                                  </Box>
                                  <Box>
                                    <Typography fontWeight={'600'} fontSize={"0.85vw"}>{Before?.eye_type === "RE" ? Before?.SPH ? Before?.SPH : "-" : "-"}</Typography>
                                  </Box>
                                </Box>
                                <Box component={'div'} className="eMed_Header_Request_item" style={{ width: "100%", height: "2vw", paddingLeft: "2vw", paddingRight: "3vw" }}>
                                  <Box>
                                    <Typography fontWeight={'600'} style={{ paddingLeft: 0.3 }} fontSize={"0.85vw"}>{"CYL"}</Typography>
                                  </Box>
                                  <Box>
                                    <Typography fontWeight={'600'} fontSize={"0.85vw"}>{Before?.eye_type === "RE" ? Before?.CYL ? Before?.CYL : "-" : "-"}</Typography>
                                  </Box>
                                </Box>
                                <Box component={'div'} className="eMed_Header_Request_item" style={{ width: "100%", height: "2vw", paddingLeft: "2vw", paddingRight: "3vw" }}>
                                  <Box>
                                    <Typography fontWeight={'600'} style={{ paddingLeft: 0.3 }} fontSize={"0.85vw"}>{"AXIS"}</Typography>
                                  </Box>
                                  <Box>
                                    <Typography fontWeight={'600'} fontSize={"0.85vw"}>{Before?.eye_type === "RE" ? Before?.AXIS ? Before?.AXIS : "-" : "-"}</Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </div> : null)) : <div style={{ marginTop: "2vw", paddingLeft: "8vw" }}>{"-"}</div>}
                          {item?.auto_refractometry.before_dilation?.length > 0 ? item?.auto_refractometry?.before_dilation.map((Before, BeforeIndex) => (
                            Before?.eye_type === "LE" ? <div key={BeforeIndex}>
                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingTop: "1vw", paddingBottom: "1vw" }}>
                                <Box component={'div'} style={{ marginLeft: "0.3vw" }}>
                                  <Typography fontSize={'1vw'} fontWeight={600}>{"Before Dilation"}</Typography>
                                </Box>
                              </div>
                              <Box className="eMed_App_reqest_table_head" sx={{ width: "100%", paddingLeft: "2vw", paddingRight: "3vw" }}>
                                <Typography fontWeight={'600'} style={{ paddingLeft: 0.3 }} fontSize={"0.8vw"}>Dilation</Typography>
                                <Typography fontWeight={'600'} style={{ paddingRight: 0.3 }} fontSize={"0.8vw"}>Left</Typography>
                              </Box>
                              <Box>
                                <Box component={'div'} className="eMed_Header_Request_item" style={{ width: "100%", height: "2vw", paddingLeft: "2vw", paddingRight: "3vw" }}>
                                  <Box>
                                    <Typography fontWeight={'600'} style={{ paddingLeft: 0.3 }} fontSize={"0.85vw"}>{"SPH"}</Typography>
                                  </Box>
                                  <Box>
                                    <Typography fontWeight={'600'} style={{ paddingRight: 0.3 }} fontSize={"0.85vw"}>{Before?.eye_type === "LE" ? Before?.SPH ? Before?.SPH : "-" : "-"}</Typography>
                                  </Box>
                                </Box>
                                <Box component={'div'} className="eMed_Header_Request_item" style={{ width: "100%", height: "2vw", paddingLeft: "2vw", paddingRight: "3vw" }}>
                                  <Box>
                                    <Typography fontWeight={'600'} style={{ paddingLeft: 0.3 }} fontSize={"0.85vw"}>{"CYL"}</Typography>
                                  </Box>
                                  <Box>
                                    <Typography fontWeight={'600'} style={{ paddingRight: 0.3 }} fontSize={"0.85vw"}>{Before?.eye_type === "LE" ? Before?.CYL ? Before?.CYL : "-" : "-"}</Typography>
                                  </Box>
                                </Box>
                                <Box component={'div'} className="eMed_Header_Request_item" style={{ width: "100%", height: "2vw", paddingLeft: "2vw", paddingRight: "3vw" }}>
                                  <Box>
                                    <Typography fontWeight={'600'} style={{ paddingLeft: 0.3 }} fontSize={"0.85vw"}>{"AXIS"}</Typography>
                                  </Box>
                                  <Box>
                                    <Typography fontWeight={'600'} style={{ paddingRight: 0.3 }} fontSize={"0.85vw"}>{Before?.eye_type === "LE" ? Before?.AXIS ? Before?.AXIS : "-" : "-"}</Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </div> : null)) : <div style={{ marginTop: "2vw", paddingLeft: "8vw" }}>{"-"}</div>}
                          {item?.auto_refractometry.before_dilation?.length > 0 ? item?.auto_refractometry?.before_dilation.map((After, AfterIndex) => (
                            After?.eye_type === "RE" ? <div key={AfterIndex}>
                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingTop: "1vw", paddingBottom: "1vw" }}>
                                <Box component={'div'} display={'flex'} style={{ marginLeft: "0.3vw" }}>
                                  <Typography fontSize={'1vw'} fontWeight={600}>{"After Dilation"}</Typography>
                                </Box>
                              </div>
                              <Box className="eMed_App_reqest_table_head" sx={{ width: "100%", paddingLeft: "2vw", paddingRight: "3vw" }}>
                                <Typography fontWeight={'600'} style={{ paddingLeft: 0.3 }} fontSize={"0.8vw"}>Dilation</Typography>
                                <Typography fontWeight={'600'} fontSize={"0.8vw"}>Right</Typography>
                              </Box>
                              <Box>
                                <Box component={'div'} className="eMed_Header_Request_item" style={{ width: "100%", height: "2vw", paddingLeft: "2vw", paddingRight: "3vw" }}>
                                  <Box>
                                    <Typography fontWeight={'600'} style={{ paddingLeft: 0.3 }} fontSize={"0.85vw"}>{"SPH"}</Typography>
                                  </Box>
                                  <Box>
                                    <Typography fontWeight={'600'} fontSize={"0.85vw"}>{After?.eye_type === "RE" ? After?.SPH ? After?.SPH : "-" : "-"}</Typography>
                                  </Box>
                                </Box>
                                <Box component={'div'} className="eMed_Header_Request_item" style={{ width: "100%", height: "2vw", paddingLeft: "2vw", paddingRight: "3vw" }}>
                                  <Box>
                                    <Typography fontWeight={'600'} style={{ paddingLeft: 0.3 }} fontSize={"0.85vw"}>{"CYL"}</Typography>
                                  </Box>
                                  <Box>
                                    <Typography fontWeight={'600'} fontSize={"0.85vw"}>{After?.eye_type === "RE" ? After?.CYL ? After?.CYL : "-" : "-"}</Typography>
                                  </Box>
                                </Box>
                                <Box component={'div'} className="eMed_Header_Request_item" style={{ width: "100%", height: "2vw", paddingLeft: "2vw", paddingRight: "3vw" }}>
                                  <Box>
                                    <Typography fontWeight={'600'} style={{ paddingLeft: 0.3 }} fontSize={"0.85vw"}>{"AXIS"}</Typography>
                                  </Box>
                                  <Box>
                                    <Typography fontWeight={'600'} fontSize={"0.85vw"}>{After?.eye_type === "RE" ? After?.AXIS ? After?.AXIS : "-" : "-"}</Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </div> : null)) : <div style={{ marginTop: "2vw", paddingLeft: "8vw" }}>{"-"}</div>}
                          {item?.auto_refractometry.before_dilation?.length > 0 ? item?.auto_refractometry?.before_dilation.map((After, AfterIndex) => (
                            After?.eye_type === "LE" ? <div key={AfterIndex}>
                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingTop: "1vw", paddingBottom: "1vw" }}>
                                <Box component={'div'} display={'flex'} style={{ marginLeft: "0.3vw" }}>
                                  <Typography fontSize={'1vw'} fontWeight={600}>{"After Dilation"}</Typography>
                                </Box>
                              </div>
                              <Box className="eMed_App_reqest_table_head" sx={{ width: "100%", paddingLeft: "2vw", paddingRight: "3vw" }}>
                                <Typography fontWeight={'600'} style={{ paddingLeft: 0.3 }} fontSize={"0.8vw"}>Dilation</Typography>
                                <Typography fontWeight={'600'} style={{ paddingRight: 0.3 }} fontSize={"0.8vw"}>Left</Typography>
                              </Box>
                              <Box>
                                <Box component={'div'} className="eMed_Header_Request_item" style={{ width: "100%", height: "2vw", paddingLeft: "2vw", paddingRight: "3vw" }}>
                                  <Box>
                                    <Typography fontWeight={'600'} style={{ paddingLeft: 0.3 }} fontSize={"0.85vw"}>{"SPH"}</Typography>
                                  </Box>
                                  <Box>
                                    <Typography fontWeight={'600'} style={{ paddingRight: 0.3 }} fontSize={"0.85vw"}>{After?.eye_type === "LE" ? After?.SPH ? After?.SPH : "-" : "-"}</Typography>
                                  </Box>
                                </Box>
                                <Box component={'div'} className="eMed_Header_Request_item" style={{ width: "100%", height: "2vw", paddingLeft: "2vw", paddingRight: "3vw" }}>
                                  <Box>
                                    <Typography fontWeight={'600'} style={{ paddingLeft: 0.3 }} fontSize={"0.85vw"}>{"CYL"}</Typography>
                                  </Box>
                                  <Box>
                                    <Typography fontWeight={'600'} style={{ paddingRight: 0.3 }} fontSize={"0.85vw"}>{After?.eye_type === "LE" ? After?.CYL ? After?.CYL : "-" : "-"}</Typography>
                                  </Box>
                                </Box>
                                <Box component={'div'} className="eMed_Header_Request_item" style={{ width: "100%", height: "2vw", paddingLeft: "2vw", paddingRight: "3vw" }}>
                                  <Box>
                                    <Typography fontWeight={'600'} style={{ paddingLeft: 0.3 }} fontSize={"0.85vw"}>{"AXIS"}</Typography>
                                  </Box>
                                  <Box>
                                    <Typography fontWeight={'600'} style={{ paddingRight: 0.3 }} fontSize={"0.85vw"}>{After?.eye_type === "LE" ? After?.AXIS ? After?.AXIS : "-" : "-"}</Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </div> : null)) : <div style={{ marginTop: "2vw", paddingLeft: "8vw" }}>{"-"}</div>}
                        </div>
                      </div>
                    </Box>
                    <Box component={'div'} flex={2} borderRight={'1px solid lightgray'}>
                      <Box component={'div'} p={'0.5vw'} pl={"2vw"} position={'sticky'}>
                        <Typography color={Colors.SecondaryText} pt={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>Diagnosis</Typography>
                      </Box>
                      <div style={{ height: "40vh", overflow: "scroll" }}>
                        {item?.assessment?.length > 0 ? item?.assessment.map((diag, diagIndex) => (
                          <div key={diagIndex} style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", paddingLeft: "2vw" }}>
                            <div style={{ marginTop: "0.5vw" }}>
                              <div style={{ fontSize: "0.8vw" }}>ICD Code</div>
                              <Typography fontSize={'0.9vw'} fontWeight={600}>{diag?.icd_code ? diag?.icd_code : "-"}</Typography>
                            </div>
                            <div style={{ marginTop: "0.5vw" }}>
                              <div style={{ fontSize: "0.8vw" }}>Short Description</div>
                              <div>{diag?.short_desc ?
                                diag?.short_desc?.length > 25 ?
                                  <Tooltip placement='top' title={diag?.short_desc}>
                                    <Typography fontSize={'0.9vw'} fontWeight={600} mr={'0.5vw'}>{diag?.short_desc?.slice(0, 25) + '...'}</Typography>
                                  </Tooltip> :
                                  <Typography fontSize={'0.9vw'} fontWeight={600} mr={'0.5vw'}>{diag?.short_desc}</Typography> : '-'
                              }</div>
                            </div>
                            <div style={{ marginTop: "0.5vw" }}>
                              <div style={{ fontSize: "0.8vw" }}>Long Description</div>
                              <div>{diag?.desc ?
                                diag?.desc?.length > 25 ?
                                  <Tooltip placement='top' title={diag?.desc}>
                                    <Typography fontSize={'0.9vw'} fontWeight={600} mr={'0.5vw'}>{diag?.desc?.slice(0, 25) + '...'}</Typography>
                                  </Tooltip> :
                                  <Typography fontSize={'0.9vw'} fontWeight={600} mr={'0.5vw'}>{diag?.desc}</Typography> : '-'
                              }</div>
                            </div>
                          </div>
                        )) : <div style={{ marginTop: "2vw", paddingLeft: "8vw" }}>{"-"}</div>}
                        <div style={{ marginTop: "0.5vw" }}>
                          <Typography color={Colors.SecondaryText} pt={'0.5vw'} pl={"2vw"} fontSize={'0.9vw'} fontWeight={600}>Provisional Diagnosis</Typography>
                          {item?.advice?.length > 0 ? item?.advice.map((provis, provisIndex) => (
                            <div key={provisIndex} style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", paddingLeft: "2vw" }}>
                              <div style={{ marginTop: "0.5vw" }}>
                                <div>{provis?.provisional_diagnosis ?
                                  provis?.provisional_diagnosis?.length > 25 ?
                                    <Tooltip placement='top' title={provis?.provisional_diagnosis}>
                                      <Typography fontSize={'0.9vw'} fontWeight={600} mr={'0.5vw'}>{provis?.provisional_diagnosis?.slice(0, 25) + '...'}</Typography>
                                    </Tooltip> :
                                    <Typography fontSize={'0.9vw'} fontWeight={600} mr={'0.5vw'}>{provis?.provisional_diagnosis}</Typography> : '-'
                                }</div>
                              </div>
                            </div>
                          )) : <div style={{ marginTop: "2vw", paddingLeft: "8vw" }}>{"-"}</div>}
                        </div>
                      </div>
                    </Box>
                    <Box component={'div'} flex={2} borderRight={'1px solid lightgray'}>
                      <Box component={'div'} p={'0.5vw'} pl={"2vw"} position={'sticky'}>
                        <Typography color={Colors.SecondaryText} pt={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>Glass Prescription</Typography>
                      </Box>
                      {item?.treatment_plan?.length > 0 ?
                        <div style={{ height: "40vh", overflow: "scroll" }}>
                          <div style={{ borderTop: "1px solid black", borderBottom: "1px solid black", height: "2vw", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                            <div>Eye</div>
                            <div>Left Eye</div>
                            <div>Right Eye</div>
                          </div>
                          <div>
                            {this.renderGP("SPH", (item?.glass_prescription[0]?.eye_type == "LE" ? item?.glass_prescription[0]?.DV_SPH : item?.glass_prescription[1]?.eye_type == "LE" ? item?.glass_prescription[1]?.DV_SPH : "-"), (item?.glass_prescription[0]?.eye_type == "RE" ? item?.glass_prescription[0]?.DV_SPH : item?.glass_prescription[1]?.eye_type == "RE" ? item?.glass_prescription[1]?.DV_SPH : "-"))}
                            {this.renderGP("CYL", (item?.glass_prescription[0]?.eye_type == "LE" ? item?.glass_prescription[0]?.DV_CYL : item?.glass_prescription[1]?.eye_type == "LE" ? item?.glass_prescription[1]?.DV_CYL : "-"), (item?.glass_prescription[0]?.eye_type == "RE" ? item?.glass_prescription[0]?.DV_CYL : item?.glass_prescription[1]?.eye_type == "RE" ? item?.glass_prescription[1]?.DV_CYL : "-"))}
                            {this.renderGP("AXIS", (item?.glass_prescription[0]?.eye_type == "LE" ? item?.glass_prescription[0]?.DV_AXIS : item?.glass_prescription[1]?.eye_type == "LE" ? item?.glass_prescription[1]?.DV_AXIS : "-"), (item?.glass_prescription[0]?.eye_type == "RE" ? item?.glass_prescription[0]?.DV_AXIS : item?.glass_prescription[1]?.eye_type == "RE" ? item?.glass_prescription[1]?.DV_AXIS : "-"))}
                            {this.renderGP("BASE", (item?.glass_prescription[0]?.eye_type == "LE" ? item?.glass_prescription[0]?.DV_BASE : item?.glass_prescription[1]?.eye_type == "LE" ? item?.glass_prescription[1]?.DV_BASE : "-"), (item?.glass_prescription[0]?.eye_type == "RE" ? item?.glass_prescription[0]?.DV_BASE : item?.glass_prescription[1]?.eye_type == "RE" ? item?.glass_prescription[1]?.DV_BASE : "-"))}
                            {this.renderGP("PRISM", (item?.glass_prescription[0]?.eye_type == "LE" ? item?.glass_prescription[0]?.DV_PRISM : item?.glass_prescription[1]?.eye_type == "LE" ? item?.glass_prescription[1]?.DV_PRISM : "-"), (item?.glass_prescription[0]?.eye_type == "RE" ? item?.glass_prescription[0]?.DV_PRISM : item?.glass_prescription[1]?.eye_type == "RE" ? item?.glass_prescription[1]?.DV_PRISM : "-"))}
                            {this.renderGP("VISION", (item?.glass_prescription[0]?.eye_type == "LE" ? item?.glass_prescription[0]?.DV_VISION : item?.glass_prescription[1]?.eye_type == "LE" ? item?.glass_prescription[1]?.DV_VISION : "-"), (item?.glass_prescription[0]?.eye_type == "RE" ? item?.glass_prescription[0]?.DV_VISION : item?.glass_prescription[1]?.eye_type == "RE" ? item?.glass_prescription[1]?.DV_VISION : "-"))}
                            {this.renderGP("BCDVA", (item?.glass_prescription[0]?.eye_type == "LE" ? item?.glass_prescription[0]?.DV_BCDVA : item?.glass_prescription[1]?.eye_type == "LE" ? item?.glass_prescription[1]?.DV_BCDVA : "-"), (item?.glass_prescription[0]?.eye_type == "RE" ? item?.glass_prescription[0]?.DV_BCDVA : item?.glass_prescription[1]?.eye_type == "RE" ? item?.glass_prescription[1]?.DV_BCDVA : "-"))}
                            {this.renderGP("BCNVA", (item?.glass_prescription[0]?.eye_type == "LE" ? item?.glass_prescription[0]?.DV_BCNVA : item?.glass_prescription[1]?.eye_type == "LE" ? item?.glass_prescription[1]?.DV_BCNVA : "-"), (item?.glass_prescription[0]?.eye_type == "RE" ? item?.glass_prescription[0]?.DV_BCNVA : item?.glass_prescription[1]?.eye_type == "RE" ? item?.glass_prescription[1]?.DV_BCNVA : "-"))}
                          </div>
                        </div> : <div style={{ marginTop: "2vw", paddingLeft: "8vw" }}>{"-"}</div>}
                    </Box>
                    <Box component={'div'} flex={2} borderRight={'1px solid lightgray'}>
                      <Box component={'div'} p={'0.5vw'} pl={"2vw"} position={'sticky'}>
                        <Typography color={Colors.SecondaryText} pt={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>Treatment Plan</Typography>
                      </Box>
                      {item?.treatment_plan?.length > 0 ? item?.treatment_plan.map((notes, notesIndex) => (
                        <div key={notesIndex} style={{ marginLeft: "2vw", marginTop: "1vw", height: "40vh", overflow: "scroll" }}>{notes?.comments ? notes?.comments : "-"}</div>
                      )) : <div style={{ marginTop: "2vw", paddingLeft: "8vw" }}>{"-"}</div>}
                    </Box>
                  </Box>
                  <Box component={'div'} display={'flex'} key={index} height={'50vh'} border={'1px solid lightgray'} sx={{ backgroundColor: Colors.white }}>
                    <Box component={'div'} flex={2} borderRight={'1px solid lightgray'}>
                      <Box component={'div'} p={'0.5vw'} pl={"2vw"} position={'sticky'}>
                        <Typography color={Colors.SecondaryText} pt={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>Anterior Segment</Typography>
                      </Box>
                      <div style={{ height: "40vh", overflow: "scroll" }}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                          <div style={{ marginTop: "1.5vw", display: "flex", flexDirection: "row", width: "100%" }}>
                            <div style={{ width: "34%", paddingLeft: "2vw" }}>{ }</div>
                            <div style={{ width: "33%", fontWeight: 600 }}>Right Eye</div>
                            <div style={{ width: "33%", fontWeight: 600 }}>Left Eye</div>
                          </div>
                          {item?.anterior_segment[0]?.lids || item?.anterior_segment[1]?.lids ?
                            <div style={{ marginTop: "1.5vw", display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
                              <div style={{ width: "34%", paddingLeft: "2vw", fontWeight: 600 }}>Lids</div>
                              <div style={{ width: "33%" }}>{item?.anterior_segment[0]?.lids && item?.anterior_segment[0]?.eye_type === "RE" ? item?.anterior_segment[0]?.lids : item?.anterior_segment[1]?.lids && item?.anterior_segment[1]?.eye_type === "RE" ? item?.anterior_segment[1]?.lids : null}</div>
                              <div style={{ width: "33%" }}>{item?.anterior_segment[0]?.lids && item?.anterior_segment[0]?.eye_type === "LE" ? item?.anterior_segment[0]?.lids : item?.anterior_segment[1]?.lids && item?.anterior_segment[1]?.eye_type === "LE" ? item?.anterior_segment[1]?.lids : null}</div>
                            </div>
                            : null}
                          {item?.anterior_segment[0]?.extra_ocular || item?.anterior_segment[1]?.extra_ocular ?
                            <div style={{ marginTop: "1.5vw", display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
                              <div style={{ width: "34%", paddingLeft: "2vw", fontWeight: 600 }}>Extra Ocular Movement</div>
                              <div style={{ width: "33%" }}>{item?.anterior_segment[0]?.extra_ocular && item?.anterior_segment[0]?.eye_type === "RE" ? item?.anterior_segment[0]?.extra_ocular : item?.anterior_segment[1]?.extra_ocular && item?.anterior_segment[1]?.eye_type === "RE" ? item?.anterior_segment[1]?.extra_ocular : null}</div>
                              <div style={{ width: "33%" }}>{item?.anterior_segment[0]?.extra_ocular && item?.anterior_segment[0]?.eye_type === "LE" ? item?.anterior_segment[0]?.extra_ocular : item?.anterior_segment[1]?.extra_ocular && item?.anterior_segment[1]?.eye_type === "LE" ? item?.anterior_segment[1]?.extra_ocular : null}</div>
                            </div>
                            : null}
                          {item?.anterior_segment[0]?.conjuctiva || item?.anterior_segment[1]?.conjuctiva ?
                            <div style={{ marginTop: "1.5vw", display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
                              <div style={{ width: "34%", paddingLeft: "2vw", fontWeight: 600 }}>Conjunctiva</div>
                              <div style={{ width: "33%" }}>{item?.anterior_segment[0]?.conjuctiva && item?.anterior_segment[0]?.eye_type === "RE" ? item?.anterior_segment[0]?.conjuctiva : item?.anterior_segment[1]?.conjuctiva && item?.anterior_segment[1]?.eye_type === "RE" ? item?.anterior_segment[1]?.conjuctiva : null}</div>
                              <div style={{ width: "33%" }}>{item?.anterior_segment[0]?.conjuctiva && item?.anterior_segment[0]?.eye_type === "LE" ? item?.anterior_segment[0]?.conjuctiva : item?.anterior_segment[1]?.conjuctiva && item?.anterior_segment[1]?.eye_type === "LE" ? item?.anterior_segment[1]?.conjuctiva : null}</div>
                            </div>
                            : null}
                          {item?.anterior_segment[0]?.sclera || item?.anterior_segment[1]?.sclera ?
                            <div style={{ marginTop: "1.5vw", display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
                              <div style={{ width: "34%", paddingLeft: "2vw", fontWeight: 600 }}>Sclera</div>
                              <div style={{ width: "33%" }}>{item?.anterior_segment[0]?.sclera && item?.anterior_segment[0]?.eye_type === "RE" ? item?.anterior_segment[0]?.sclera : item?.anterior_segment[1]?.sclera && item?.anterior_segment[1]?.eye_type === "RE" ? item?.anterior_segment[1]?.sclera : null}</div>
                              <div style={{ width: "33%" }}>{item?.anterior_segment[0]?.sclera && item?.anterior_segment[0]?.eye_type === "LE" ? item?.anterior_segment[0]?.sclera : item?.anterior_segment[1]?.sclera && item?.anterior_segment[1]?.eye_type === "LE" ? item?.anterior_segment[1]?.sclera : null}</div>
                            </div>
                            : null}
                          {item?.anterior_segment[0]?.cornea || item?.anterior_segment[1]?.cornea ?
                            <div style={{ marginTop: "1.5vw", display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
                              <div style={{ width: "34%", paddingLeft: "2vw", fontWeight: 600 }}>Cornea</div>
                              <div style={{ width: "33%" }}>{item?.anterior_segment[0]?.cornea && item?.anterior_segment[0]?.eye_type === "RE" ? item?.anterior_segment[0]?.cornea : item?.anterior_segment[1]?.cornea && item?.anterior_segment[1]?.eye_type === "RE" ? item?.anterior_segment[1]?.cornea : null}</div>
                              <div style={{ width: "33%" }}>{item?.anterior_segment[0]?.cornea && item?.anterior_segment[0]?.eye_type === "LE" ? item?.anterior_segment[0]?.cornea : item?.anterior_segment[1]?.cornea && item?.anterior_segment[1]?.eye_type === "LE" ? item?.anterior_segment[1]?.cornea : null}</div>
                            </div>
                            : null}
                          {item?.anterior_segment[0]?.anterior_chamber || item?.anterior_segment[1]?.anterior_chamber ?
                            <div style={{ marginTop: "1.5vw", display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
                              <div style={{ width: "34%", paddingLeft: "2vw", fontWeight: 600 }}>Anterior Chamber</div>
                              <div style={{ width: "33%" }}>{item?.anterior_segment[0]?.anterior_chamber && item?.anterior_segment[0]?.eye_type === "RE" ? item?.anterior_segment[0]?.anterior_chamber : item?.anterior_segment[1]?.anterior_chamber && item?.anterior_segment[1]?.eye_type === "RE" ? item?.anterior_segment[1]?.anterior_chamber : null}</div>
                              <div style={{ width: "33%" }}>{item?.anterior_segment[0]?.anterior_chamber && item?.anterior_segment[0]?.eye_type === "LE" ? item?.anterior_segment[0]?.anterior_chamber : item?.anterior_segment[1]?.anterior_chamber && item?.anterior_segment[1]?.eye_type === "LE" ? item?.anterior_segment[1]?.anterior_chamber : null}</div>
                            </div>
                            : null}
                          {item?.anterior_segment[0]?.pupil || item?.anterior_segment[1]?.pupil ?
                            <div style={{ marginTop: "1.5vw", display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
                              <div style={{ width: "34%", paddingLeft: "2vw", fontWeight: 600 }}>pupil</div>
                              <div style={{ width: "33%" }}>{item?.anterior_segment[0]?.pupil && item?.anterior_segment[0]?.eye_type === "RE" ? item?.anterior_segment[0]?.pupil : item?.anterior_segment[1]?.pupil && item?.anterior_segment[1]?.eye_type === "RE" ? item?.anterior_segment[1]?.pupil : null}</div>
                              <div style={{ width: "33%" }}>{item?.anterior_segment[0]?.pupil && item?.anterior_segment[0]?.eye_type === "LE" ? item?.anterior_segment[0]?.pupil : item?.anterior_segment[1]?.pupil && item?.anterior_segment[1]?.eye_type === "LE" ? item?.anterior_segment[1]?.pupil : null}</div>
                            </div>
                            : null}
                          {item?.anterior_segment[0]?.iris || item?.anterior_segment[1]?.iris ?
                            <div style={{ marginTop: "1.5vw", display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
                              <div style={{ width: "34%", paddingLeft: "2vw", fontWeight: 600 }}>Iris</div>
                              <div style={{ width: "33%" }}>{item?.anterior_segment[0]?.iris && item?.anterior_segment[0]?.eye_type === "RE" ? item?.anterior_segment[0]?.iris : item?.anterior_segment[1]?.iris && item?.anterior_segment[1]?.eye_type === "RE" ? item?.anterior_segment[1]?.iris : null}</div>
                              <div style={{ width: "33%" }}>{item?.anterior_segment[0]?.iris && item?.anterior_segment[0]?.eye_type === "LE" ? item?.anterior_segment[0]?.iris : item?.anterior_segment[1]?.iris && item?.anterior_segment[1]?.eye_type === "LE" ? item?.anterior_segment[1]?.iris : null}</div>
                            </div>
                            : null}
                          {item?.anterior_segment[0]?.lens || item?.anterior_segment[1]?.lens ?
                            <div style={{ marginTop: "1.5vw", display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
                              <div style={{ width: "34%", paddingLeft: "2vw", fontWeight: 600 }}>Lens</div>
                              <div style={{ width: "33%" }}>{item?.anterior_segment[0]?.lens && item?.anterior_segment[0]?.eye_type === "RE" ? item?.anterior_segment[0]?.lens : item?.anterior_segment[1]?.lens && item?.anterior_segment[1]?.eye_type === "RE" ? item?.anterior_segment[1]?.lens : null}</div>
                              <div style={{ width: "33%" }}>{item?.anterior_segment[0]?.lens && item?.anterior_segment[0]?.eye_type === "LE" ? item?.anterior_segment[0]?.lens : item?.anterior_segment[1]?.lens && item?.anterior_segment[1]?.eye_type === "LE" ? item?.anterior_segment[1]?.lens : null}</div>
                            </div>
                            : null}
                        </div>
                      </div>
                    </Box>
                    <Box component={'div'} flex={2} borderRight={'1px solid lightgray'}>
                      <Box component={'div'} p={'0.5vw'} pl={"2vw"} position={'sticky'}>
                        <Typography color={Colors.SecondaryText} pt={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>Posterior Segment</Typography>
                      </Box>
                      <div style={{ height: "40vh", overflow: "scroll" }}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                          <div style={{ marginTop: "1.5vw", display: "flex", flexDirection: "row", width: "100%" }}>
                            <div style={{ width: "34%", paddingLeft: "2vw" }}>{ }</div>
                            <div style={{ width: "33%", fontWeight: 600 }}>Right Eye</div>
                            <div style={{ width: "33%", fontWeight: 600 }}>Left Eye</div>
                          </div>
                          {item?.posterior_segment[0]?.disc || item?.posterior_segment[1]?.disc ?
                            <div style={{ marginTop: "1.5vw", display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
                              <div style={{ width: "34%", paddingLeft: "2vw", fontWeight: 600 }}>Disc</div>
                              <div style={{ width: "33%" }}>{item?.posterior_segment[0]?.disc && item?.posterior_segment[0]?.eye_type === "RE" ? item?.posterior_segment[0]?.disc : item?.posterior_segment[1]?.disc && item?.posterior_segment[1]?.eye_type === "RE" ? item?.posterior_segment[1]?.disc : null}</div>
                              <div style={{ width: "33%" }}>{item?.posterior_segment[0]?.disc && item?.posterior_segment[0]?.eye_type === "LE" ? item?.posterior_segment[0]?.disc : item?.posterior_segment[1]?.disc && item?.posterior_segment[1]?.eye_type === "LE" ? item?.posterior_segment[1]?.disc : null}</div>
                            </div>
                            : null}
                          {item?.posterior_segment[0]?.macula || item?.posterior_segment[1]?.macula ?
                            <div style={{ marginTop: "1.5vw", display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
                              <div style={{ width: "34%", paddingLeft: "2vw", fontWeight: 600 }}>Macula</div>
                              <div style={{ width: "33%" }}>{item?.posterior_segment[0]?.macula && item?.posterior_segment[0]?.eye_type === "RE" ? item?.posterior_segment[0]?.macula : item?.posterior_segment[1]?.macula && item?.posterior_segment[1]?.eye_type === "RE" ? item?.posterior_segment[1]?.macula : null}</div>
                              <div style={{ width: "33%" }}>{item?.posterior_segment[0]?.macula && item?.posterior_segment[0]?.eye_type === "LE" ? item?.posterior_segment[0]?.macula : item?.posterior_segment[1]?.macula && item?.posterior_segment[1]?.eye_type === "LE" ? item?.posterior_segment[1]?.macula : null}</div>
                            </div>
                            : null}
                          {item?.posterior_segment[0]?.media || item?.posterior_segment[1]?.media ?
                            <div style={{ marginTop: "1.5vw", display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
                              <div style={{ width: "34%", paddingLeft: "2vw", fontWeight: 600 }}>Media</div>
                              <div style={{ width: "33%" }}>{item?.posterior_segment[0]?.media && item?.posterior_segment[0]?.eye_type === "RE" ? item?.posterior_segment[0]?.media : item?.posterior_segment[1]?.media && item?.posterior_segment[1]?.eye_type === "RE" ? item?.posterior_segment[1]?.media : null}</div>
                              <div style={{ width: "33%" }}>{item?.posterior_segment[0]?.media && item?.posterior_segment[0]?.eye_type === "LE" ? item?.posterior_segment[0]?.media : item?.posterior_segment[1]?.media && item?.posterior_segment[1]?.eye_type === "LE" ? item?.posterior_segment[1]?.media : null}</div>
                            </div>
                            : null}
                          {item?.posterior_segment[0]?.vessels || item?.posterior_segment[1]?.vessels ?
                            <div style={{ marginTop: "1.5vw", display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
                              <div style={{ width: "34%", paddingLeft: "2vw", fontWeight: 600 }}>Vessels</div>
                              <div style={{ width: "33%" }}>{item?.posterior_segment[0]?.vessels && item?.posterior_segment[0]?.eye_type === "RE" ? item?.posterior_segment[0]?.vessels : item?.posterior_segment[1]?.vessels && item?.posterior_segment[1]?.eye_type === "RE" ? item?.posterior_segment[1]?.vessels : null}</div>
                              <div style={{ width: "33%" }}>{item?.posterior_segment[0]?.vessels && item?.posterior_segment[0]?.eye_type === "LE" ? item?.posterior_segment[0]?.vessels : item?.posterior_segment[1]?.vessels && item?.posterior_segment[1]?.eye_type === "LE" ? item?.posterior_segment[1]?.vessels : null}</div>
                            </div>
                            : null}
                          {item?.posterior_segment[0]?.periphery || item?.posterior_segment[1]?.periphery ?
                            <div style={{ marginTop: "1.5vw", display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
                              <div style={{ width: "34%", paddingLeft: "2vw", fontWeight: 600 }}>Periphery</div>
                              <div style={{ width: "33%" }}>{item?.posterior_segment[0]?.periphery && item?.posterior_segment[0]?.eye_type === "RE" ? item?.posterior_segment[0]?.periphery : item?.posterior_segment[1]?.periphery && item?.posterior_segment[1]?.eye_type === "RE" ? item?.posterior_segment[1]?.periphery : null}</div>
                              <div style={{ width: "33%" }}>{item?.posterior_segment[0]?.periphery && item?.posterior_segment[0]?.eye_type === "LE" ? item?.posterior_segment[0]?.periphery : item?.posterior_segment[1]?.periphery && item?.posterior_segment[1]?.eye_type === "LE" ? item?.posterior_segment[1]?.periphery : null}</div>
                            </div>
                            : null}
                        </div>
                      </div>
                    </Box>
                  </Box>
                  <Box component={'div'} display={'flex'} key={index} height={'50vh'} border={'1px solid lightgray'} sx={{ backgroundColor: Colors.white }}>
                    <Box component={'div'} flex={2} borderRight={'1px solid lightgray'}>
                      <Box component={'div'} p={'0.5vw'} pl={"2vw"} position={'sticky'}>
                        <Typography color={Colors.SecondaryText} pt={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>Medication</Typography>
                      </Box>
                      <div style={{ height: "40vh", overflow: "scroll" }}>
                        {item?.medication?.length > 0 ? item?.medication.map((medic, medicIndex) => (
                          <div key={medicIndex} style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%" }}>
                            <div style={{ marginTop: "0.5vw", marginLeft: "1vw", width: "10%" }}>
                              <div>Eye</div>
                              <Typography fontSize={'0.8vw'} fontWeight={600}>
                                {medic?.eye_type ? medic?.eye_type : "-"}
                              </Typography>
                            </div>
                            <div style={{ marginTop: "0.5vw", marginLeft: "1vw", width: "30%" }}>
                              <div>Drug Name</div>
                              <Typography fontSize={'0.8vw'} fontWeight={600}>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                  <div style={{ fontSize: "0.9vw" }}><Tooltip placement='top' title={medic?.brand_name}>
                                    <div>
                                      {medic?.brand_name.length > 15
                                        ? `${medic?.brand_name.slice(0, 15)}... `
                                        : medic?.brand_name}
                                    </div>
                                  </Tooltip></div>
                                  <Tooltip placement='top' title={`${medic?.generic_name} | ${medic?.dosage_type}`}>
                                    <div style={{ fontSize: "0.8vw" }}>
                                      {`${typeof medic.generic_name === 'object'
                                        ? JSON.stringify(medic.generic_name)
                                        : medic?.generic_name?.length > 15
                                          ? `${medic?.generic_name.slice(0, 15)}...`
                                          : medic?.generic_name} | ${medic?.dosage_type ? medic?.dosage_type : ''}`}
                                    </div>
                                  </Tooltip>
                                </div>
                              </Typography>
                            </div>
                            <div style={{ marginTop: "0.5vw", marginLeft: "1vw", width: "30%" }}>
                              <div>Frequency</div>
                              <Typography fontSize={'0.8vw'} fontWeight={600}>
                                {medic?.tapper_meds.length > 0 ? (
                                  medic?.tapper_meds?.map((list, listIndex) => (
                                    <div key={listIndex} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                      <div style={{ fontSize: "0.85vw" }}>
                                        <Tooltip placement='top' title={list?.duration}>
                                          <div>
                                            {list?.duration.length > 15
                                              ? `${list?.duration.slice(0, 15)}... `
                                              : list?.duration}
                                          </div>
                                        </Tooltip></div>
                                      <Tooltip placement='top' title={`${list?.start_date ? (list?.start_date).split("-").reverse().join("-") : "-"} | ${list?.end_date ? (list?.end_date).split("-").reverse().join("-") : '-'}`}>
                                        <div style={{ fontSize: "0.8vw" }}>
                                          {`${list?.start_date ? (list?.start_date).split("-").reverse().join("-") : "-"} | ${list?.end_date ? `${((list?.end_date).split("-").reverse().join("-")).slice(0, 7)}..` : '-'}`}
                                        </div>
                                      </Tooltip>
                                    </div>
                                  ))) : <div style={{ fontSize: "0.85vw" }}> {medic?.frequency_desc.length > 15 ? `${medic?.frequency_desc.slice(0, 15)}... ` : medic?.frequency_desc} </div>}
                              </Typography>
                            </div>
                            <div style={{ marginTop: "0.5vw", marginLeft: "1vw", width: "30%" }}>
                              <div>Duration/Unit</div>
                              <Typography fontSize={'0.8vw'} fontWeight={600}>
                                {medic?.tapper_meds.length > 0 ? (
                                  medic?.tapper_meds?.map((list, listIndex) => (
                                    <div key={listIndex} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                      <div style={{ fontSize: "0.85vw" }}>
                                        <Tooltip placement='top' title={list?.no_of_dosage}>
                                          <div>
                                            {list?.no_of_dosage.length > 15
                                              ? `${list?.no_of_dosage.slice(0, 15)}... `
                                              : list?.no_of_dosage}
                                          </div>
                                        </Tooltip></div>
                                      <Tooltip placement='top' title={list?.frequency}>
                                        <div style={{ fontSize: "0.8vw" }}>
                                          {list?.frequency.length > 17
                                            ? `${list?.frequency.slice(0, 17)}... `
                                            : list?.frequency}
                                        </div>
                                      </Tooltip>
                                    </div>
                                  ))) : <div style={{ fontSize: "0.85vw" }}> {medic?.duration ? (medic?.duration.split(" ")[1] === "Until_the_Next_Visit") ? "Until the Next Visit" : medic?.duration : "-"} </div>}
                              </Typography>
                            </div>
                          </div>
                        )) : <div style={{ marginTop: "2vw", paddingLeft: "8vw" }}>{"-"}</div>}
                      </div>
                    </Box>
                    <Box component={'div'} flex={2} borderRight={'1px solid lightgray'}>
                      <Box component={'div'} p={'0.5vw'} pl={"2vw"} position={'sticky'}>
                        <Typography color={Colors.SecondaryText} pt={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>Notes</Typography>
                      </Box>
                      {item?.notes?.length > 0 ? item?.notes.map((notes, notesIndex) => (
                        <div key={notesIndex} style={{ marginLeft: "2vw", marginTop: "1vw", height: "40vh", overflow: "scroll" }}>{notes?.comments ? notes?.comments : "-"}</div>
                      )) : <div style={{ marginTop: "2vw", paddingLeft: "15vw" }}>{"-"}</div>}
                    </Box>
                  </Box>
                </div>
              ))}
              {this.state.OPTCollabData?.length === 0 ? <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: "10vh" }}><Typography>No Records Found</Typography></Box> : null}
            </Box>
            <Box component={'div'} flex={1} borderLeft={'1px solid lightgray'}>
              <Box component={'div'} display={'flex'} p={'0.5vw'} justifyContent={'space-around'} alignItems={'center'} borderBottom={'1px solid lightgray'} position={'sticky'}>
                <Typography color={Colors.SecondaryText} pt={'0.5vw'} fontSize={'0.9vw'} fontWeight={600}>Action & Status</Typography>
              </Box>

              {this.state.OPTCollabData?.length > 0 && this.state.OPTCollabData?.map((item, index) => (
                <Box component={'div'} display={'flex'} height={'150vh'} border={'1px solid lightgray'} sx={{ backgroundColor: Colors.white }}>
                  <Box component={'div'} flex={2}>
                    <div style={{ height: '100vh', display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                          <Tooltip title="Continue Notes" placement="top" arrow>
                            <div onClick={() => {
                              setCachevalue(getCachevalue('DoctorPatientData'), "CurrentDoctorPatientData")
                              setCachevalue(JSON.stringify(item), "DoctorPatientData")
                              history.push({ pathname: "/DoctorHome/DoctorNotes", state: { FromCollab: true, CollabData: item } })
                            }} style={{ backgroundColor: Colors.themeBackgroud, display: "flex", alignItems: "center", justifyContent: "center", height: "2vw", width: "2vw", borderRadius: "0.25vw", cursor: "pointer" }}>
                              <Box component={'img'} src={ImagePaths.Play.default} height={'1.7vw'} width={'1.7vw'} />
                            </div>
                          </Tooltip>
                          <Tooltip title="Addendum" placement="top" arrow>
                            <div onClick={() => { this.setState({ addendumPopup: item?.is_locked ? true : false }) }} style={{ marginLeft: "0.5vw", backgroundColor: item?.is_locked ? Colors.themeBackgroud : "#dddada", display: "flex", alignItems: "center", justifyContent: "center", height: "2vw", width: "2vw", borderRadius: "0.25vw", cursor: "pointer" }}>
                              <Box component={'img'} src={ImagePaths.Append.default} height={'1.5vw'} width={'1.5vw'} />
                            </div>
                          </Tooltip>
                        </div>
                        <div>
                          <Button
                            variant='contained'
                            size='small'
                            sx={{ mt: "0.65vw" }}
                            disabled={!item?.is_locked}
                            onClick={() => { this.setState({ historyPopup: true }, () => { this.getlogData(item?.appointment_id) }) }}>Logs</Button>
                        </div>
                      </div>
                    </div>
                  </Box>
                </Box>))}
            </Box>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    let attender = {
      lineOne: "Attender Name",
      lineTwo: `${this.state.patientDetails?.attender_name ? this.state.patientDetails?.attender_name :
        this.state.patientDetails?.caretaker_name ? this.state.patientDetails?.caretaker_name : "-"}`,
      lineFour: 'Contact Number',
      LineFive: `${this.state.patientDetails?.attender_mobile ? this.state.patientDetails?.attender_mobile :
        this.state.patientDetails?.caretaker_mobile ? this.state.patientDetails?.caretaker_mobile : "-"}`
    }
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'74vh'} p={'0.5vw'}>
        <Box component={'div'} display={'flex'} alignItems={'center'} justifyContent={"space-between"}>
          <Box component={'div'} display={'flex'}>
            <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
              <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} fromDoctor={true} />
            </Box>
            <Box>
              {/* <CommonPatientCard details={attender} showDetailed={true} /> */}
            </Box>
          </Box>
        </Box>
        <Box component={'div'}>
          {this.renderCommonFilter()}
        </Box>
        <Box component={'div'} display={'flex'} mt={'0.5vw'} height={'61.5vh'}>
          {this.state?.OPTTable ? this.renderOPTCollabTable() : this.renderTableData()}
        </Box>
        {this.addendumPopup()}
        {this.HistoryPopup()}
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}
