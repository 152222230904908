import React, { Component } from 'react';
import {
    Tabs, Tab, Divider, IconButton, Tooltip, Box, Typography
} from "@mui/material";
import './../../../Components/Common Components/CommonTabs';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { clearCachevalue, getCachevalue } from '../../../Utility/Services/CacheProviderService';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';


const sub_menu_access = [
    {
        "sub_menu": 'Laboratory Billing',
        "access_granted": true,
        "full_access": true,
        "access_type": "r-w-u-d",
    },
    {
        "sub_menu": 'Radiology Billing',
        "access_granted": true,
        "full_access": true,
        "access_type": "r-w-u-d",
    }]

export default class BillingHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            MainTabIndex: props.selectedTab ? props.selectedTab?.id : props.List[0]?.id,
            SubTabIndex: props.selectedTab ? (props.selectedTab?.subMenu.length > 0 ? props.selectedTab?.subMenu[0]?.id : 0) : 0,
            List: props.List ? props.List : [],
            SelectedItem: props.selectedTab ? props.selectedTab : props?.List[0],
            SelectedSubItem: props.selectedTab ? (props.selectedTab?.subMenu.length > 0 ? props.selectedTab?.subMenu[0] : null) : null,
            BackScreen: props.BackToHome ? props.BackToHome : null,
            isSubRendered: false
        }
    }
    componentWillReceiveProps(props) {
        this.setState({
            List: props.List,
            SelectedItem: props.selectedTab ? props.selectedTab : props.List[0],
            MainTabIndex: props.selectedTab ? props.selectedTab?.id : props.List[0]?.id,
            BackScreen: props.BackToHome ? props.BackToHome : null,
            SubTabIndex: props.selectedTab ? (props.selectedTab?.subMenu.length !== 0 ? props.selectedTab?.subMenu[0]?.id : 0) : 0,
            SelectedSubItem: props.selectedTab ? (props.selectedTab?.subMenu.length > 0 ? props.selectedTab?.subMenu[0] : null) : null
        })
    }
    handleChange = (event, newValue) => {
        this.setState({
            MainTabIndex: newValue,
            SelectedItem: this.state.List.filter((item) => item.id == newValue)[0]
        }, () => {
            let { history } = this.props
            history.push({ pathname: `${this.state.SelectedItem.path}` })
        })

    };
    handleChangesub = (event, newValue) => {
        this.setState({
            SubTabIndex: newValue,
            SelectedSubItem: this.state.SelectedItem.subMenu.filter((item) => item.id == newValue)[0]
        })
    };

    checkAccess = (data) => {
        try {
            let patientDatas = getCachevalue("patientData")
            let patients = JSON.parse(patientDatas)
            if (data.menuName === "Nurse Notes" || data.menuName === "Billing") {
                if (patients?.appointment_id) { return true } else { return false }
            }
            else {
                return true
            }
        }
        catch (e) {
            console.log(e?.message)
            return true
        }
    }

    SubTabcheckAccess = (data) => {
        try {
            if (data.Access?.viewAccess === false) {
                return false
            }
            else {
                return true
            }
        }
        catch (e) {
            return true
        }
    }
    
    renderMainComponent = (data, Type) =>{
        if(data.Access?.viewAccess === true){
            return data?.component
        }
        else if (data.Access?.viewAccess === false){
            return <Typography className='eMed_NoAccess_Text' sx={{paddingTop: Type === "Tab" ? "15.65vw": "13vw"}}>No Access For This Screen</Typography>
        }else{
            return data?.component
        }
    }

    render() {
        let { history } = this.props
        return (
            <div>
                <div className='eMed_tabWrapper'>
                    {
                        this.state.BackScreen === null ? null :
                            <div>
                                <Tooltip title="Back" placement='top' arrow>
                                    <IconButton onClick={() => {
                                        history.push({ pathname: `${this.state.BackScreen}` })
                                        if(this.state.BackScreen !== "/IPHome/Demographics/AdmissionHistory"){
                                            clearCachevalue("patientData");
                                            clearCachevalue("IppatientData");
                                            clearCachevalue("OP_CreateAppointment_PatientId");
                                            clearCachevalue("AppRequestPatientData");
                                        }
                                        clearCachevalue("withoutAppointment");
                                    }}>
                                        <ChevronLeftIcon color='primary' />
                                    </IconButton>
                                </Tooltip>
                            </div>
                    }
                    <div >
                        <Box sx={{ width: '100%' }}>
                            <Box >
                                <Tabs
                                    value={this.state.MainTabIndex}
                                    onChange={this.handleChange}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    sx={{ minHeight: '1vw', width: '100%' }}
                                >
                                    {this.state.List && this.state.List.length > 0 && this.state.List.map((data, index) => {
                                        if(this.checkAccess(data)){
                                            return (
                                                <Tab
                                                    sx={{ textTransform: 'capitalize', minHeight: '1vw', fontWeight: 600 }}
                                                    disabled={!(sub_menu_access.filter((item) => item.sub_menu == data.menuName).length > 0 ? sub_menu_access.filter((item) => item.sub_menu == data.menuName)[0].access_granted : true)}
                                                    key={data.id}
                                                    value={data.id}
                                                    label={data.menuName} />
                                            )}
                                    }
                                    )}
                                </Tabs>
                            </Box>
                        </Box>
                    </div>
                </div>

                <div>
                    {
                        Object.keys(this.state.SelectedItem).length !== 0 ?
                            this.state.SelectedItem.subMenu?.length === 0 ?
                                <div className='eMed_MainComponentDiv'>
                                    {this.renderMainComponent(this.state.SelectedItem, "Tab")}
                                </div>
                                :
                                <div>
                                    {
                                        this.state.SubTabIndex != 0 ?
                                            <div>
                                                <Tabs
                                                    value={this.state.SubTabIndex}
                                                    onChange={this.handleChangesub}
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                    sx={{ minHeight: '1vw' }}
                                                >
                                                    {
                                                        this.state.SelectedItem.subMenu.map((data, index) => {
                                                                return (
                                                                    <Tab  sx={{ textTransform: 'capitalize', minHeight: '1vw', fontWeight: 600 }} key={data.id} value={data.id} label={data.menuName} />
                                                                )

                                                        })
                                                    }
                                                </Tabs>
                                                <div className='eMed_SubComponentDiv'>
                                                {this.renderMainComponent(this.state.SelectedSubItem, "SubTab")}
                                                </div>
                                            </div> : null
                                    }
                                </div>
                            : null
                    }
                </div>
            </div>
        )
    }
}
