import { Box, TextField } from '@mui/material';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../../ClinicalNotes/DoctorNotes.css'
import { CommonEditButton } from '../../../../../../Components/Common Components/CommonComponents';
import RestAPIService from '../../../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../../../Utility/API/Serviceurls';
import { CheckAccessFunc } from '../../../../../../Components/CommonFunctions/CommonFunctions';
import ToastMsg from '../../../../../../Components/ToastMsg/ToastMsg';
class ProvDiagnosisLeft extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ProvDiagnosisText: "",
      withOutAppointment : this.props?.withOutAppointment,
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  componentDidMount() {
    // this.GetProvDiagnosisData()
  }
  componentDidUpdate(prevprops) {
    if (prevprops.GetAPiCall !== this.props.GetAPiCall && this.props.GetAPiCall) { this.GetProvDiagnosisData() }
    if (prevprops.IpPatientData !== this.props.IpPatientData) { this.setState({ IpPatientData: this.props.IpPatientData }, () => { this.GetProvDiagnosisData() }) }
    if (prevprops.withOutAppointment?.patient_id !== this.props.withOutAppointment?.patient_id) { this.setState({ withOutAppointment: this.props.withOutAppointment }, () => { this.GetProvDiagnosisData() }) }
  }

  GetProvDiagnosisData() {
    let admission_id =   this.state.IpPatientData?.id || this.state.withOutAppointment?.ip_admission_id;
    try {
      RestAPIService.getAll(Serviceurls.IP_DOCNOTES_PROVISIONAL_DIAG + "?ip_admission_id=" + admission_id).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              ProvDiagnosisText: response.data.data.notes
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  onEditHandler(data) {
    this.props.EditProvDiagnosisData(this.state.ProvDiagnosisText)
  }
  msgClose(){
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
    })
  }
  render() {
    const { t } = this.props
    return (
      <Box display={'flex'} flexDirection={'row'}>
        <TextField
          size='small'
          className='eMed_DocNotes_ProvDiag_TextField'
          multiline={true}
          rows={5}
          label={t("ProvDiagnosis")}
          value={this.state.ProvDiagnosisText}
          InputProps={{ readOnly: true }}
        />
        <Box marginTop={'0.3vw'}>
          <CommonEditButton disable={CheckAccessFunc("front_office", "In Patients","Clinical Notes", "Doctor Notes", "SubTab")?.editAccess ? this.state.withOutAppointment?.is_ip : true} size={"1.5vw"} onClick={this.onEditHandler.bind(this)} />
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}
export default withTranslation()(ProvDiagnosisLeft)