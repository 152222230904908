import React, { Component } from 'react';
import { Box, Button, Typography, Chip, CircularProgress, TextField, Stack } from '@mui/material';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import MUIRichTextEditor from 'mui-rte';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { getCachevalue, localGetItem } from '../../../../Utility/Services/CacheProviderService';
import { Colors } from '../../../../Styles/Colors';
import Loader from '../../../../Components/Loader';
import { withTranslation } from 'react-i18next';
import { AddNewListItemPop, CommonPatientCard, CommonPatientDetails, DeletePopup, CommonPopUp } from '../../../../Components/Common Components/CommonComponents';

const defaultTxt = {
    "blocks": [{
        "key": "1",
        "text": "Dear Doctor,\n\nThis is with reference To [patientName], [uhid] [age] has diagnosed with [eye_problem]. We noticed that He / She needs [surgery] surgery to be undergone. Kindly Him / Her evaluate regarding the same.\n\nRegards,\n[clinic_doctor]",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [],
        "data": {}
    }],
    "entityMap": {}
}

let currentDate = new Date();

let day = currentDate.getDate();
let month = currentDate.getMonth() + 1;
let year = currentDate.getFullYear();

let formattedDate = `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year}`;

class DocRefferalNotesCommon extends Component {
    constructor(props) {
        super(props)
        this.state = {
            referral_id: "",
            refferalText: JSON.stringify(defaultTxt),
            dynamicValues: {
                patientName: "",
                uhid: "",
                age: "",
                eye_problem: "",
                eye_type: "",
            },
            patientDetails: {},
            clinicId: '',
            appointmentId: '',
            patientId: '',
            doctorData: [],
            doctor_details: {
                id: "",
                doctor_name: "",
                qualifications: "",
                designation: "",
                clinic_name: "",
                city_name: "",
                country: "",
                mobile_number: "",
                email: ""
            },
            eye_problem_data: [],
            eye_problem: {},
            eye_type_data: ["Left Eye", "Right Eye", "Both Eyes"],
            eye_type: "",
            systemic_illness_data: [],
            systemic_illness: {},
            surgery_data: [],
            surgery: {},
            is_add_doctor: false,
            is_add_eye_pbrlm: false,
            is_add_illness: false,
            is_add_surgery: false,
            isDoctorLoad: false,
            isLoader: false,
            isSaveSuccess: false,
            DrName: "",
        }
    }

    componentDidMount() {
        let clinicId = getCachevalue('SelectedDoctorClinic')
        let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
        let ServiceType = localGetItem("loggedInUserInfo")
        let myUser = JSON.parse(ServiceType)
        let states = this.state
        states["dynamicValues"]["clinic_doctor"] = myUser?.doctor_name
        this.setState({
            clinicId: clinicId,
            appointmentId: appointmentId?.appointment_id,
            patientId: appointmentId?.patient_id,
            doctorId: appointmentId?.doctor_id,
            DrName: myUser?.doctor_name
        }, () => {
            this.getReferralNotesData()
            this.getDoctorListData()
            this.getPatientDetailData()
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    LoaderFunction = (key, flag) => {
        let states = this.state;
        states[key] = flag;
        this.setState({
            states
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    getPatientDetailData = () => {
        let states = this.state
        try {
            RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            patientDetails: response?.data?.data
                        }, () => {
                            states["dynamicValues"]["patientName"] = (response?.data?.data?.first_name) + (response?.data?.data?.last_name ? response?.data?.data?.last_name : "")
                            states["dynamicValues"]["uhid"] = response?.data?.data?.patient_account_number
                            states["dynamicValues"]["age"] = response?.data?.data?.patient_age_str
                            this.setState({
                                states
                            })
                        })

                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getReferralNotesData = () => {
        try {
            this.LoaderFunction("isLoader", true);
            RestAPIService.getAll(Serviceurls.DOC_OPT_REFERRAL_GET)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.LoaderFunction("isLoader", false);
                        this.assignDetails(response?.data?.referral_note_items)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction("isLoader", false);
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction("isLoader", false);
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getDoctorListData = () => {
        try {
            this.LoaderFunction("isDoctorLoad", true);
            RestAPIService.getAll(Serviceurls.DOC_REFERRAL_DOCTOR_OPT)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction("isDoctorLoad", false);
                        this.setState({
                            doctorData: response?.data?.doctor_profile?.length > 0 ? response.data?.doctor_profile : []
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction("isDoctorLoad", false);
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction("isDoctorLoad", false);
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    assignDetails = (referral_note_items) => {
        this.setState({
            eye_problem_data: referral_note_items?.eye_problem_data?.length > 0 ? referral_note_items?.eye_problem_data : [],
            systemic_illness_data: referral_note_items?.systemic_illness_data?.length > 0 ? referral_note_items?.systemic_illness_data : [],
            surgery_data: referral_note_items?.surgery_data?.length > 0 ? referral_note_items?.surgery_data : [],
        })
    }

    postReferralData = (name) => {
        let states = this.state;
        try {
            let data = {
                eye_problem_name: states.is_add_eye_pbrlm ? name : "",
                solution_name: "",
                surgery_name: states.is_add_surgery ? name : "",
                systemic_illness_name: states.is_add_illness ? name : ""
            }
            this.LoaderFunction(true);
            RestAPIService.create(data, Serviceurls.DOC_OPT_REFERRAL_GET)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.assignDetails(response?.data?.referral_note_items);
                        this.successMessage(response?.data?.message)
                        this.ClosePopUp();
                        this.LoaderFunction(false);
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false);
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false);
            this.errorMessage(error.message)
        }
    }

    postDoctorData = () => {
        let { doctor_details } = this.state;
        try {
            let isValid = this.CheckAllFields();
            let msg = "";
            if (doctor_details.mobile_number && doctor_details.mobile_number.length !== 10) {
                msg = "Mobile Number must be 10 Digits"
            } else if (doctor_details.email && !CommonValidation.emailValidation(doctor_details.email)) {
                msg = "Invalid Email ID"
            }
            if (isValid && msg === "") {
                this.LoaderFunction("isDoctorLoad", true);
                RestAPIService.create(doctor_details, Serviceurls.DOC_REFERRAL_DOCTOR_OPT)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.setState({
                                doctorData: response?.data?.doctor_profile?.length > 0 ? response.data?.doctor_profile : []
                            })
                            this.successMessage(response?.data?.message)
                            this.closeDoctorPopup();
                            this.LoaderFunction("isDoctorLoad", false);
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.LoaderFunction("isDoctorLoad", false);
                            this.errorMessage(error?.response?.data?.message)
                        } else {
                            this.LoaderFunction("isDoctorLoad", false);
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage(msg)
            }
        } catch (error) {
            this.LoaderFunction("isDoctorLoad", false);
            this.errorMessage(error.message)
        }
    }

    CheckAllRefferalFields = () => {
        let { doctor_details, eye_problem, surgery } = this.state;
        if (doctor_details.id !== "" && eye_problem?.eye_problem_id && surgery?.surgery_id) {
            return true;
        }
        return false;
    }

    handleReferralSave = () => {
        let { doctor_details, eye_problem, surgery, patientId, refferalText, dynamicValues } = this.state;
        // const { blocks } = JSON.parse(refferalText);
        let DocNotes = `Dear Doctor,\n\nThis is with reference To ${dynamicValues?.patientName}, ${dynamicValues?.uhid} ${dynamicValues?.age} has diagnosed with ${dynamicValues?.eye_problem}. We noticed that He / She needs ${dynamicValues?.surgery} surgery to be undergone. Kindly Him / Her evaluate regarding the same.\n\nRegards,\n${dynamicValues?.clinic_doctor}`
        try {
            let isValid = this.CheckAllRefferalFields();
            if (isValid) {
                let data = {
                    doctor_id: "",
                    doctor_notes: DocNotes,
                    eye_problem_id: eye_problem?.eye_problem_id,
                    patient_id: patientId,
                    referral_doctor_id: doctor_details?.id,
                    solution_id: null,
                    surgery_id: surgery?.surgery_id,
                }
                this.LoaderFunction("isLoader", true);
                RestAPIService.create(data, Serviceurls.DOC_OPT_REFERRAL_POST)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.setState({
                                isSaveSuccess: true,
                                referral_id: response.data.referral_data[response.data.referral_data.length - 1]?.referral_id,
                                refferalText: JSON.stringify(defaultTxt),
                            })
                            // this.handleReferralClr()
                            this.successMessage(response?.data?.message)
                            this.LoaderFunction("isLoader", false);
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.LoaderFunction("isLoader", false);
                            this.errorMessage(error?.response?.data?.message)
                        } else {
                            this.LoaderFunction("isLoader", false);
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage("Select All Fields")
            }
        } catch (error) {
            this.LoaderFunction("isDoctorLoad", false);
            this.errorMessage(error.message)
        }
    }

    handleReferralPrint = () => {
        try {
            RestAPIService.PrintPDF(`${Serviceurls.DOC_OPT_REFERRAL_POST}?referral_id=${this.state?.referral_id}&export_type=pdf`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    handleChipClk = (stateKey, item) => {
        let states = this.state;
        let { dynamicValues } = this.state;
        let value = "";
        if (stateKey === "eye_problem") {
            value = item?.eye_problem_name ? item?.eye_problem_name : ""
        } else if (stateKey === "systemic_illness") {
            value = item?.systemic_illness_name ? item?.systemic_illness_name : ""
        } else if (stateKey === "surgery") {
            value = item?.surgery_name ? item?.surgery_name : ""
        } else {
            value = item
        }
        dynamicValues[stateKey] = value
        this.updateEditorContent(dynamicValues);
        states[stateKey] = item;
        this.setState({ states })
    }

    updateEditorContent = (values) => {
        const { refferalText } = this.state;
        let DefaultTxt = JSON.parse(refferalText);
        const { blocks } = DefaultTxt;
        let newText = blocks[0].text;

        Object.keys(values).forEach((key) => {
            newText = newText.replace(`[${key}]`, (values[key] ? values[key] : `[${key}]`));
        });
        DefaultTxt.blocks[0].text = newText;
        this.setState({
            refferalText: JSON.stringify(DefaultTxt),
            dynamicValues: { ...values },
        });
    };


    handleAdd = (stateKey) => {
        let states = this.state;
        states[stateKey] = true;
        this.setState({ states })
    }

    ClosePopUp = () => {
        this.setState({
            is_add_doctor: false,
            is_add_eye_pbrlm: false,
            is_add_illness: false,
            is_add_surgery: false,
        })
    }

    handleReferralClr = () => {
        this.setState({
            eye_problem: {},
            eye_type: "",
            systemic_illness: {},
            surgery: {},
        })
        this.ClosePopUp();
    }

    renderChip = (title, List, stateKey, showKey, idKey, addKey) => {
        let states = this.state;
        return (
            <Box component={'div'} borderBottom={'1px solid lightgray'} sx={{ padding: "0.6vw", marginTop: "0.3vw", minHeight: stateKey === "eye_type" ? "" : "18vh" }}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText} pl={"0.5vw"}>{title}</Typography>
                <Box component={'div'} display={'flex'} flexWrap={'wrap'} marginY={"0.625vw"}>
                    {states.isDoctorLoad ? <CircularProgress size={'small'} color='primary' /> :
                        List?.length > 0 ? List?.map((item) => {
                            return (
                                <Chip
                                    className={`eMed_chip ${title === "Eye Type" ? (item === states[stateKey] ? 'eMed_Chip_OnClick' : "eMed_Chip_Border") : (states[stateKey][idKey] === item[idKey] ? 'eMed_Chip_OnClick' : "eMed_Chip_Border")}`}
                                    size="small"
                                    label={showKey ? item[showKey] : item}
                                    key={item[idKey]}
                                    variant={title === "Eye Type" ? (item === states[stateKey] ? 'contained' : "outlined") : (states[stateKey][idKey] === item[idKey] ? 'contained' : "outlined")}
                                    onClick={() => this.handleChipClk(stateKey, item)}
                                />
                            )
                        }) : <p style={{ margin: "0.4vw auto", fontSize: "0.85vw", color: Colors.SecondaryText }}>{"No data found..."}</p>
                    }
                </Box>
                {stateKey === "eye_type" ? null : <Button
                    disabled={states.isLoader}
                    variant='contained'
                    size={"small"}
                    id="eMed_recPayment_btn"
                    sx={{ marginTop: "0.625vw", marginLeft: "0.5vw" }}
                    onClick={() => this.handleAdd(addKey)}
                >{"Add"}</Button>}
            </Box>
        )
    }

    handleChange = (e) => {
        let { name, value } = e.target;
        value = value.trimStart();
        let { doctor_details } = this.state;
        let isValid = false;

        switch (name) {
            case "doctor_name":
            case "city_name":
            case "country":
                isValid = CommonValidation.alphabetOnly(value);
                if (value === "" || isValid && value) {
                    doctor_details[name] = value;
                }
                break;
            case "mobile_number":
                isValid = CommonValidation.numberOnly(value)
                if (value === "" || isValid && value.length <= 10) {
                    doctor_details["mobile_number"] = value;
                }
                break;
            default:
                doctor_details[name] = value;
                break;
        }
        this.setState({ doctor_details })
    }

    renderTextbox = (label, stateKey, mandatory = false) => {
        let { t } = this.props;
        let { doctor_details } = this.state;
        return (
            <TextField
                label={t(label)}
                required={mandatory}
                sx={{ width: "16vw", margin: "0.6vw" }}
                size="small"
                autoComplete="off"
                name={stateKey}
                value={doctor_details[stateKey] ? doctor_details[stateKey] : ""}
                onChange={this.handleChange}
            />)
    }

    closeDoctorPopup = () => {
        this.setState({
            is_add_doctor: false,
            doctor_details: {
                id: "",
                doctor_name: "",
                qualifications: "",
                designation: "",
                clinic_name: "",
                city_name: "",
                country: "",
                mobile_number: "",
                email: ""
            }
        })
    }

    CheckAllFields = () => {
        let { doctor_name, qualifications, designation, clinic_name } = this.state.doctor_details;
        if (!doctor_name || !qualifications || !designation || !clinic_name) {
            return false
        }
        return true
    }

    renderDoctorPopUp = () => {
        return (
            <Box>
                <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
                    {this.renderTextbox("Doctor Name", "doctor_name", true)}
                    {this.renderTextbox("Qualification", "qualifications", true)}
                </Stack>
                <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
                    {this.renderTextbox("Designation", "designation", true)}
                    {this.renderTextbox("Hospital / clinic", "clinic_name", true)}
                </Stack>
                <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
                    {this.renderTextbox("City", "city_name")}
                    {this.renderTextbox("Country", "country")}
                </Stack>
                <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
                    {this.renderTextbox("Mobile Number", "mobile_number")}
                    {this.renderTextbox("Email", "email")}
                </Stack>
            </Box>
        )
    }

    render() {
        let { t } = this.props;
        let states = this.state;
        let attender = {
            lineOne: "Attender Name",
            lineTwo: `${this.state.patientDetails?.attender_name ? this.state.patientDetails?.attender_name :
                this.state.patientDetails?.caretaker_name ? this.state.patientDetails?.caretaker_name : "-"}`,
            lineFour: 'Contact Number',
            LineFive: `${this.state.patientDetails?.attender_mobile ? this.state.patientDetails?.attender_mobile :
                this.state.patientDetails?.caretaker_mobile ? this.state.patientDetails?.caretaker_mobile : "-"}`
        }
        return (
            <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'74vh'} p={'0.5vw'}>
                <Box component={'div'} display={'flex'}>
                    <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
                        <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} fromDoctor={true} />
                    </Box>
                    {/* <CommonPatientCard details={attender} showDetailed={true} /> */}
                </Box>
                <Box component={'div'} display={'flex'} mt={'0.5vw'} height={'61.5vh'}>
                    <Box component={'div'} flex={0.65} >
                        {/* <Box border={'1px solid lightgray'} height={"100%"}>
                            <MUIRichTextEditor
                                controls={["bold", "italic", "underline", "highlight", "undo", "redo"]}
                                inlineToolbar={true}
                                defaultValue={this.state.refferalText}
                            />
                        </Box> */}
                        <Box border={'1px solid lightgray'} height={"100%"} p={"1vw"}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between", height: "10vh" }}>
                                <div>
                                    <div>To</div>
                                    {this.state?.doctor_details?.id != "" ?
                                        <div style={{ marginLeft: "0.65vw" }}>
                                            <div style={{fontWeight: 'bold'}}>{`${states.doctor_details?.doctor_name} ${states.doctor_details?.qualifications}`}</div>
                                            <div>{`${states.doctor_details?.designation}`}</div>
                                            <div>{`${states.doctor_details?.clinic_name}`}</div>
                                            <div>{`${states.doctor_details?.city_name}`}</div>
                                        </div> : null
                                    }
                                </div>
                                <div>
                                    <div>{`${states.doctor_details?.city_name}`}</div>
                                    <div>{formattedDate}</div>
                                </div>
                            </div>
                            <div style={{ fontSize: "1.1vw", fontWeight: "600", textAlign: "center", padding: "1vw" }}>{`Greetings from ${this.state?.DrName}`}</div>
                            <div>{`Dear Dr ${states.doctor_details?.doctor_name},`}</div>
                            <div style={{ display: "flex", flexDirection: "row", marginTop: "0.75vw", marginLeft: "0.8vw" }}>
                                <div>{`This is with reference To ${states?.patientDetails?.first_name} ${states?.patientDetails?.last_name ? states?.patientDetails?.last_name : ""}, ${`(${states?.patientDetails?.patient_account_number})`} ${states?.patientDetails?.patient_age_str} has diagnosed with ${(Object.keys(states?.eye_problem).length > 0 ? states?.eye_problem?.eye_problem_name : "_____")} . We noticed that He / She needs ${Object.keys(states?.surgery).length > 0 ? states?.surgery?.surgery_name : "_____"} to be undergone. Kindly  Him / Her evaluate regarding the same.`}</div>
                            </div>
                            <div style={{ marginTop: "1.25vw" }}>
                                <div>Regards,</div>
                                <div>{`Dr ${this.state?.DrName}`}</div>
                            </div>
                        </Box>
                    </Box>
                    <Box component={'div'} flex={0.35} border={'1px solid lightgray'}>
                        <Box component={'div'} height={'5.15vh'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
                            <Typography fontWeight={600}>{"Referral Doctor"}</Typography>
                        </Box>
                        <Box sx={{ backgroundColor: Colors.white, height: "50.17vh", overflowY: "auto" }}>
                            {this.renderChip("Doctor", states.doctorData, "doctor_details", "doctor_name", "id", "is_add_doctor")}
                            {this.renderChip("Problem", states.eye_problem_data, "eye_problem", "eye_problem_name", "eye_problem_id", "is_add_eye_pbrlm")}
                            {this.renderChip("Surgery", states.surgery_data, "surgery", "surgery_name", "surgery_id", "is_add_surgery")}
                        </Box>
                        <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'6.18vh'}>
                            <Button
                                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                variant='outlined' disabled={!this.CheckAllRefferalFields()}
                                onClick={() => this.handleReferralSave()}
                            >{"Save"}</Button>
                            <Button
                                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                variant='contained' disabled={!this.state.isSaveSuccess}
                                onClick={() => this.handleReferralPrint()}
                            >{"Print"}</Button>
                        </Box>
                    </Box>
                </Box>

                {this.state.is_add_doctor ?
                    <CommonPopUp
                        width={"35vw"}
                        backgroundColor={Colors.white} justifyRight={true}
                        popUpClose={this.closeDoctorPopup} title={t("Add New Doctor")}
                        component={this.renderDoctorPopUp} buttonOne={t("Cancel")}
                        buttonOneAction={this.closeDoctorPopup} buttonTwo={t("Save")}
                        buttonTwoAction={this.postDoctorData} disableBtn2={!this.CheckAllFields()}
                    />
                    : null
                }

                {
                    this.state.is_add_eye_pbrlm ?
                        <AddNewListItemPop
                            title={"Problem"}
                            key={"is_add_eye_pbrlm"}
                            ClosePopUp={this.ClosePopUp.bind(this)}
                            sendNewData={this.postReferralData.bind(this)} />
                        : null
                }

                {
                    this.state.is_add_illness ?
                        <AddNewListItemPop
                            title={"Systemic Illness"}
                            key={"is_add_illness"}
                            ClosePopUp={this.ClosePopUp.bind(this)}
                            sendNewData={this.postReferralData.bind(this)} />
                        : null
                }
                {
                    this.state.is_add_surgery ?
                        <AddNewListItemPop
                            title={"Surgery"}
                            key={"is_add_surgery"}
                            ClosePopUp={this.ClosePopUp.bind(this)}
                            sendNewData={this.postReferralData.bind(this)} />
                        : null
                }

                {/* {this.state.deletePopup ? <DeletePopup
                    DeletTitle={`Are you sure you want to delete ?`}
                    deleteAlertPopupClose={this.popupClose.bind(this)}
                    removeData={this.detetePatientCategory.bind(this)}
                    disable={this.state.removeData}
                /> : null} */}

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}

                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}
export default withTranslation()(DocRefferalNotesCommon);
