import React, { Component } from "react";
import { Box, Button, Chip, Modal, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from '@mui/material'
import { Colors } from "../../../../Styles/Colors";
import ShowComponents from "./ShowComponent";
import ToastMsg from "../../../../Components/ToastMsg/ToastMsg";
import { DeletePopup } from "../../../../Components/Common Components/CommonComponents";
import { ImagePaths } from "../../../../Utility/ImagePaths";
import { Serviceurls } from "../../../../Utility/API/Serviceurls";
import RestAPIService from "../../../../Utility/Services/RestAPIService";
import CommonValidation from "../../../../Components/CommonFunctions/CommonValidation";
import { DEFAULT_DRUGS_IN_SYSTEMIC_ILLNESS } from "../../../../Utility/Constants";

export default class DocSystemicIllness extends Component {
    constructor(props) {
        super(props)
        this.state = {
            patientId: this.props.patientId,
            clinicId: this.props.clinicId,
            appointmentId: this.props.appointmentId,
            openPopUp: false,
            loading: false,
            deletePopup: false,
            removeData: false,
            isEdit: false,
            postLoading: false,
            isLocked: false,
            complainDescrp: "",
            Years: "",
            Months: "",
            updateId: "",
            AddNew: false,
            DrugList: [],
            SurgeryList: [],
            value: "One",
            Illness: [],
            Allergy: [],
            IllnessData: [],
            AllergyData: [],
            SurgeryYears: "",
            SurgeryMonths: "",
            disableIll: false,
            disableAllerg: false
        }
    }

    apiCalls = () => {
        this.getFavoriteDrugs()
        this.getAllergyFavoriteDrugs()
        this.getIllnessData()
        this.getDrugAllergyData()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLocked !== this.props.isLocked) {
            this.setState({ isLocked: this.props.isLocked })
        }
    }

    getFavoriteDrugs = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_OPT_FREQUENT_ILLNESS)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            DrugList: response.data.data
                        }, () => {
                            const newConditions = DEFAULT_DRUGS_IN_SYSTEMIC_ILLNESS
                            let newArray = [...this.state.DrugList, ...newConditions[0]]
                            let uniqueArray = newArray.filter((value, index, self) => {
                                return self.indexOf(value) === index;
                            });
                            this.setState({
                                DrugList: uniqueArray
                            });
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getAllergyFavoriteDrugs = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_OPT_FREQUENT_DRUGALLERGY)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            SurgeryList: response.data.data
                        }, () => {
                            let filteredArray = this.state.SurgeryList.filter(value => value !== null);
                            this.setState({ SurgeryList: filteredArray })
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getIllnessData = () => {
        try {
            this.setState({ loading: true })
            RestAPIService.getAll(Serviceurls.DOC_OPT_LIST_ILLNESS + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            IllnessData: response.data.data,
                            loading: false
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ loading: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ loading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    getDrugAllergyData = () => {
        try {
            this.setState({ loading: true })
            RestAPIService.getAll(Serviceurls.DOC_OPT_DRUG_ALLERGY + `?patient_id=${this.state.patientId}&appointment_id=${this.state.appointmentId}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            AllergyData: response.data.data.data,
                            loading: false
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ loading: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ loading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    checkValidation = () => {
        if (this.state.Illness != null && (this.state.Years != "" || this.state.Months != "")) {
            return null
        } else {
            if (this.state.Illness != null && this.state.Illness?.length === 0) {
                return "Please Choose Illness"
            } else {
                return "Please Enter Year or Month"
            }
        }
    }

    checkSurgeryValidation = () => {
        if (this.state.Allergy.length !== 0 && (this.state.SurgeryYears != "" || this.state.SurgeryMonths != "")) {
            return null
        } else {
            if (this.state.Allergy != null && this.state.Allergy?.length === 0) {
                return "Please Choose Allergy"
            } else {
                return "Please Enter Year or Month"
            }
        }
    }

    DeleteComplaintsData = () => {
        try {
            if (this.state.SelectedDeleteChip) {
                let SeriveURL = this.state.value === "One" ? `${Serviceurls.DOC_OPT_FREQUENT_ILLNESS}?illness_name=${this.state.SelectedDeleteChip}` : `${Serviceurls.DOC_OPT_FREQUENT_DRUGALLERGY}?name=${this.state.SelectedDeleteChip}`
                this.setState({ removeData: true })
                RestAPIService.delete(SeriveURL)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response?.data?.message)
                            this.state.value === "One" ? this.getFavoriteDrugs() : this.getAllergyFavoriteDrugs()
                            let filteredArray =this.state.value === "One" ? this.state.Illness.filter((item)=>(item !== this.state.SelectedDeleteChip)) : null
                            if( this.state.value === "Two" &&  this.state.Allergy === this.state.SelectedDeleteChip ){
                                this.setState ({Allergy : ""})
                            }
                            this.setState({ deletePopup: false, SelectedDeleteChip: null, removeData: false , Illness : filteredArray })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ removeData: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ removeData: false })
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                let data = {}
                let SeriveURL = this.state.selectedField == "Illness" ? Serviceurls.DOC_OPT_DELETE_ILLNESS : Serviceurls.DOC_OPT_DELETE_DRUGALLERGY
                this.setState({ removeData: true })
                RestAPIService.create(data, SeriveURL + `${this.state.updateId}/`)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response?.data?.message)
                            this.state.selectedField == "Illness" ? this.getIllnessData() : this.getDrugAllergyData()
                            this.setState({ deletePopup: false, removeData: false, updateId: "" })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ removeData: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ removeData: false })
                            this.errorMessage(error.message)
                        }
                    })
            }
        } catch (error) {
            this.setState({ removeData: false })
            this.errorMessage(error.message)
        }
    }

    addIllness = () => {
        let states = this.state
        try {
            let data = {
                "appointment_id": states.appointmentId,
                "patient_id": states.patientId,
                "year": states.Years || 0,
                "month": +states.Months || 0,
                "treatment": states.complainDescrp
            }
            var datas = []
            if (states.updateId) {
                datas.push(states.Illness)
                data["illness"] = datas;
                data["id"] = states.updateId;
            } else {
                for (var i = 0; i < states.Illness.length; i++) {
                    datas.push(states.Illness[i])
                }
                data["illness"] = datas;
            }
            let validate = this.checkValidation()
            if (validate === null) {
                this.setState({ postLoading: true })
                RestAPIService.create(data, Serviceurls.DOC_OPT_ILLNESS)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response?.data?.message)
                            this.onClearHandler()
                            this.getIllnessData()
                            this.setState({ openPopUp: false, postLoading: false, isEdit: false, updateId: "" })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ postLoading: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ postLoading: false })
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage(validate)
            }
        } catch (error) {
            this.setState({ postLoading: false })
            this.errorMessage(error.message)
        }
    }

    addDrugAllergy = () => {
        let states = this.state
        try {
            let data = {
                "name": states.Allergy,
                "no_of_years": states.SurgeryYears ? states.SurgeryYears : 0,
                "no_of_months": states.SurgeryMonths ? states.SurgeryMonths : 0,
                "no_of_days": 0,
                "comments": states.complainDescrp,
                "allergy": states.Allergy
            }
            if (states.updateId) {
                data["id"] = states.updateId;
            }
            data = {
                "allergies": [data],
                "appointment_id": states.appointmentId,
                "patient_id": states.patientId
            }
            let validate = this.checkSurgeryValidation()
            if (validate === null) {
                this.setState({ postLoading: true })
                RestAPIService.create(data, Serviceurls.DOC_OPT_DRUG_ALLERGY)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response?.data?.message)
                            this.onClearHandler()
                            this.getDrugAllergyData()
                            this.setState({ openPopUp: false, postLoading: false, isEdit: false, updateId: "" })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ postLoading: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ postLoading: false })
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage(validate)
            }
        } catch (error) {
            this.setState({ postLoading: false })
            this.errorMessage(error.message)
        }
    }

    renderChip = (values, key, Type) => {
        let states = this.state
        return (
            <div style={{ height: "6vw", overflow: "scroll" }}>
                <div style={{ display: "flex", flexWrap: "wrap", marginTop: "0.5vw" }}>
                    {
                        values && values.map((item, index) => (
                            <Chip
                                className={`eMed_chip ${key.includes(item) ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                                style={{ width: "8.5vw", display:'flex', justifyContent:'space-between' }}
                                label={item}
                                variant={key.includes(item) ? 'contained' : 'outlined'}
                                key={index}
                                onClick={() => {
                                    if (Type == "Allergy") {
                                        if (item === key) {
                                            states[Type] = ""
                                            this.setState({ states })
                                        } else {
                                            states[Type] = item
                                            this.setState({ states })
                                        }
                                    } else {
                                        const selectedIndex = states[Type].indexOf(item);
                                        let newSelected = [...states[Type]];
                                        if (selectedIndex === -1) {
                                            if (states.updateId) {
                                                newSelected = item
                                            } else {
                                                newSelected.push(item);
                                            }
                                        } else {
                                            newSelected = newSelected.filter((_, index) => index !== selectedIndex);
                                        }
                                        states[Type] = newSelected
                                        this.setState({ states });
                                    }
                                }}
                                onDelete={DEFAULT_DRUGS_IN_SYSTEMIC_ILLNESS[0].includes(item) ? null : () => { this.setState({ deletePopup: true, SelectedDeleteChip: item }) }}
                                clickable
                            />
                        ))
                    }
                </div>
            </div>
        )
    }

    onClearHandler = () => {
        this.setState({
            complainDescrp: "",
            Years: "",
            Months: "",
            AddNew: false,
            NewText: "",
            Illness: [],
            Allergy: [],
            SurgeryYears: "",
            SurgeryMonths: "",
            disableIll: false,
            disableAllerg: false
        })
    }

    renderTextField = (label) => {
        let states = this.state
        return (
            <div>
                <TextField
                    label={label == "SurgeryYears" ? "Years" : label == "SurgeryMonths" ? "Months" : label}
                    size="small"
                    style={{ marginLeft: "0.5vw", marginRight: label == "Months" ? "0.5vw" : 0 }}
                    variant="outlined"
                    value={states[label]}
                    onChange={(event) => {
                        let value = event.target.value
                        let isValid = CommonValidation.numberOnly(value)
                        if ((value === "" || isValid) && value.length < 5 && label == "Years") {
                            states[label] = value
                            this.setState({ states })
                        } else if ((value === "" || isValid) && value <= 11 && label == "Months") {
                            states[label] = value
                            this.setState({ states })
                        } else if (label == "SurgeryYears") {
                            if ((value === "" || isValid) && value.length < 5) {
                                states[label] = value
                                this.setState({ states })
                            }
                        } else if (label == "SurgeryMonths") {
                            if ((value === "" || isValid) && value.length < 5) {
                                states[label] = value
                                this.setState({ states })
                            }
                        }
                    }}
                />
            </div>
        )
    }

    renderPopUpScreen = () => {
        let states = this.state
        try {
            return (
                <Box>
                    <Modal open={this.state.openPopUp}>
                        <Box component={'div'} className='eMed_Doc_Notes_Pop_Up' style={{ width: "30vw", height: "35vw" }}>
                            <Box p={'1vw'} component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Add Systemic Illness</Typography>
                                <Button
                                    className='Common_Btn_Min_Width'
                                    onClick={() => this.setState({ openPopUp: false, isEdit: false, updateId: "" }, () => { this.onClearHandler() })}
                                >
                                    <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                                </Button>
                            </Box>

                            <Box sx={{ padding: "0.5vw" }}>
                                <Tabs
                                    value={this.state.value}
                                    onChange={(e, newValue) => this.setState({
                                        value: newValue
                                    }, () => { this.onClearHandler() })}
                                >
                                    <Tab value="One" disabled={this.state.disableIll} label={"Illness"} className='eMed_tab_header' />
                                    <Tab value="Two" disabled={this.state.disableAllerg} label={"Drug Allergy"} className='eMed_tab_header' />
                                </Tabs>

                                {
                                    this.state.value === "One" ?
                                        <div>
                                            {this.renderChip(this.state.DrugList, this.state.Illness, "Illness")}
                                            {this.state.AddNew ? null :
                                                <div style={{ height: '2.5vw', marginBottom: "0.65vw", marginTop: "-0.25vw", paddingRight: "1vw" }}>
                                                    <Button
                                                        sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                                        variant='contained'
                                                        onClick={() => { this.setState({ AddNew: true }) }}
                                                    >Add</Button>
                                                </div>}
                                            {this.state.AddNew ?
                                                <div style={{ display: "flex", flexDirection: "row", marginLeft: "0.5vw", height: "2.5vw", marginBottom: "0.65vw", marginTop: "-0.25vw" }}>
                                                    <TextField
                                                        label={(`Add Illness`)}
                                                        size="small"
                                                        style={{ marginTop: "0.5vw", width: "20vw" }}
                                                        variant="outlined"
                                                        value={states.NewText}
                                                        onChange={(event) => {
                                                            let value = event.target.value
                                                            this.setState({ NewText: value })
                                                        }}
                                                    />
                                                    <div style={{ marginLeft: "0.5vw", marginTop: "0.65vw", cursor: "pointer" }}
                                                        onClick={() => {
                                                            if (this.state.NewText) {
                                                                let isHaveItem = states.DrugList.find((item) => (item?.toLowerCase().trim() === this.state.NewText?.toLowerCase().trim()))
                                                                if (!isHaveItem) {
                                                                    states.DrugList.push(CommonValidation.removeSpace(this.state.NewText))
                                                                    states["Illness"] = [...this.state.Illness, this.state.NewText.trim()]
                                                                } else {
                                                                    states["Illness"] = [...this.state.Illness, isHaveItem]
                                                                }
                                                                this.setState({ states }, () => { this.setState({ AddNew: false, NewText: "" }) })
                                                            }
                                                        }}>
                                                        <img className="eMed_Tick_img" style={{ height: "2vw", width: "2vw" }} src={ImagePaths.Tick.default} alt={"Tick"} />
                                                    </div>
                                                    <div style={{ marginLeft: "0.5vw", marginTop: "0.65vw", cursor: "pointer" }}
                                                        onClick={() => {
                                                            this.setState({ AddNew: false, NewText: "" })
                                                        }}>
                                                        <img className="eMed_Tick_img" style={{ height: "2vw", width: "2vw" }} src={ImagePaths.CancelIcon.default} alt={"Close"} />
                                                    </div>
                                                </div> : null
                                            }
                                            <Box component={'div'} height={'2vw'} display={'flex'} ml={"0.5vw"} alignItems={'center'}>
                                                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Duration</Typography>
                                            </Box>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "0.65vw" }}>
                                                {this.renderTextField("Years")}
                                                {this.renderTextField("Months")}
                                            </div>
                                        </div> :
                                        <div>
                                            <div>
                                                {this.renderChip(this.state.SurgeryList, this.state.Allergy, "Allergy")}
                                                {this.state.AddNew ? null :
                                                    <div style={{ height: '2.5vw', marginBottom: "0.65vw", marginTop: "-0.25vw", paddingRight: "1vw" }}>
                                                        <Button
                                                            sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                                            variant='contained'
                                                            onClick={() => { this.setState({ AddNew: true }) }}
                                                        >Add</Button>
                                                    </div>}
                                                {this.state.AddNew ?
                                                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "0.5vw", height: "2.5vw", marginBottom: "0.65vw", marginTop: "-0.25vw" }}>
                                                        <TextField
                                                            label={(`Add Allergy`)}
                                                            size="small"
                                                            style={{ marginTop: "0.5vw", width: "20vw" }}
                                                            variant="outlined"
                                                            value={states.NewText}
                                                            onChange={(event) => {
                                                                let value = event.target.value
                                                                this.setState({ NewText: value })
                                                            }}
                                                        />
                                                        <div style={{ marginLeft: "0.5vw", marginTop: "0.65vw", cursor: "pointer" }}
                                                            onClick={() => {
                                                                if (this.state.NewText) {
                                                                    let isHaveItem = states.SurgeryList.find((item) => (item?.toLowerCase().trim() === this.state.NewText?.toLowerCase().trim()))
                                                                    if (!isHaveItem) {
                                                                        states.SurgeryList.push(CommonValidation.removeSpace(this.state.NewText))
                                                                        states["Allergy"] = this.state.NewText.trim()
                                                                    } else {
                                                                        states["Allergy"] = isHaveItem
                                                                    }
                                                                    this.setState({ states }, () => { this.setState({ AddNew: false, NewText: "" }) })
                                                                }
                                                            }}>
                                                            <img className="eMed_Tick_img" style={{ height: "2vw", width: "2vw" }} src={ImagePaths.Tick.default} alt={"Tick"} />
                                                        </div>
                                                        <div style={{ marginLeft: "0.5vw", marginTop: "0.65vw", cursor: "pointer" }}
                                                            onClick={() => {
                                                                this.setState({ AddNew: false, NewText: "" })
                                                            }}>
                                                            <img className="eMed_Tick_img" style={{ height: "2vw", width: "2vw" }} src={ImagePaths.CancelIcon.default} alt={"Close"} />
                                                        </div>
                                                    </div> : null
                                                }
                                                <Box component={'div'} height={'2vw'} display={'flex'} ml={"0.5vw"} alignItems={'center'}>
                                                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Year</Typography>
                                                </Box>
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "0.65vw" }}>
                                                    {this.renderTextField("SurgeryYears")}
                                                    {this.renderTextField("SurgeryMonths")}
                                                </div>
                                            </div>
                                        </div>
                                }
                            </Box>
                            <Box component={'div'} style={{ display: "flex", flexDirection: "column", paddingLeft: "1vw" }}>
                                <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'}>
                                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>{this.state.value === "One" ? "Treatment" : "Comments"}</Typography>
                                </Box>
                                <Box component={'div'} style={{ marginLeft: "0.5vw" }}>
                                    <TextField
                                        multiline
                                        rows={3}
                                        sx={{ width: '27vw', marginTop: "0.5vw" }}
                                        value={this.state.complainDescrp}
                                        onChange={(e) => {
                                            let value = e.target.value
                                            if (value?.length < 250) {
                                                this.setState({ complainDescrp: e.target.value })
                                            } else {
                                                this.errorMessage('Allowed only 250 characters')
                                            }
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'} mt={"0.5vw"}>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                    variant='outlined'
                                    onClick={() => this.onClearHandler()}
                                >Clear</Button>
                                <Button
                                    disabled={this.state.postLoading}
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                    variant='contained'
                                    onClick={() => { this.state.value == "One" ? this.addIllness() : this.addDrugAllergy() }}
                                >Add</Button>
                            </Box>
                        </Box>
                    </Modal>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    popupClose = () => {
        try {
            this.setState({
                deletePopup: false
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onEditHandler = (item) => {
        if (item?.illness_name) {
            this.setState({
                updateId: item?.id,
                complainDescrp: item?.treatment,
                Years: item?.year,
                Months: item?.month,
                Illness: item?.illness_name,
                openPopUp: true,
                disableAllerg: true,
                value: "One",
                DrugList : this.state.DrugList.includes(item?.illness_name) ? this.state.DrugList : [...this.state.DrugList , item?.illness_name]
            })
        } else {
            this.setState({
                updateId: item?.id,
                complainDescrp: item?.comments,
                SurgeryYears: item?.no_of_years ? item?.no_of_years : 0,
                SurgeryMonths: item?.no_of_months ? item?.no_of_months : 0,
                Allergy: item?.name,
                openPopUp: true,
                disableIll: true,
                value: "Two",
                SurgeryList : this.state.SurgeryList.includes(item?.name) ? this.state.SurgeryList : [...this.state.SurgeryList , item?.name]
            })
        }
    }

    onDeleteHandler = (item) => {
        try {
            this.setState({
                deletePopup: true,
                updateId: item?.id,
                selectedField: item?.illness_name ? "Illness" : "Allergy"
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                <Box component={'div'}>
                    <Box component={'div'} height={'3vw'} display={'flex'} p={'0.5vw'} justifyContent={'space-between'} alignItems={'center'} >
                        <Box component={'div'}>
                            <Typography fontWeight={600}>Systemic Illness</Typography>
                            {/* <Typography fontSize={'0.8vw'} color={'gray'}>Systemic Illness added By
                                <Typography component={'span'} fontWeight={600} fontSize={'0.8vw'} color={Colors.SecondaryText}> Hospital Nurse</Typography>
                            </Typography> */}
                        </Box>
                        <Box component={'div'}>
                            <Button
                                variant='contained'
                                sx={{ height: '2vw', width: '5vw', textTransform: "capitalize" }}
                                onClick={() => { this.setState({ openPopUp: true }) }}
                                disabled={this.state.isLocked || this.props.isLocked}
                            >Add</Button>
                        </Box>
                    </Box>
                    <Box component={'div'} sx={{ backgroundColor: Colors.white }} pt={'0.5vw'}>
                        <Box component={'div'} sx={{ border: '1px solid lightgray', }}>
                            <Table stickyHeader size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Illness</Typography>
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Comments</Typography>
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Action</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.state.IllnessData?.length > 0 ? this.state.IllnessData?.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell style={{ fontWeight: 600 }}>
                                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                        <div style={{ fontSize: "0.9vw" }}>{item?.illness_name}</div>
                                                        <div style={{ fontSize: "0.8vw" }}>{`${item?.year ? item.year : "0"}Y ${item?.month ? item?.month : "0"}M`}</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    {item?.treatment ? item?.treatment?.length > 15 ?
                                                        <Tooltip placement='top' title={item?.treatment}>
                                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.treatment?.slice(0, 15) + '...'}</Typography>
                                                        </Tooltip> :
                                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.treatment}</Typography> : '-'}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <Box component={'div'} display={'flex'} justifyContent={"flex-start"}>
                                                        <Button
                                                            className='Common_Btn_Min_Width'
                                                            onClick={() => { this.setState({ isEdit: true }, () => { this.onEditHandler(item) }) }}
                                                            disabled={this.state.isLocked || this.props.isLocked}
                                                        >
                                                            {this.state.isLocked || this.props.isLocked ?
                                                                <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                                                :
                                                                <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                                            }
                                                        </Button>
                                                        <Button
                                                            className='Common_Btn_Min_Width'
                                                            onClick={() => this.onDeleteHandler(item)}
                                                            disabled={this.state.isLocked || this.props.isLocked}
                                                        >
                                                            {this.state.isLocked || this.props.isLocked ?
                                                                <Box component={'img'} src={ImagePaths.DisabledDelete.default} height={'1.2vw'} width={'1.2vw'} />
                                                                :
                                                                <Box component={'img'} src={ImagePaths.DeleteOutline.default} height={'1.2vw'} width={'1.2vw'} />
                                                            }
                                                        </Button>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        )) : <TableRow>
                                            <TableCell />
                                            <TableCell>No Records To Be Shown</TableCell>
                                            <TableCell />
                                        </TableRow>}
                                </TableBody>
                            </Table>
                        </Box>
                        <Box component={'div'} sx={{ border: '1px solid lightgray', marginTop: "0.75vw" }}>
                            <Table stickyHeader size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Drug Allergy</Typography>
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Comments</Typography>
                                        </TableCell>
                                        <TableCell sx={{ backgroundColor: Colors.Background }}>
                                            <Typography fontWeight={600} fontSize={'0.9vw'} color={Colors.SecondaryText}>Action</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.state.AllergyData?.length > 0 ? this.state.AllergyData?.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell style={{ fontWeight: 600 }}>
                                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                        <div style={{ fontSize: "0.9vw" }}>{item?.name}</div>
                                                        <div style={{ fontSize: "0.8vw" }}>{`${item?.no_of_years ? item.no_of_years : "0"}Y ${item?.no_of_months ? item?.no_of_months : "0"}M`}</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    {item?.comments ? item?.comments?.length > 15 ?
                                                        <Tooltip placement='top' title={item?.comments}>
                                                            <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.comments?.slice(0, 15) + '...'}</Typography>
                                                        </Tooltip> :
                                                        <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.comments}</Typography> : '-'}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <Box component={'div'} display={'flex'} justifyContent={"flex-start"}>
                                                        <Button
                                                            className='Common_Btn_Min_Width'
                                                            onClick={() => { this.setState({ isEdit: true }, () => { this.onEditHandler(item) }) }}
                                                            disabled={this.state.isLocked || this.props.isLocked}
                                                        >
                                                            {this.state.isLocked || this.props.isLocked ?
                                                                <Box component={'img'} src={ImagePaths.DisabledEdit.default} height={'1.2vw'} width={'1.2vw'} />
                                                                :
                                                                <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1.2vw'} width={'1.2vw'} />
                                                            }
                                                        </Button>
                                                        <Button
                                                            className='Common_Btn_Min_Width'
                                                            onClick={() => this.onDeleteHandler(item)}
                                                            disabled={this.state.isLocked || this.props.isLocked}
                                                        >
                                                            {this.state.isLocked || this.props.isLocked ?
                                                                <Box component={'img'} src={ImagePaths.DisabledDelete.default} height={'1.2vw'} width={'1.2vw'} />
                                                                :
                                                                <Box component={'img'} src={ImagePaths.DeleteOutline.default} height={'1.2vw'} width={'1.2vw'} />
                                                            }
                                                        </Button>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        )) : <TableRow>
                                            <TableCell />
                                            <TableCell>No Records To Be Shown</TableCell>
                                            <TableCell />
                                        </TableRow>}
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                    {this.renderPopUpScreen()}
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.deletePopup ? <DeletePopup
                        DeletTitle={this.state.SelectedDeleteChip ? `Are you sure ? Do you want to remove ${this.state.SelectedDeleteChip} from suggestion ?` : `Are you sure you want to delete ?`}
                        deleteAlertPopupClose={this.popupClose.bind(this)}
                        removeData={this.DeleteComplaintsData.bind(this)}
                        disable={this.state.removeData}
                    /> : null}
                </Box>
            </ShowComponents>
        )
    }
}