import { Box, Button, Chip, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { Colors } from '../../../../Styles/Colors';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import AddIcon from '@mui/icons-material/Add';
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents';

class DocExaminationRight extends Component {
  constructor(props) {
    super(props)
    this.state = {
      examGroupData: [],
      defaultExamList: [],
      selectedExamId: null,
      selectedExamName: null,
      duplicateSymptomsDatas: '[]',
      duplicateDefaultExamList: '[]',
      NewGroupAdd: false,
      NewGroupName: "",
      NewExaminationAdd: false,
      NewExaminationName: '',
    }
  }

  componentDidMount() {
    this.getExamGroupData()
  }


  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  ClearData() {
    this.setState({
      defaultExamList: [],
      selectedExamId: null,
      selectedExamName: null,
      duplicateSymptomsDatas: '[]',
      NewGroupAdd: false,
      NewGroupName: "",
      NewExaminationAdd: false,
      NewExaminationName: '',
    })
  }

  getExamGroupData = (SelectLastItem = false) => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_EXAM_GROUP_GET)
        .then((response) => {
          if (response?.data?.status === 'Success' && response?.data?.data?.length > 0) {
            this.setState({
              examGroupData: response?.data?.data,
              selectedExamId: SelectLastItem ? response?.data?.data[response?.data?.data?.length - 1]?.id : null,
              selectedExamName: SelectLastItem ? response?.data?.data[response?.data?.data?.length - 1]?.name : null
            }, () => {
               this.getDefaultExamList(this.state.selectedExamId)
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getDefaultExamList = (groupId) => {
    try {
      this.setState({ sympLoad: true })
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_DEFAULT_EXAM_SYMPTOM)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({ sympLoad: false })
            if (groupId) {
              let selectedGroupSymptom = []
              response?.data?.data?.filter((item) => item?.group_id === groupId ? selectedGroupSymptom?.push(item) : [])
              this.setState({
                defaultExamList: selectedGroupSymptom,
                duplicateDefaultExamList: JSON.stringify(response?.data?.data)
              }, () => {
                this.state.defaultExamList?.forEach(element => {
                  element.is_checked = false
                  element.is_comment = false
                  element.comments = ''
                })
              })
            } else {
              this.setState({
                defaultExamList: [],
                duplicateDefaultExamList: JSON.stringify(response?.data?.data)
              }, () => {
                this.state.defaultExamList?.forEach(element => {
                  element.is_checked = false
                  element.is_comment = false
                  element.comments = ''
                })
              })
            }
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ sympLoad: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ sympLoad: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ sympLoad: false })
      this.errorMessage(error.message)
    }
  }


  GetSymptomsSuggestions() {
    try {
      RestAPIService.getAll(Serviceurls.IP_DOCNOTES_SYMPTOMS_SUGGEST).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              SymptomsList: response.data.data
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }
  GetSymptomsFrequents() {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_SYMPTOMNS_FREQ).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              FrequentData: response.data.data
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  deletedIds = () => {
    try {
      let states = this.state
      let removedIds = [];
      let duplicate = JSON.parse(this.state.duplicateSymptomsDatas)
      states.defaultExamList?.forEach(element => {
        if ((element.examination_id === duplicate?.examination_id) && (element?.is_checked !== duplicate?.is_checked)) {
          removedIds?.push(duplicate?.id)
        }
      })
      return removedIds;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postExaminationData = () => {
    try {
      let states = this.state
      let examinationData = []
      let removedIds = this.deletedIds()
      states.defaultExamList?.forEach(element => {
        if (element?.is_checked) {
          examinationData?.push({
            display_order: element?.display_order,
            examination_id: element?.examination_id,
            examination_name: element?.examination_name,
            group_id: element?.group_id,
            value: element?.value ? element?.value : ''
          })
        }
      })
      let data = {
        examination_data: examinationData,
        appointment_id: this.props.PatientData?.appointment_id,
        deleted_ids: removedIds
      }
      if (examinationData?.length > 0) {
        RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_EXAM_POST)
          .then((response) => {
            if (response?.data?.status === 'success') {
              this.successMessage(response.data.message)
              this.setState({
                selectedExamId: '',
                selectedExamName: '',
                isEdit: false
              })
              this.getExamGroupData()
              this.props.CallgetApi('examination')
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      } else {
        this.errorMessage("Please select at least one sign/symptom.")
      }
    } catch (error) {
      this.setState({ postLoad: false })
      this.errorMessage(error.message)
    }
  }

  popupClose() {
    this.setState({ isDeleteClicked: false })
  }
  DelectSymptomFreq() {
    try {
      var states = this.state
      RestAPIService.delete(Serviceurls.DOC_DOCTOR_NOTES_SYMPTOMNS_FREQ + "?symptom=" + states.SelectedFreq).
        then((response) => {
          if (response.data.status === "success") {
            this.state.FrequentData.splice(states.SelectedFreqIndex, 1)
            this.setState({ FrequentData: this.state.FrequentData, isDeleteClicked: false })
            if (this.state.SymptomsText === states.SelectedFreq) { this.setState({ SymptomsText: "" }) }
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  postExaminationGroupName = () => {
    try {
      let data = {
        name: this.state.NewGroupName
      }
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_EXAM_GROUP_GET)
        .then((response) => {
          if (response?.data?.status === 'Success') {
            this.successMessage(response.data.message)
            this.getExamGroupData(true)
            this.setState({ NewGroupName: false, NewGroupAdd: false })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ groupLoad: false })
      this.errorMessage(error.message)
    }
  }

  postNewExamination = () => {
    try {
      let data = {
        display_order: 1,
        examination_name: this.state.NewExaminationName,
        group_id: this.state.selectedExamId
      }
      this.setState({ examLoad: true })
      RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_ADD_NEW_EXAM_SYMPTOM)
        .then((response) => {
          if (response?.data?.status === 'Success') {
            this.successMessage(response.data.message)
            this.setState({ NewExaminationName: "", NewExaminationAdd: false, selectedSymptom: '' }, () => {
              this.getDefaultExamList(this.state.selectedExamId)
            })

          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ examLoad: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ examLoad: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ examLoad: false })
      this.errorMessage(error.message)
    }
  }

  render() {
    const { t } = this.props
    let states = this.state
    return (
      <Box>
        <Typography className='eMed_refdoc_title'>{t("Examination")}</Typography>
        <Box height={"56.3vh"} sx={{ overflowY: 'scroll' }}>
          <Typography className='eMed_docnotes_sub_title'>{t("Systematic Examination")}</Typography>
          <Box className="eMed_DocNotes_FreqChips_Wrapp">
            {
              this.state.examGroupData.length > 0 ? this.state.examGroupData.map((item, index) => (
                <Chip
                  size='small'
                  className={`eMed_chip ${this.state.selectedExamId === item?.id ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                  key={index}
                  label={item?.name}
                  variant={this.state.selectedExamId === item?.id ? 'contained' : "outlined"}
                  onClick={() => {
                    this.setState({
                      selectedExamId: this.state.selectedExamId === item?.id ? null : item?.id,
                      selectedExamName: this.state.selectedExamId === item?.id ? null : item?.name
                    }, () => {
                      if (this.state.selectedExamId) {
                        let selectedGroupSymptom = []
                        let duplicate = JSON.parse(this.state.duplicateDefaultExamList)
                        duplicate?.filter((item) => item?.group_id === this.state.selectedExamId ? selectedGroupSymptom?.push(item) : [])
                        this.setState({
                          defaultExamList: selectedGroupSymptom
                        })
                      } else {
                        this.setState({
                          defaultExamList: []
                        })
                      }
                    })
                  }}
                />
              )) : null
            }
            {
              this.state.NewGroupAdd ?
                <TextField
                  size='small'
                  value={this.state.NewGroupName}
                  onChange={(e) => {
                    this.setState({ NewGroupName: e.target.value })
                  }}
                  inputProps={{ maxLength: 100, style: { height: "0.8vw" } }}
                  sx={{ width: "26vw", marginLeft: "0.5vw", marginTop: '0.3vw' }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && this.state.NewGroupName !== "") {
                      this.postExaminationGroupName()
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={this.state.NewGroupName === ""}
                          onClick={() => {
                            this.postExaminationGroupName()
                          }}>
                          <AddIcon color={this.state.NewGroupName === "" ? 'grey' : 'primary'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }} /> :
                <IconButton
                  onClick={() => {
                    this.setState({
                      NewGroupAdd: true
                    })
                  }}>
                  <AddIcon color={'primary'} />
                </IconButton>
            }
          </Box>
          <Typography className='eMed_docnotes_sub_title'>{t("Signs / Symptoms")}</Typography>
          <Box className="eMed_DocNotes_FreqChips_Wrapp">
            {
              this.state.defaultExamList.length > 0 ? this.state.defaultExamList.map((item, index) => (
                <Chip
                  size='small'
                  className={`eMed_chip ${this.state.defaultExamList[index]['is_checked'] === true ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                  key={index}
                  label={item?.examination_name}
                  variant={this.state.defaultExamList[index]['is_checked'] === true ? 'contained' : "outlined"}
                  onClick={() => {
                    if (item?.group_id === this.state.selectedExamId) {
                      this.state.defaultExamList[index]['is_checked'] = !this.state.defaultExamList[index]['is_checked']
                      this.setState({
                        defaultExamList: this.state.defaultExamList,
                      })
                    }
                  }}
                />
              )) : null
            }
            {
              this.state.NewExaminationAdd ?
                <TextField
                  size='small'
                  value={this.state.NewExaminationName}
                  onChange={(e) => {
                    this.setState({ NewExaminationName: e.target.value })
                  }}
                  inputProps={{ maxLength: 100, style: { height: "0.8vw" } }}
                  sx={{ width: "26vw", marginLeft: "0.5vw", marginTop: '0.3vw' }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && this.state.NewExaminationName !== "") {
                      this.postNewExamination()
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={this.state.NewExaminationName === ""}
                          onClick={() => {
                            this.postNewExamination()
                          }}>
                          <AddIcon color={this.state.NewExaminationName === "" ? 'grey' : 'primary'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }} /> :
                <IconButton
                  disabled={this.state.selectedExamId === null}
                  onClick={() => {
                    this.setState({
                      NewExaminationAdd: true
                    })
                  }}>
                  <AddIcon color={this.state.selectedExamId === null ? 'disabled' : 'primary'} />
                </IconButton>
            }
          </Box>
        </Box>
        <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
          <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
          <Button variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.postExaminationData() }}>{t("Add")}</Button>
        </Stack>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.isDeleteClicked ? <DeletePopup
          DeletTitle={`${t("deleteMsg")}  ${t("Symptom")} ${this.state.SelectedFreq ? this.state.SelectedFreq : "-"} From Suggestion List ?`}
          deleteAlertPopupClose={this.popupClose.bind(this)}
          removeData={this.DelectSymptomFreq.bind(this)}
        /> : null}
      </Box>
    )
  }
}
export default withTranslation()(DocExaminationRight)
