import React, { Component } from 'react'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, Paper, Radio, RadioGroup, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation'

export default class DocNotesConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOPT: false,
            lockPeriod: '30',
            transmitSms: null,
            summarySms: null
        }
    }

    componentDidMount() {
        this.getDoctorNotesConfigList()
    }

    getDoctorNotesConfigList = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_OPT_CONFIG)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            lockPeriod: response.data.data.doctor_notes_lock_period,
                            transmitSms: response.data.data.late_transmit_summary_sms,
                            summarySms: response.data.data.append_summary_sms
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postDoctorConfigList = () => {
        try {
            let data = {
                doctor_notes_lock_period: +this.state.lockPeriod,
                late_transmit_summary_sms: Boolean(this.state.transmitSms),
                append_summary_sms: Boolean(this.state.summarySms)
            }
            RestAPIService.create(data, Serviceurls.DOC_OPT_CONFIG)
                .then((response) => {
                    if (response?.data.status === 'success') {
                        this.successMessage(response.data.message)
                        this.getDoctorNotesConfigList()
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    onChangeHandler = (e, names) => {
        try {
            let value = e.target.value
            switch (names) {
                case 'lockPeriod':
                    if ((CommonValidation.numberOnly(value) && +value <= 90) || value === '') {
                        this.setState({ lockPeriod: value })
                    }
                    break;
                default: break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    inputTextBox = (names, value, label, endAdornment, text, width) => {
        try {
            return (
                <TextField
                    size='small'
                    sx={{ width: width }}
                    label={label}
                    autoComplete='off'
                    InputProps={endAdornment ? {
                        endAdornment: <Box component={'div'}><Typography fontWeight={600}>{text}</Typography></Box>
                    } : null}
                    name={names}
                    value={value}
                    onChange={(e) => this.onChangeHandler(e, names)}
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onClearHandler = () => {
        try {
            this.setState({
                lockPeriod: '30',
                transmitSms: false,
                summarySms: false
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        return (
            <Box component={"div"} className='eMed_refdoc_container' style={{ backgroundColor: "white" }} p={'0.5vw'}>
                <Box component={'div'} border={'1px solid lightgray'} borderRadius={'10px'} height={'68vh'}>
                    <Box component={'div'} height={'3vw'} borderBottom={'1px solid lightgray'} display={'flex'} alignItems={'center'} pl={'0.5vw'}>
                        <Typography fontWeight={'600'}>Doctor Notes Configuration</Typography>
                    </Box>
                    <Box component={'div'} height={'68vh'} overflow={'auto'} p={'0.5vw'}>
                        <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{ padding: "0.5vw" }}>
                            <Box component={'div'}>
                                <Typography>Duration for Doctor Notes Lock Period ?</Typography>
                            </Box>
                            <Box component={'div'} display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
                                {this.inputTextBox('lockPeriod', this.state.lockPeriod, '', true, 'Days', '10vw')}
                                <Typography mt={"0.25vw"} fontSize={'0.8vw'} color={'gray'}>*3 days is the default lock period</Typography>
                            </Box>
                        </Box>

                        {/* <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{ paddingLeft: "0.5vw", marginBottom: "0.5vw" }}>
                            <Box component={'div'}>
                                <Typography>Late Transmit Summary SMS</Typography>
                            </Box>
                            <Box component={'div'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                <RadioGroup
                                    row
                                    value={this.state.transmitSms}
                                    onChange={(e) => {
                                        let value = e.target.value
                                        this.setState({ transmitSms: value })
                                    }}
                                >
                                    <FormControlLabel value={true} control={<Radio size='small' />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio size='small' />} label="No" />
                                </RadioGroup>
                            </Box>
                        </Box> */}

                        {/* <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{ paddingLeft: "0.5vw", marginTop: "0.5vw" }}>
                            <Box component={'div'}>
                                <Typography>Append Summary SMS</Typography>
                            </Box>
                            <Box component={'div'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                <RadioGroup
                                    value={this.state.summarySms}
                                    row
                                    onChange={(e) => {
                                        let value = e.target.value
                                        this.setState({ summarySms: value })
                                    }}
                                >
                                    <FormControlLabel value={true} control={<Radio size='small' />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio size='small' />} label="No" />
                                </RadioGroup>
                            </Box>
                        </Box> */}
                    </Box>
                </Box>
                <Box component={'div'} height={'3vw'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Button
                        sx={{ height: '2vw', mr: '1vw', textTransform: 'capitalize' }}
                        variant='outlined'
                        onClick={() => this.onClearHandler()}
                    >Cancel</Button>
                    <Button
                        sx={{ height: '2vw', mr: '1vw', textTransform: 'capitalize' }}
                        variant='contained'
                        onClick={() => this.postDoctorConfigList()}
                    >Save</Button>
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}