import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { Box, Grid, Stack, Paper, Typography, Button, FormControlLabel, Radio, TextField, InputAdornment, Checkbox, Tooltip, IconButton, Menu, MenuItem, RadioGroup } from '@mui/material';
import { Colors } from '../../../Styles/Colors';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { DataGrid } from '@mui/x-data-grid';
import CommonGridHeader from '../../../Components/Common Components/CommonComponents';
import { CommonEditButton } from '../../../Components/Common Components/CommonComponents';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { CommonPopUp } from '../../../Components/Common Components/CommonComponents';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';


class PrintConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      getTblData: [],
      is_a4Select: false,
      is_a5Select: false,
      is_Portrait: false,
      is_Landscape: false,
      wHeader: '',
      printHead: false,
      wFooter: '',
      printFooter: false,
      footSpceHeight: '',
      headSpceHeight: '',
      selectedID: null,
      isHistory: false,
      printName: '',
      pageWidth: '',
      pageHeight: '',
    }
  }

  componentDidMount() {
    this.getPrintList()
  }

  LoaderFunction = (key) => {
    this.setState({
        isLoader: key
    })
}

  getPrintList = () => {
    try {
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.PRINT_CONFIG)
        .then(response => {
          if (response.data.status === 'success') {
            this.setState({
              getTblData: response.data.data ? response.data.data : []
            },()=>{this.LoaderFunction(false)})
          }
        }).catch(e => {
          this.LoaderFunction(false)
          if (e?.response?.data?.status === 'fail') {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.LoaderFunction(false)
      this.errorMessage(e.message)
    }
  }

  getHistoryData = (id) => {
    try {
      RestAPIService.getAll(Serviceurls.PRINT_HISTORY + '?print_id=' + id)
        .then(response => {
          if (response.data.status === 'success') {
            this.setState({
              historyData: response.data.history ? response.data.history : [],
              isHistory: true,
            })
          }
        }).catch(e => {
          if (e?.response?.data?.status === 'fail') {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  postPrintConfig = () => {
    try {
      var states = this.state
      var data = {
        "print_id": states.selectedID,
        "print_size": states.is_a4Select ? 'A4' : states.is_a5Select ? 'A5' : '',
        "ortantation": this.state.printName === "Ip admission barcode" ? 'Landscape' : states.is_Portrait ? 'Portrait' : states.is_Landscape ? 'Landscape' : '',
        "is_header": states.wHeader === 'withHeader' ? true : false,
        "spacing_hight": states.headSpceHeight ? +states.headSpceHeight : 0,
        "header_all_page": states.printHead ? states.printHead : false,
        "is_footer": states.wFooter === 'withFooter' ? true : false,
        "footer_spacer_hight": states.footSpceHeight ? +states.footSpceHeight : 0,
        "footer_all_page": states.printFooter ? states.printFooter : false,
        "page_height": states.pageHeight ? states.pageHeight : 0,
        "page_width": states.pageWidth ? states.pageWidth : 0,
      }
      RestAPIService.updateWithOutId(data, Serviceurls.PRINT_CONFIG)
        .then(response => {
          if (response.data.status === 'success') {
            this.successMessage(response?.data?.message)
            this.getPrintList()
            this.clearPrintConfig()
          }
        }).catch(e => {
          if (e?.response?.data?.status === 'fail') {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }
  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  renderPrintLeft = () => {
    try {
      this.state.getTblData.forEach((element, index) => { element.sno = index + 1 })
      const { t } = this.props
      const columns = [
        { field: "sno", sortable: false, flex: 0.08, headerName: t("SNo") },
        {
          field: "report_type", flex: 0.22, headerName: t("SRT"),
          renderCell: (params) =>
            <Box component={'div'}>
              {params?.row?.report_type?.length > 16 ?
                <Tooltip title={params?.row?.report_type} placement='top' arrow>
                  <div>{params.row.report_type ? params.row.report_type.slice(0, 16) + '...' : '-'}</div>
                </Tooltip>
                : params?.row?.report_type ? params?.row?.report_type : '-'}
            </Box>
        },
        {
          field: "print_size", flex: 0.20, headerName: t("SizeO"),
          renderCell: (params) => (<Box component={'div'}>{(params?.row?.print_size ? params.row.print_size : "-") +
            " / " + (params?.row?.ortantation ? params.row.ortantation : "-")}</Box>)
        },
        {
          field: "modified_by", flex: 0.30, headerName: t("ModifiedBy"),
          renderCell: (params) =>
            <Box component={'div'}>
              {(params?.row?.modified_by + params?.row?.modified_dates).length > 25 ?
                <Tooltip title={params?.row?.modified_by + ' On ' + params?.row?.modified_dates} placement='top' arrow>
                  <div>{params.row.modified_by && params.row.modified_dates ? (params.row.modified_by + " On " + params.row.modified_dates).slice(0, 25) + '...' : '-'}</div>
                </Tooltip>
                : params?.row?.modified_by && params?.row?.modified_dates ? (params.row.modified_by + " On " + params.row.modified_dates) : '-'}
            </Box>
        },
        {
          field: "action", flex: 0.20, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false, hideable: false,
          renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <CommonEditButton disable={!CheckAccessFunc("front_office", "Settings", "Print Configuration", "Print Configuration", "SubTab")?.editAccess} size={"1.5vw"} onClick={this.onEditHandler.bind(this, params?.row)} />
            {this.MenuItem(params)}
          </Box>
        }
      ]

      return (
        <Stack component={"div"}>
          <Paper className='eMed_common_Tbl_left' >
            <DataGrid
              rows={this.state.getTblData}
              columns={columns}
              getRowId={(row) => row['sno']}
              hideFooterSelectedRowCount
              hideFooter
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              headerHeight={40}
              components={{
                Toolbar: CommonGridHeader.CommonToolBar,
                NoRowsOverlay: () => (
                  <Stack className='eMed_conf_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              disableSelectionOnClick
              loading={this.state.isLoader}
            />
          </Paper>
        </Stack>
      )
    } catch (e) {
      this.errorMessage(e.message)
    }
  }
  onEditHandler(item) {
    try {
      this.setState({
        selectedID: item.id,
        is_a4Select: item.print_size === 'A4' ? true : false,
        is_a5Select: item.print_size === 'A5' ? true : false,
        is_Portrait: item.ortantation === "Portrait" ? true : false,
        is_Landscape: item.ortantation === "Landscape" ? true : false,
        wHeader: item.is_header ? "withHeader" : "withoutHeader",
        wFooter: item.is_footer ? "withFooter" : "withoutFooter",
        printHead: item.header_all_page ? true : false,
        printFooter: item.footer_all_page ? true : false,
        headSpceHeight: item.header_spacer_hight ? item.header_spacer_hight : '',
        footSpceHeight: item.footer_spacer_hight ? item.footer_spacer_hight : '',
        printName: item.report_type,
        pageHeight: item.page_height ? item.page_height : '',
        pageWidth: item.page_width ? item.page_width : ''

      })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }
  MenuItem = (data) => {
    const { t } = this.props
    const multi_option = [
      { value: "History", label: t("History") },
    ]
    return (
      <div>
        <Tooltip title={t("More")} placement='top' arrow>
          <IconButton
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data.id,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow == data.id) ?
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
            <MenuItem onClick={() => { this.setState({ anchorEl: null }, () => { this.getHistoryData(data.row.id) }) }}>
              {t("History")}
            </MenuItem>
          </Menu>
          : null}
      </div>
    )
  }

  renderPrintRight = () => {
    try {
      const { t } = this.props
      var states = this.state
      return (
        <Stack component={"div"}>
          <Paper className='eMed_common_Tbl_right' elevation={2}>
            <Typography className='eMed_refdoc_title'>{t("BPC")}</Typography>
            <Box component={"div"} className='eMed_Print_Option_Con' overflow={'scroll'}>
              <Box component={"div"} className='eMed_Print_SCon'>
                <Typography className='eMed_Print_title'>{t('Size')}</Typography>
                <Box component={"div"} className='eMed_Pr_SizeImg'>
                  <Box component={"div"}>
                    <img className='eMed_Img_cursor' src={states.is_a4Select ? ImagePaths.A4Select.default : ImagePaths.A4Unselect.default}
                      alt='A4-Image' style={{ width: '7vw', height: '9vw' }}
                      onClick={() => {
                        this.setState({
                          is_a4Select: true,
                          is_a5Select: false,
                        })
                      }} />
                  </Box>
                  <Box component={"div"}>
                    <img className='eMed_Img_cursor' src={states.is_a5Select ? ImagePaths.A5Select.default : ImagePaths.A5Unselect.default}
                      alt='A4-Image' style={{ width: '6.5vw', height: '8vw' }}
                      onClick={() => {
                        this.setState({
                          is_a4Select: false,
                          is_a5Select: true,
                        })
                      }} />
                  </Box>
                </Box>
              </Box>
              <Box component={"div"} className='eMed_Print_SCon' marginTop={'1vw'}>
                <Typography className='eMed_Print_title'>{t('Orientation')}</Typography>
                <Box component={"div"} className='eMed_Pr_OriImg'>
                  <Box component={"div"}>
                    <img className='eMed_Img_cursor' src={states.is_Portrait ? ImagePaths.PortraitSelect.default : ImagePaths.PortraitUnselect.default}
                      alt='Portrait' style={{ width: '6.8vw', height: '8vw' }}
                      onClick={() => {
                        this.setState({
                          is_Portrait: true,
                          is_Landscape: false
                        })
                      }} />
                  </Box>
                  {this.state.printName === "Patient Prescription Sheet" ? null :
                    <Box component={"div"}>
                      <img className='eMed_Img_cursor' src={states.is_Landscape ? ImagePaths.LandscapeSelect.default : ImagePaths.LandscapeUnselect.default}
                        alt='Landscape' style={{ width: '8vw', height: '5.7vw' }}
                        onClick={() => {
                          this.setState({
                            is_Portrait: false,
                            is_Landscape: true
                          })
                        }} />
                    </Box>}
                </Box>
              </Box>
              {this.state.printName === "Ip admission barcode" ?
                <Box component={"div"} className='eMed_Print_FooCon'>
                  <Typography className='eMed_Print_title'>{t('Page')}</Typography>
                  <Box component={'div'} >
                    <Box component={"div"} sx={{ margin: '1vw', marginTop: '0vw'}}>
                      <Box component={"div"} sx={{ display: 'flex', flexDirection: 'row', marginLeft: '1vw', marginTop: '1vw', display: 'flex', width: '15vw', justifyContent: 'space-between' }}>
                        <Typography>{t('Height')}</Typography>
                        <TextField
                          id="cm"
                          variant="outlined"
                          value={states.pageHeight}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">cm</InputAdornment>, style: { height: '2vw', width: '9vw', marginLeft: '1vw' }
                          }}
                          onChange={(event) => {
                            var alphanum = CommonValidation.DecimalNumberOne(event.target.value)
                            if ((alphanum || event.target.value === '') && +event.target.value <= 10) {
                              this.setState({
                                pageHeight: event.target.value,
                              })
                            }
                          }} />
                      </Box>
                      <Box component={"div"} sx={{ display: 'flex', flexDirection: 'row', marginLeft: '1vw', marginTop: '1vw', display: 'flex', width: '15vw', justifyContent: 'space-between' }}>
                        <Typography>{t('Width')}</Typography>
                        <TextField
                          id="cm"
                          variant="outlined"
                          value={states.pageWidth}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">cm</InputAdornment>, style: { height: '2vw', width: '9vw', marginLeft: '1vw' }
                          }}
                          onChange={(event) => {
                            var alphanum = CommonValidation.DecimalNumberOne(event.target.value)
                            if ((alphanum || event.target.value === '') && +event.target.value <= 10) {
                              this.setState({
                                pageWidth: event.target.value,
                              })
                            }
                          }} />
                      </Box>
                    </Box>
                  </Box>
                </Box> :
                <Box>
                  <Box component={"div"} className='eMed_Print_FooCon'>
                    <Typography className='eMed_Print_title'>{t('Header')}</Typography>
                    <Box component={'div'}>
                      <Box component={"div"} sx={{ margin: '1vw', marginTop: '0vw' }}>
                        <FormControlLabel control={<Radio size='small'
                          value={"withoutHeader"}
                          checked={states.wHeader === "withoutHeader"}
                          onChange={(event) => {
                            this.setState({
                              wHeader: event.target.value,
                              printHead: false,
                            })
                          }} />} label={t('woHead')} />
                        <Box component={"div"} sx={{ display: 'flex', flexDirection: 'row', marginLeft: '2vw' }}>
                          <Typography>{t('SpaceHeight')}</Typography>
                          <TextField
                            id="cm"
                            variant="outlined"
                            disabled={states.wHeader === "withoutHeader" ? false : true}
                            value={states.headSpceHeight}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">cm</InputAdornment>, style: { height: '2vw', width: '9vw', marginLeft: '1vw' }
                            }}
                            onChange={(event) => {
                              var alphanum = CommonValidation.DecimalNumberOne(event.target.value)
                              if ((alphanum || event.target.value === '') && +event.target.value <= 10) {
                                this.setState({
                                  headSpceHeight: event.target.value,
                                })
                              }
                            }} />
                        </Box>
                      </Box>
                      <Box component={"div"} sx={{ marginLeft: '1vw' }}>
                        <FormControlLabel control={<Radio size='small'
                          value={"withHeader"}
                          checked={states.wHeader === "withHeader"}
                          onChange={(event) => {
                            this.setState({
                              wHeader: event.target.value,
                              printHead: true,
                              headSpceHeight: '',
                            })
                          }} />} label={t('wHead')} />
                      </Box>
                      <Box component={"div"} sx={{ marginLeft: '3vw', }}>
                        <FormControlLabel control={<Checkbox size='small' disabled={states.wHeader === 'withoutHeader' ? true : false} checked={states.printHead}
                          onChange={(e) => { this.setState({ printHead: e.target.checked }) }} />} label={t('PHAP')} />
                      </Box>
                    </Box>
                  </Box>
                  <Box component={"div"} className='eMed_Print_FooCon'>
                    <Typography className='eMed_Print_title'>{t('Footer')}</Typography>
                    <Box component={"div"} sx={{ margin: '1vw', marginBottom: '0vw' }}>
                      <FormControlLabel control={<Radio size='small'
                        value={"withoutFooter"}
                        checked={states.wFooter === "withoutFooter"}
                        onClick={(event) => {
                          this.setState({
                            wFooter: event.target.value,
                            printFooter: false,
                          })
                        }} />} label={t('woFoot')} />
                      <Box component={"div"} sx={{ display: 'flex', flexDirection: 'row', marginLeft: '2vw' }}>
                        <Typography>{t('SpaceHeight')}</Typography>
                        <TextField
                          id="cm"
                          variant="outlined"
                          disabled={states.wFooter === "withoutFooter" ? false : true}
                          value={states.footSpceHeight}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">cm</InputAdornment>, style: { height: '2vw', width: '9vw', marginLeft: '1vw' }
                          }}
                          onChange={(event) => {
                            var alphanum = CommonValidation.DecimalNumberOne(event.target.value)
                            if ((alphanum || event.target.value === '') && +event.target.value <= 5) {
                              this.setState({
                                footSpceHeight: event.target.value
                              })
                            }
                          }} />
                      </Box>
                    </Box>
                    <Box component={"div"} sx={{ marginLeft: '1vw' }}>
                      <FormControlLabel control={<Radio size='small'
                        value={"withFooter"}
                        checked={states.wFooter === "withFooter"}
                        onClick={(event) => {
                          this.setState({
                            wFooter: event.target.value,
                            printFooter: true,
                            footSpceHeight: '',
                          })
                        }} />} label={t('wFoot')} />
                    </Box>
                    <Box component={"div"} sx={{ marginLeft: '3vw' }}>
                      <FormControlLabel control={<Checkbox size='small' disabled={states.wFooter === 'withoutFooter' ? true : false} checked={states.printFooter}
                        onChange={(e) => { this.setState({ printFooter: e.target.checked }) }} />} label={t('PFAP')} />
                    </Box>
                  </Box>
                </Box>}
            </Box>
            <Stack className='eMed_DisSum_right_Btn' spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
              {/* <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.clearPrintConfig() }}>{t("Reset")}</Button> */}
              <Button variant='contained' size="small" id="eMed_Config_btn" disabled={CheckAccessFunc("front_office", "Settings", "Print Configuration", "Print Configuration", "SubTab")?.editAccess ? (states.selectedID === null ? true : false) : true} onClick={() => { this.savePrintConfig() }}>{t("Save")}</Button>
            </Stack>
          </Paper>
        </Stack>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  savePrintConfig = () => {
    var states = this.state
    if(states.printName === "Ip admission barcode" && (states.pageHeight > states.pageWidth)){
        this.errorMessage('Width Should be Greater than Height')
      }else{
        this.postPrintConfig()
      }
  }
  clearPrintConfig = () => {
    this.setState({
      is_a4Select: false,
      is_a5Select: false,
      is_Portrait: false,
      is_Landscape: false,
      wHeader: null,
      wFooter: null,
      printHead: false,
      printFooter: false,
      selectedID: null,
      headSpceHeight: '',
      footSpceHeight: '',
      pageHeight: '',
      pageWidth: '',
      printName: '',
    })
  }

  historyPopUpClose() {
    this.setState({
      isHistory: false,
      historyData: []
    })
  }
  _getHistory(historydata) {
    historydata.forEach((element, index) => { element.sno = index + 1 })
    const { t } = this.props;
    let { anchorEl2 } = this.state;
    let open = Boolean(anchorEl2);
    const historyColumn = [
      { field: "sno", sortable: false, width: 100, headerName: t("SNo") },
      {
        field: "modified_by_to", width: 200, headerName: t("UpdatedBy"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.modified_by_to?.length > 16 ?
            <Tooltip title={params.row.modified_by_to} placement='top' arrow>
              <div>{params.row.modified_by_to ? params.row.modified_by_to.slice(0, 16) + '...' : '-'}</div>
            </Tooltip> : params.row.modified_by_to ? params.row.modified_by_to : '-'}</Box>)
      },
      {
        field: "modified_date_to", width: 200, headerName: t("ModifiedDate"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.modified_date_to ? params?.row?.modified_date_to : "-"}</Box>)
      },
      {
        field: "print_size_from", width: 250, headerName: t("PreSize"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          {(params?.row?.print_size_from ? params?.row?.print_size_from : "-") + ' / ' + (params?.row?.ortantation_from ? params?.row?.ortantation_from : "-")}</Box>)
      },
      {
        field: "print_size_to", width: 250, headerName: t("PreOrien"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          {(params?.row?.print_size_to ? params?.row?.print_size_to : "-") + ' / ' + (params?.row?.ortantation_to ? params?.row?.ortantation_to : "-")}</Box>)
      },
      {
        field: "is_header_from", width: 260, headerName: t("PreHead"), headerAlign: "center", align: "center",
        renderCell: (params) => {
          let is_header_from = (params?.row?.is_header_from ? "With Header" : "With out Header") + ' / ' + (params?.row?.is_footer_from ? "With Footer" : "With out Footer");
          return (<Box component={'div'}>{is_header_from?.length > 20 ? <Tooltip placement="top" title={is_header_from} arrow><div>{is_header_from?.slice(0, 20) + "..."}</div></Tooltip> : is_header_from ? is_header_from : "-"}</Box>)
        }
      },
      {
        field: "is_header_to", width: 260, headerName: t("PreFoot"), headerAlign: "center", align: "center",
        renderCell: (params) => {
          let is_header_to = (params?.row?.is_header_to ? "With Header" : "With out Header") + ' / ' + (params?.row?.is_footer_to ? "With Footer" : "With out Footer");
          return (<Box component={'div'}>{is_header_to?.length > 20 ? <Tooltip placement="top" title={is_header_to} arrow><div>{is_header_to?.slice(0, 20) + "..."}</div></Tooltip> : is_header_to ? is_header_to : "-"}</Box>)
        }
      },
    ];
    return (
      <Box id="eMed_ipsrvConfig_accTbl">
        <DataGrid
          rows={historydata}
          columns={historyColumn}
          getRowId={(row) => row.sno}
          disableColumnMenu
          hideFooter
          components={{
            NoRowsOverlay: () => (
              <Stack className='eMed_conf_nodata'>
                {t("NoRecordsFound")}
              </Stack>
            )
          }}
          headerHeight={40}
          disableSelectionOnClick
        />
      </Box>
    )
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  render() {
    const { t } = this.props
    try {
      return (
        <Box component={'div'} className='eMed_usrconf_container'>
          <Grid container className='eMed_refdoc_content' >
            <Grid item xs={7.5}>
              {this.renderPrintLeft()}
            </Grid>
            <Grid item xs={4.5}>
              {this.renderPrintRight()}
            </Grid>
          </Grid>
          {this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {(this.state.isHistory) ?
            <CommonPopUp width={"75vw"}
              backgroundColor={Colors.white}
              popUpClose={this.historyPopUpClose.bind(this)}
              title={t("History")}
              component={this._getHistory.bind(this, this.state.historyData)} />
            : null}
        </Box>
      )
    } catch (e) {
      this.errorMessage(e.message)
    }

  }
}

export default withTranslation()(PrintConfig);