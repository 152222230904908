import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { Autocomplete, Button, Divider, FormControl, IconButton, InputLabel, MenuItem, Modal, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { Colors } from '../../Styles/Colors';
import { ImagePaths } from '../../Utility/ImagePaths';
import { CommonPatientDetails } from './CommonComponents';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import RestAPIService from '../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../Utility/API/Serviceurls';
import { localGetItem } from '../../Utility/Services/CacheProviderService';
import { DateTime } from 'luxon';
import CommonValidation from '../CommonFunctions/CommonValidation';
import moment from 'moment/moment';
import { formatDate } from '../CommonFunctions/CommonFunctions';
import { CurrencySymbol } from '../../Utility/Constants';

class PackageDeatailPop extends Component {
    constructor(props) {
        super(props);

        var timeString = this.props.PatientDetails?.surgery_time ? this.props.PatientDetails?.surgery_time : "00:00:00";
        var timeArray = timeString.split(':');
        var dateObj = new Date();
        dateObj.setHours(parseInt(timeArray[0], 10));
        dateObj.setMinutes(parseInt(timeArray[1], 10));
        dateObj.setSeconds(parseInt(timeArray[2], 10));

        this.state = {
            open: true,
            roomChangeDate: this.props?.OTHome ? new Date(this.props.PatientDetails?.surgery_date) : new Date(),
            roomChangeTime: this.props?.OTHome ? dateObj : new Date(),
            Comments: "",
            PatientDetails: this.props.PatientDetails,
            serviceTemplateData: [],
            SelectedPackageID: this.props.PatientDetails?.package_id,
            SelectedPackage: null,
            SelectedSurgeon: this.props.PatientDetails?.surgeon_name,
            SelectedAnaesthetist: this.props.PatientDetails?.anaesthetist,
            DoctorData: "",
            counsellorName: "",
            TotalAmount: 0,
            DiscountPercent: this.props.PatientDetails?.discount_percentage ? this.props.PatientDetails.discount_percentage : "",
            DiscountAmount: this.props.PatientDetails?.discount_amount ? this.props.PatientDetails.discount_amount : "",
            PackageAmount: 0,
            isDisable: false,
            surgeryNameData: [],
            surgeryName: this.props.PatientDetails?.surgery_name,
            PackageData: []
        }
        this.dropdownRef = React.createRef();
    }

    componentDidMount = () => {
        if (this.props.PatientDetails?.surgery_name && (this.props.PatientDetails?.package_id || (this.props.OTHome && this.props.PatientDetails?.package_name))) {
            this.getServiceTemplateData()
            this.GetPackageList()
        } else if (this.props.PatientDetails?.surgery_name) {
            this.GetPackageList()
        }
        this.getDoctorList()
        this.getSurgeryNameList()
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        if (myUser?.counsellor_name) {
            this.setState({
                counsellorName: myUser?.counsellor_name
            })
        }

        if ((this.props.PatientDetails.status === "Completed" || this.props.PatientDetails.status === "Draft" || this.props.PatientDetails.status === "Cancelled") && (!this.props.OTHome)) {
            this.setState({
                isDisable: this.props.PatientDetails.status === "Draft" ? false : true
            }, () => {
                this.TransmittedData()
            })
        } else if (this.props.OTHome) {
            this.setState({
                isDisable: true
            })
        }
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
            this.setState({ isDropdownOpen: false });
        }
    };

    getSurgeryNameList = () => {
        let states = this.state
        try {
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_COUNSELLING_SURGERY_SEARCH + `?clinic_id=${this.props.ClinicID}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        if (response?.data?.data?.length > 0) {
                            response?.data?.data.forEach((item) => {
                                if (item?.surgery_key != null) {
                                    states["surgeryNameData"].push(item)
                                }
                                this.setState({ states })
                            })
                        } else {
                            this.setState({
                                surgeryNameData: response?.data?.data ? response?.data?.data : []
                            })
                        }
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.props.ErrorMsg(error.response.data.message)
                    } else {
                        this.props.ErrorMsg(error.message)
                    }
                })
        } catch (error) {
            this.props.ErrorMsg(error.message)
        }
    }

    GetPackageList = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_COUNSELLING_SURGERY_SEARCH + `?surgery_key=${this.state.surgeryName}&clinic_id=${this.props.ClinicID}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            PackageData: response?.data?.data ? response?.data?.data : []
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.props.ErrorMsg(error.response.data.message)
                    } else {
                        this.props.ErrorMsg(error.message)
                    }
                })
        } catch (error) {
            this.props.ErrorMsg(error.message)
        }
    }

    PostCounsellorRetransmit = (status, isSelected) => {
        try {
            let states = this.state;
            this.setState({ isDisable: true })
            var dateOfSurgery = typeof (states.roomChangeDate) === 'string' ? states.roomChangeDate : DateTime.fromJSDate(states.roomChangeDate).toFormat('yyyy-MM-dd')
            var timeOfSurgery = typeof (states.roomChangeTime?._i) === 'string' ? states.roomChangeTime?._i : DateTime.fromJSDate(states.roomChangeTime).toFormat('HH:mm:ss')
            var surgon = (states.SelectedSurgeon?.first_name ? states.SelectedSurgeon.first_name : "")
            var anaesthetist = (states.SelectedAnaesthetist?.first_name ? states.SelectedAnaesthetist.first_name : "")
            if (dateOfSurgery != "Invalid DateTime" && timeOfSurgery != "Invalid DateTime" && (surgon != " " && surgon != null) && (anaesthetist != " " && anaesthetist != null) && (states?.surgeryName !== "") && (states?.SelectedPackage != null)) {
                var data = {
                    id: states.PatientDetails?.id,
                    doctor_id: states.PatientDetails?.doctor_id,
                    patient_id: states.PatientDetails?.patient_id,
                    appointment_id: states.PatientDetails?.appointment_id,
                    clinic_id: this.props.ClinicID,
                    surgery_id: this.props.PatientDetails?.surgery_id ? this.props.PatientDetails?.surgery_id : this.state.SelectedPackageID,
                    package_id: this.state.SelectedPackageID,
                    // package_id: this.props.PatientDetails?.package_id,
                    eye_type: states.PatientDetails?.eye_type,
                    surgeon_name: surgon,
                    anaesthetist: anaesthetist,
                    counsellor_name: states.counsellorName,
                    notes: this.props.CancelComments != undefined ? this.props.CancelComments : states.Comments,
                    discount_amount: +(states.DiscountAmount),
                    // gst_percentage: states.selectedGST,
                    // gst_type: states.selectedGSTtype,
                    net_amount: states.TotalAmount,
                    status: status,
                    discount_percentage: +(states.DiscountPercent),
                    surgery_date: dateOfSurgery,
                    iol_power: this.props.PatientDetails.iol_power,
                    surgery_time: timeOfSurgery,
                    is_surgery_selected: isSelected,
                    surgery_name: this.state.surgeryName
                }
                RestAPIService.create(data, Serviceurls.COUNSELLOR_RETRANSMIT_POST)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.props.SuccessMsg(response.data.message)
                            this.props.handleClose()
                            this.setState({ isDisable: false })
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.props.ErrorMsg(error.response.data.message)
                            this.setState({ isDisable: false })
                        } else {
                            this.props.ErrorMsg(error.message)
                            this.setState({ isDisable: false })
                        }
                    })
            } else {
                if (dateOfSurgery == "Invalid DateTime") {
                    this.props.ErrorMsg("Invalid Date")
                } else if (timeOfSurgery == "Invalid DateTime") {
                    this.props.ErrorMsg("Invalid Time")
                } else if ((surgon == " " || surgon == null)) {
                    this.props.ErrorMsg("Please Enter Surgeon")
                } else if (anaesthetist == " " || anaesthetist == null) {
                    this.props.ErrorMsg("Please Enter Anaesthetist")
                } else if (states?.surgeryName == "") {
                    this.props.ErrorMsg("Please Select Surgery")
                } else if (states?.SelectedPackage == null) {
                    this.props.ErrorMsg("Please Select Package")
                }
                this.setState({ isDisable: false })
            }
        } catch (error) {
            this.props.ErrorMsg(error.message)
            this.setState({ isDisable: false })
        }
    }

    TransmittedData = () => {
        let states = this.state
        try {
            RestAPIService.getAll(Serviceurls.COUNSELLOR_RETRANSMIT_POST + "?patient_id=" + states.PatientDetails.patient_id + "&clinic_id=" + this.props.ClinicID + "&appointment_id=" + states.PatientDetails.appointment_id)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            roomChangeDate: new Date(response?.data?.data?.surgery_date),
                            roomChangeTime: moment(response.data?.data?.surgery_time, 'hh:mm a'),
                            Comments: response.data?.data?.notes,
                            TotalAmount: response.data?.data?.net_amount,
                            DiscountPercent: response.data?.data?.discount_percentage,
                            DiscountAmount: response.data?.data?.discount,
                            PackageAmount: response.data?.data?.amount,
                            SelectedSurgeon: response.data?.data?.surgeon_name,
                            SelectedAnaesthetist: response.data?.data?.anaesthetist,
                            surgeryName: response.data?.data?.surgery_name
                        }, () => { this.getDoctorList() })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status) {
                        this.props.ErrorMsg(error.response.data.message)
                    } else {
                        this.props.ErrorMsg(error.message)
                    }
                })
        } catch (error) {
            this.props.ErrorMsg(error.message)
        }
    }

    getServiceTemplateData = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_COUNSELLING_SURGERY_SEARCH + "?surgery_key=" + this.props.PatientDetails?.surgery_name + "&clinic_id=" + this.props.ClinicID)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let pkg_name_for_surg = [];
                        if (this.state.PatientDetails?.surgery_name) {
                            pkg_name_for_surg = response.data.data.filter((pkg) => {
                                return pkg.surgary_name === this.state.PatientDetails?.surgery_name
                            })
                        } else {
                            pkg_name_for_surg = response.data.data
                        }
                        this.setState({
                            serviceTemplateData: pkg_name_for_surg
                        }, () => {
                            if (this.props.OTHome) {
                                this.setState({
                                    SelectedPackage: this.state.serviceTemplateData.find((element) => element.package_name === this.state.PatientDetails.package_name)
                                }, () => { this.setState({ TotalAmount: this.state?.SelectedPackage?.total_amount, PackageAmount: this.state?.SelectedPackage?.package_amount }) })
                            } else {
                                this.setState({
                                    SelectedPackage: this.state.serviceTemplateData.find((element) => element.id === this.state.SelectedPackageID)
                                }, () => { this.setState({ TotalAmount: this.state?.SelectedPackage?.total_amount, PackageAmount: this.state?.SelectedPackage?.package_amount }) })
                            }
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status) {
                        this.props.ErrorMsg(error.response.data.message)
                    } else {
                        this.props.ErrorMsg(error.message)
                    }
                })
        } catch (error) {
            this.props.ErrorMsg(error.message)
        }
    }
    // getServiceTemplateData = () => {
    //     try {
    //         RestAPIService.getAll(Serviceurls.FO_CONFIG_IPSRCPACKAGE + "?clinic_id=" + this.props.ClinicID)
    //             .then((response) => {
    //                 if (response.data.status === 'success') {
    //                     let pkg_name_for_surg = [];
    //                     if (this.state.PatientDetails?.surgery_name) {
    //                         pkg_name_for_surg = response.data.data.filter((pkg) => {
    //                             return pkg.surgary_name === this.state.PatientDetails?.surgery_name
    //                         })
    //                     } else {
    //                         pkg_name_for_surg = response.data.data
    //                     }
    //                     this.setState({
    //                         serviceTemplateData: pkg_name_for_surg
    //                     }, () => {
    //                         if (this.props.OTHome) {
    //                             this.setState({
    //                                 SelectedPackage: this.state.serviceTemplateData.find((element) => element.package_name === this.state.PatientDetails.package_name)
    //                             }, () => { this.setState({ TotalAmount: this.state?.SelectedPackage?.total_amount, PackageAmount: this.state?.SelectedPackage?.total_amount }) })
    //                         } else {
    //                             this.setState({
    //                                 SelectedPackage: this.state.serviceTemplateData.find((element) => element.id === this.state.SelectedPackageID)
    //                             }, () => { this.setState({ TotalAmount: this.state?.SelectedPackage?.total_amount, PackageAmount: this.state?.SelectedPackage?.total_amount }) })
    //                         }
    //                     })
    //                 }
    //             }).catch((error) => {
    //                 if (error?.response?.data?.status) {
    //                     this.props.ErrorMsg(error.response.data.message)
    //                 } else {
    //                     this.props.ErrorMsg(error.message)
    //                 }
    //             })
    //     } catch (error) {
    //         this.props.ErrorMsg(error.message)
    //     }
    // }

    ActionBtn = () => {
        if (this.props.OTHome) {
            if (this.state.Comments == "") {
                this.props.ErrorMsg("Please Enter Comments")
            } else {
                this.props.RescheduleAppointment(this.state.roomChangeDate, this.state.roomChangeTime, this.state.Comments)
            }
        } else {
            this.PostCounsellorRetransmit("Completed", true)
        }
    }

    getDoctorList = () => {
        try {
            RestAPIService.getAll(Serviceurls.HOME_GET_CLINIC_DOCTORS + "?clinic_id=" + this.props.ClinicID).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            DoctorData: response.data.data,
                        }, () => {
                            let Surgeon = this.state?.DoctorData.find((list) => ((list?.first_name ? list.first_name : "") === this.props.PatientDetails?.surgeon_name))
                            let Anaesthetist = this.state.DoctorData.find((list) => ((list?.first_name ? list.first_name : "") === this.props.PatientDetails?.anaesthetist))
                            this.setState({
                                SelectedSurgeon: Surgeon,
                                SelectedAnaesthetist: Anaesthetist
                            })
                        })
                    }
                    else {
                        this.props.ErrorMsg(response.data.message)
                    }
                }).catch((error) => {
                    this.props.ErrorMsg(error.message)
                })
        } catch (e) {
            this.props.ErrorMsg(e.message)
        }
    }

    handleOptionSelect = (option) => {
        let packageData = this.state.serviceTemplateData?.filter((item) => item?.id == option?.id)
        this.setState({
            surgeryName: option?.surgery_names,
            isDropdownOpen: false,
            surgery: option,
            SelectedPackage: packageData[0],
            PackageAmount: packageData[0]?.total_amount,
            DiscountAmount: "",
            DiscountPercent: "",
            TotalAmount: Math.round(+((packageData[0]?.total_amount - ((packageData[0]?.total_amount * 0) / 100)).toFixed(2))),
            SelectedPackageID: packageData[0]?.id
        }, () => {
            this.setState({ surgeryNameData: [] })
        });
    };

    render() {
        let { open } = this.state;
        let { t } = this.props
        var oneYearFromNow = new Date();
        var currentDate = new Date()
        const minTime = new Date(currentDate.getTime() - 80000);
        return (
            <Modal open={open}>
                <Paper elevation={3} id="emedhub_popup_mainBox" sx={{ width: "60vw", height: "41vw" }}>
                    <div id="emedhub_popup_header" style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                        <Typography sx={{ color: "#616161", fontWeight: 600 }}>{this.props.OTHome ? t("Reschedule") : t("Package Details")}</Typography>
                        <IconButton onClick={() => { this.props.handleClose() }} size="small" ><img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton>
                    </div>
                    <div id="emedhub_popup_container">
                        <div id="popup_Patient_details">
                            <CommonPatientDetails data={this.props.PatientDetails} showAdmission={false} packageDetail={true} />
                            {this.props.OTHome ? null :
                                <TextField
                                    label={"Counsellor"}
                                    size='small'
                                    disabled={true}
                                    sx={{ width: '15vw' }}
                                    placeholder='Enter Counsellor Name'
                                    variant="outlined"
                                    value={this.state.counsellorName}
                                    onChange={(event) => {
                                        this.setState({
                                            counsellorName: event.target.value
                                        })
                                    }}
                                />
                            }
                        </div>
                        <Divider style={{ paddingTop: "0.5vw", paddingBottom: "0.5vw" }} />
                        <div className='Popup_Text_Area'>
                            {<Typography className='PopupDetail'>{t("Ref Doctor Name : ")}<span className='PopupSubDetail'>
                                {this.state.PatientDetails?.doctor_name && this.state.PatientDetails?.doctor_name.length > 20 ? <Tooltip placement="top" title={this.state.PatientDetails?.doctor_name} arrow><div style={{ fontSize: "0.9vw", marginLeft: "0.35vw", marginTop: "0.05vw" }}>{this.state.PatientDetails?.doctor_name.slice(0, 18) + "..."}</div></Tooltip> : this.state.PatientDetails?.doctor_name ? this.state.PatientDetails?.doctor_name : "-"}
                            </span></Typography>}
                            {this.props?.OTHome ? <Typography className='PopupDetail'>{t("Surgery : ")}<span className='PopupSubDetail'>
                                {this.state.PatientDetails?.surgery_name && this.state.PatientDetails?.surgery_name.length > 20 ? <Tooltip placement="top" title={this.state.PatientDetails?.surgery_name} arrow><div style={{ fontSize: "0.9vw", marginLeft: "0.35vw", marginTop: "0.05vw" }}>{this.state.PatientDetails?.surgery_name.slice(0, 18) + "..."}</div></Tooltip> : this.state.PatientDetails?.surgery_name ? this.state.PatientDetails?.surgery_name : "-"}
                            </span></Typography> :
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <Typography sx={{ width: "5vw" }} className='PopupDetail'>{t("Surgery : ")}</Typography>
                                    <div>
                                        <Autocomplete
                                            clearIcon
                                            size='small'
                                            sx={{ width: '15vw' }}
                                            freeSolo
                                            options={this.state.surgeryNameData}
                                            getOptionLabel={(option) => (typeof (option) === 'string') ? option : option?.surgery_key}
                                            value={this.state.surgeryName}
                                            autoComplete='off'
                                            disabled={this.state.isDisable}
                                            onChange={(e) => {
                                                let newValue = e.target.value;
                                                if (newValue === "") {
                                                    this.setState({
                                                        surgeryNameData: [],
                                                        isDropdownOpen: false,
                                                        surgery: null,
                                                        surgeryName: "",
                                                        SelectedPackage: null,
                                                        PackageAmount: 0,
                                                        DiscountAmount: "",
                                                        DiscountPercent: "",
                                                        TotalAmount: 0
                                                    })
                                                }
                                            }}
                                            renderOption={(props, option) => {
                                                return (
                                                    <div onClick={() => {
                                                        this.setState({
                                                            surgeryName: option?.surgery_key,
                                                            surgery_id: option?.surgery_id,
                                                            SelectedPackage: null,
                                                            PackageAmount: 0,
                                                            DiscountAmount: "",
                                                            DiscountPercent: "",
                                                            TotalAmount: 0
                                                        }, () => { this.GetPackageList() })
                                                    }}>
                                                        <li {...props}>
                                                            {option?.surgery_key ? option?.surgery_key : null}
                                                        </li>
                                                    </div>
                                                )
                                            }}
                                            renderInput={(params) => <TextField {...params} label='Search and Select Surgery *' />}
                                        />
                                    </div>
                                    {/* <div className="custom-dropdown" ref={this.dropdownRef}>
                                        <TextField
                                            label='Search and Select Surgery'
                                            autoComplete="off"
                                            size='small'
                                            sx={{ width: '15vw' }}
                                            // onClick={() => { this.handleToggleDropdown() }}
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                this.setState({ package: '', surgeryName: newValue }, () => {
                                                    if (newValue.trim() === "") {
                                                        this.setState({
                                                            surgeryNameData: [],
                                                            isDropdownOpen: false,
                                                            surgery: null,
                                                            surgeryName: "",
                                                            SelectedPackage: null,
                                                            PackageAmount: 0,
                                                            DiscountAmount: 0,
                                                            DiscountPercent: 0,
                                                            TotalAmount: 0
                                                        });
                                                    } else if (this.state.surgeryName.length > 0) {
                                                        this.getSurgeryNameList();
                                                    }
                                                })
                                            }}
                                            value={this.state?.surgeryName ? this.state?.surgeryName : ""}
                                        />
                                        {(this.state.surgeryNameData?.length > 0 && this.state.isDropdownOpen) && (
                                            <div className="dropdown-content">
                                                {this.state.surgeryNameData.map((option, index) => (
                                                    <div
                                                        className="dropdown-item"
                                                        key={index}
                                                        onClick={() => { this.handleOptionSelect(option) }}
                                                    >
                                                        {`${option?.surgery_names} ${option?.package_name ? (" - " + option?.package_name) : ""}`}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div> */}
                                </div>}
                            {/* {this.state.PatientDetails?.doctor_name ?
                                <Typography className='PopupDetail'>{t("Power : ")}<span className='PopupSubDetail'></span>{this.state.PatientDetails?.doctor_name ? this.state.PatientDetails?.doctor_name : "-"}</Typography>
                                : null} */}
                            {/* {this.state.PatientDetails?.doctor_name ?
                            <Typography className='PopupDetail'>{t("Eye Type : ")}<span className='PopupSubDetail'>

                            </span></Typography>  
                            : null} */}
                        </div>
                        <div className='popup_Appoint_details'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={this.state.roomChangeDate ? this.state.roomChangeDate : null}
                                    inputFormat='DD-MM-YYYY'
                                    style={{ width: '20%' }}
                                    label={t("Surgery Date")}
                                    maxDate={oneYearFromNow.setFullYear(currentDate.getFullYear() + 1)}
                                    minDate={new Date()}
                                    disabled={this.props.OTHome ? false : this.state.isDisable}
                                    onChange={
                                        (newDate) => {
                                            if (newDate == null) {
                                                this.setState({
                                                    roomChangeDate: new Date()
                                                });
                                            } else {
                                                this.setState({
                                                    roomChangeDate: newDate.$d
                                                });
                                            }
                                        }}
                                    renderInput={(params) => <TextField size='small' {...params} />}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker format="h:mm A" label={t("Surgery Time *")} minTime={this.state.isDisable ? null : (this.state.roomChangeDate > moment(currentDate)) ? null : moment(minTime)} disabled={this.props.OTHome ? false : this.state.isDisable} style={{ width: '20%' }}
                                    value={this.state.roomChangeTime ? moment(this.state.roomChangeTime, 'hh:mm a') : null}
                                    onChange={(time) => {
                                        if (time == null) {
                                            this.setState({
                                                roomChangeDate: new Date()
                                            });
                                        } else {
                                            this.setState({
                                                roomChangeTime: time.$d
                                            })
                                        }
                                    }}
                                    renderInput={(props) => <TextField {...props} inputProps={{ ...props.inputProps, placeholder: "HH:MM (AM|PM)" }} size="small" />}
                                />
                            </LocalizationProvider>
                            <FormControl size='small' style={{ width: '20%' }}>
                                <InputLabel>{t('Surgeon *')}</InputLabel>
                                <Select
                                    value={this.state.SelectedSurgeon ? this.state.SelectedSurgeon : ""}
                                    label={t('Surgeon')}
                                    disabled={this.state.isDisable}
                                    onChange={(event) => {
                                        let values = event.target.value;
                                        this.setState({
                                            SelectedSurgeon: values
                                        })
                                    }}
                                    MenuProps={{
                                        style: { maxHeight: 350 },
                                    }}
                                >
                                    <MenuItem value={""}>{"Select"}</MenuItem>
                                    {this.state.DoctorData?.length > 0 ?
                                        this.state.DoctorData.map((item, index) => {
                                            return <MenuItem key={index} value={item}>{(item.title ? item.title : "") + " " + (item.initial ? item.initial : "") + " " + (item.first_name ? item.first_name : "")}</MenuItem>
                                        }) : null}
                                </Select>
                            </FormControl>
                            <FormControl size='small' style={{ width: '20%' }}>
                                <InputLabel id="Dis_lay_Diet">{t('Anaesthetist *')}</InputLabel>
                                <Select
                                    value={this.state.SelectedAnaesthetist ? this.state.SelectedAnaesthetist : ""}
                                    label={t('Anaesthetist')}
                                    disabled={this.state.isDisable}
                                    onChange={(event) => {
                                        let values = event.target.value;
                                        this.setState({
                                            SelectedAnaesthetist: values
                                        })
                                    }}
                                    MenuProps={{
                                        style: { maxHeight: 350 },
                                    }}
                                >
                                    <MenuItem value={""}>{"Select"}</MenuItem>
                                    {this.state.DoctorData?.length > 0 ? this.state.DoctorData.map((item, index) => {
                                        return <MenuItem key={index} value={item}>{(item.title ? item.title : "") + " " + (item.initial ? item.initial : "") + " " + (item.first_name ? item.first_name : "")}</MenuItem>
                                    }) : null}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ width: "29%" }} id="emedhub_directpoTable_headTxt">{t("Package Name")}</TableCell>
                                            <TableCell sx={{ width: "17%" }} id="emedhub_directpoTable_headTxt">{t('Amount') + ` (${CurrencySymbol})`}</TableCell>
                                            <TableCell sx={{ width: "17%" }} id="emedhub_directpoTable_headTxt">{t('Discount') + ` (${CurrencySymbol})`}</TableCell>
                                            <TableCell sx={{ width: "17%" }} id="emedhub_directpoTable_headTxt"> {t("Discount (%)")}</TableCell>
                                            <TableCell sx={{ width: "20%" }} id="emedhub_directpoTable_headTxt">{t("Bill Amount")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ width: "29%" }}>
                                                <Autocomplete
                                                    disableClearable
                                                    size='medium'
                                                    value={this.state.SelectedPackage}
                                                    options={this.state.PackageData}
                                                    getOptionLabel={(option) => (typeof (option) === 'string') ? option : option?.package_name}
                                                    disabled={this.state.isDisable}
                                                    onChange={(event, newValue) => {
                                                        this.setState({
                                                            SelectedPackage: newValue,
                                                            PackageAmount: newValue?.total_amount,
                                                            DiscountAmount: "",
                                                            DiscountPercent: "",
                                                            TotalAmount: Math.round(+((newValue.total_amount - ((newValue.total_amount * 0) / 100)).toFixed(2))),
                                                            SelectedPackageID: newValue.id
                                                        })
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={t('Select Package *')}
                                                        />
                                                    )} />
                                            </TableCell>
                                            <TableCell sx={{ width: "17%" }}>
                                                <TextField
                                                    size='medium'
                                                    disabled={true}
                                                    variant="outlined"
                                                    value={this.state.PackageAmount}
                                                    onChange={(event) => {
                                                        this.setState({
                                                            PackageAmount: event.target.value
                                                        })
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ width: "17%" }}>
                                                <TextField
                                                    size='medium'
                                                    variant="outlined"
                                                    disabled={this.state.isDisable}
                                                    value={this.state.DiscountAmount}
                                                    onChange={(event) => {
                                                        let isValid = CommonValidation.numberOnly(event.target.value)
                                                        if (isValid && event.target.value < this.state.PackageAmount) {
                                                            this.setState({
                                                                DiscountAmount: event.target.value,
                                                                DiscountPercent: +((event.target.value * 100 / this.state.PackageAmount)).toFixed(2)
                                                            }, () => { this.setState({ TotalAmount: Math.round(+((this.state.PackageAmount - ((this.state.PackageAmount * this.state.DiscountPercent) / 100)).toFixed(2))) }) })
                                                        } else if (event.target.value == 0 || event.target.value == "") {
                                                            this.setState({
                                                                DiscountAmount: "",
                                                                DiscountPercent: "",
                                                                TotalAmount: this.state.PackageAmount
                                                            })
                                                        }
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ width: "17%" }}>
                                                <TextField
                                                    size='medium'
                                                    variant="outlined"
                                                    disabled={this.state.isDisable}
                                                    value={this.state.DiscountPercent}
                                                    onChange={(event) => {
                                                        let isValid = CommonValidation.NumberWithDot(event.target.value)
                                                        if (isValid && event.target.value <= 100) {
                                                            let discountedAmt = ((this.state.PackageAmount * event.target.value) / 100)
                                                            this.setState({
                                                                DiscountPercent: event.target.value,
                                                                TotalAmount: Math.round(+((this.state.PackageAmount - discountedAmt).toFixed(2))),
                                                                DiscountAmount: Math.round(+(((this.state.PackageAmount * event.target.value) / 100).toFixed(2)))
                                                            })
                                                        } else if (event.target.value == 0 || event.target.value == "") {
                                                            this.setState({
                                                                DiscountPercent: "",
                                                                DiscountAmount: "",
                                                                TotalAmount: this.state.PackageAmount
                                                            })
                                                        }
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ width: "20%" }}>
                                                <TextField
                                                    size='medium'
                                                    variant="outlined"
                                                    disabled={true}
                                                    value={this.state.TotalAmount}
                                                    onChange={(event) => {
                                                        this.setState({
                                                            TotalAmount: event.target.value
                                                        })
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <div style={{ marginTop: "0.5vw" }}>
                            <TextField
                                label={this.props.OTHome ? t("Comments*") : t("Counsellor Comments")}
                                sx={{ width: '100%' }}
                                multiline={true}
                                inputProps={{ maxLength: 500 }}
                                disabled={this.props.OTHome ? false : this.state.isDisable}
                                rows={3}
                                variant="outlined"
                                value={this.state.Comments}
                                onChange={(event) => {
                                    this.setState({
                                        Comments: event.target.value
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <Stack id="emedhub_popup_footer" direction="row" justifyContent={"space-between"} alignItems="center">
                        <div>
                            {this.props.OTHome ? null : <Button variant="outlined" disabled={this.state.isDisable} size="small" sx={{ textTransform: "capitalize" }} onClick={() => { this.PostCounsellorRetransmit("Draft", false) }}>{t("Save As Draft")}</Button>}
                        </div>
                        <div>
                            <Button size="small" disabled={this.props.OTHome ? false : this.state.isDisable} sx={{ marginRight: "1vw", textTransform: "capitalize" }} variant={"contained"} onClick={() => { this.ActionBtn() }}>{this.props.OTHome ? t("Reschedule") : t("Confirm and Approved")}</Button>
                            <Button variant="outlined" size="small" sx={{ textTransform: "capitalize" }} onClick={() => { this.props.handleClose() }}>{t("Cancel")}</Button>
                        </div>
                    </Stack>
                </Paper>
            </Modal>
        )
    }
}
export default withTranslation()(PackageDeatailPop)