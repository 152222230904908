import React, { Component } from 'react'
import { CommonDeleteButton, CommonEditButton, CommonPopUp, DeletePopup } from '../../../../../../Components/Common Components/CommonComponents'
import { withTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';
import { Box, IconButton, Menu, MenuItem, Stack, Tooltip } from '@mui/material';
import RestAPIService from '../../../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../../../Components/ToastMsg/ToastMsg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Colors } from '../../../../../../Styles/Colors';
import { CheckAccessFunc } from '../../../../../../Components/CommonFunctions/CommonFunctions';
class SymptomsLeft extends Component {
  constructor(props) {
    super(props)
    this.state = {
      SymptomsData: [],
      isDeleteCliked: false,
      SelectedList: {},
      IpPatientData: this.props?.IpPatientData,
      isHistoryClicked: false,
      SelectedHistory: [],
      withOutAppointment : {},
    }
  }

  componentDidMount() {
    // this.GetSymptomsData()
    if (this.props.withOutAppointment?.patient_id !== this.props.withOutAppointment?.patient_id) {
      this.setState({ withOutAppointment: this.props.withOutAppointment })
    }
  }
  componentDidUpdate(prevprops) {
    if (prevprops.GetAPiCall !== this.props.GetAPiCall && this.props.GetAPiCall) { this.GetSymptomsData() }
    if (prevprops.IpPatientData !== this.props.IpPatientData) { this.setState({ IpPatientData: this.props.IpPatientData }, () => { this.GetSymptomsData() }) }
    if (prevprops.withOutAppointment?.patient_id !== this.props.withOutAppointment?.patient_id) { this.setState({ withOutAppointment: this.props.withOutAppointment }, () => { this.GetSymptomsData() }) }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  GetSymptomsData() {
    let admission_id =   this.state.IpPatientData?.id || this.state.withOutAppointment?.ip_admission_id;
    try {
      RestAPIService.getAll(Serviceurls.IP_DOCNOTES_SYMPTOMS + "?ip_admission_id=" + admission_id).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              SymptomsData: response.data.data
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  onEditHandler(data) {
    this.props.EditSymptomData(data.row)
  }
  onDeleteHandler(data) {
    try {
      this.setState({
        isDeleteCliked: true,
        SelectedList: data.row,
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  popupClose() {
    this.setState({ isDeleteCliked: false })
  }
  DeleteSymptom() {
    try {
      var states = this.state
      RestAPIService.delete(Serviceurls.IP_DOCNOTES_SYMPTOMS + "?symptoms_id=" + states.SelectedList.id).
        then((response) => {
          if (response.data.status === "success") {
            this.successMessage(response.data.message)
            this.setState({ isDeleteCliked: false }, () => { this.GetSymptomsData() })
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  GetHistoryData = (id) => {
    try {
      RestAPIService.getAll(Serviceurls.IP_CLINICNOTES_HISTORY + "?condition=symptom" + "&row_id=" + id).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              SelectedHistory: response.data.data,
              isHistoryClicked: true,
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  MenuItem = (data) => {
    const { t } = this.props
    const multi_option = [
      { value: "History", label: t("History") },
    ]
    return (
      <div>
        <Tooltip title={t("More")} placement='top' arrow>
          <IconButton
           disabled = {this.state.withOutAppointment?.is_ip}
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data.id,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow == data.id) ?
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
            <MenuItem onClick={() => { this.setState({ anchorEl: null }, () => { this.GetHistoryData(data?.row?.id) }) }}>
              {t("History")}
            </MenuItem>
          </Menu> : null}
      </div>
    )
  }
  historyPopUpClose() {
    this.setState({
      isHistoryClicked: false,
      SelectedHistory: []
    })
  }

  _getHistory(historydata) {
    historydata.forEach((element, index) => { element.sno = index + 1 })
    const { t } = this.props;
    let { anchorEl2 } = this.state;
    let open = Boolean(anchorEl2);
    const historyColumn = [
      { field: "sno", flex: 0.08, headerName: t("SNo") },
      {
        field: "user", flex: 0.16, headerName: t("User"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.user ? params?.row?.user : "-"}</Box>)
      },
      {
        field: "modified_date", flex: 0.20, headerName: t("ModifiedDate"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.modified_date ? params?.row?.modified_date : "-"}</Box>)
      },
      {
        field: "symptoms_name_from", flex: 0.21, headerName: t("PreviousSymptom"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.symptoms_name_from ? params?.row?.symptoms_name_from?.length > 15 ?
            <Tooltip placement='top' title={params?.row?.symptoms_name_from} arrow>
              <div>{params?.row?.symptoms_name_from.slice(0, 15) + "..."}</div></Tooltip> :
            params?.row?.symptoms_name_from : "-"}
        </Box>)
      },
      {
        field: "symptoms_name_to", flex: 0.166, headerName: t("ChangedSymptom"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.symptoms_name_to ? params?.row?.symptoms_name_to?.length > 15 ?
            <Tooltip placement='top' title={params?.row?.symptoms_name_to} arrow>
              <div>{params?.row?.symptoms_name_to.slice(0, 15) + "..."}</div></Tooltip> :
            params?.row?.symptoms_name_to : "-"}
        </Box>)
      },
      {
        field: "description_from", flex: 0.21, headerName: t("PreviousDescription"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.description_from ? params?.row?.description_from?.length > 15 ?
            <Tooltip placement='top' title={params?.row?.description_from} arrow>
              <div>{params?.row?.description_from.slice(0, 15) + "..."}</div></Tooltip> :
            params?.row?.description_from : "-"}
        </Box>)
      },
      {
        field: "description_to", flex: 0.166, headerName: t("ChangedDescription"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.description_to ? params?.row?.description_to?.length > 15 ?
            <Tooltip placement='top' title={params?.row?.description_to} arrow>
              <div>{params?.row?.description_to.slice(0, 15) + "..."}</div></Tooltip> :
            params?.row?.description_to : "-"}
        </Box>)
      },
    ];
    return (
      <Box id="eMed_ipsrvConfig_accTbl">
        <DataGrid
          rows={historydata}
          columns={historyColumn}
          getRowId={(row) => row.sno}
          disableColumnMenu
          hideFooter
          components={{
            NoRowsOverlay: () => (
              <Stack className='eMed_conf_nodata'>
                {t("NoRecordsFound")}
              </Stack>
            )
          }}
          headerHeight={40}
          disableSelectionOnClick
        />
      </Box>
    )
  }
  msgClose(){
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  render() {
    try {
      const { t } = this.props
      this.state.SymptomsData.forEach((element, index) => { element.sno = index + 1 })
      const columns = [
        {
          field: "sym_date", flex: 0.14, headerName: t("Date"),
          renderCell: (params) => (
            params?.row?.sym_date.length > 10 ?
            <Tooltip title={params?.row?.sym_date} placement='top' arrow>
                <div>{params?.row?.sym_date ? params.row.sym_date.slice(0, 10) + '...' : '-'}</div>
            </Tooltip> : <div>{params?.row?.sym_date ? params.row.sym_date : '-'}</div>
          )
        },
        {
          field: "symptoms_name", flex: 0.17, headerName: t("SymtomName"),
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.symptoms_name ? params?.row?.symptoms_name?.length > 18 ?
              <Tooltip placement='top' title={params?.row?.symptoms_name} arrow>
                <div>{params?.row?.symptoms_name.slice(0, 18) + "..."}</div></Tooltip> :
              params?.row?.symptoms_name : "-"}
          </Box>)
        },
        {
          field: "duration", flex: 0.14, headerName: t("Duration"), headerAlign: "center", align: 'center',
          renderCell: (params) => {
            let DurationText = params?.row?.duration ? `${params?.row?.duration}  ${params?.row?.duration_type}` : "-"
            return (DurationText)
          }
        },
        {
          field: "description", flex: 0.18, headerName: t("Description"),
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.description ? params?.row?.description?.length > 18 ?
              <Tooltip placement='top' title={params?.row?.description} arrow>
                <div>{params?.row?.description.slice(0, 18) + "..."}</div></Tooltip> :
              params?.row?.description : "-"}
          </Box>)
        },
        {
          field: "created_by", flex: 0.14, headerName: t("CreatedBy"),
          renderCell: (params) => (<Box component={'div'}>{params?.row?.created_by ? params?.row?.created_by : "-"}</Box>)
        },
        {
          field: "modified_by", flex: 0.14, headerName: t("UpdatedBy"),
          renderCell: (params) => (<Box component={'div'}>{params?.row?.modified_by ? params?.row?.modified_by : "-"}</Box>)
        },
        {
          field: "action", flex: 0.14, headerName: t("Action"), headerAlign: "center", align: "center",
          renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <CommonEditButton size={"1.5vw"} disable = {CheckAccessFunc("front_office", "In Patients","Clinical Notes", "Doctor Notes", "SubTab")?.editAccess ? this.state.withOutAppointment?.is_ip : true} onClick={this.onEditHandler.bind(this, params)} />
            <CommonDeleteButton size={"1.5vw"} disable = {CheckAccessFunc("front_office", "In Patients","Clinical Notes", "Doctor Notes", "SubTab")?.editAccess ? this.state.withOutAppointment?.is_ip : true} onClick={this.onDeleteHandler.bind(this, params)} />
            {this.MenuItem(params)}
          </Box>
        }
      ]
      return (
        <Box height={"35vh"}>
          <DataGrid
            rows={this.state.SymptomsData}
            columns={columns}
            getRowId={(row) => row['sno']} c
            headerHeight={40}
            hideFooter={true}
            components={{
              NoRowsOverlay: () => (
                <Stack className='eMed_DocNotes_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            disableSelectionOnClick
          />
          {this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.isDeleteCliked ? <DeletePopup
            DeletTitle={`${t("deleteMsg")} ${t("Symptom")} ${this.state.SelectedList ? this.state.SelectedList?.symptoms_name : "-"} ?`}
            deleteAlertPopupClose={this.popupClose.bind(this)}
            removeData={this.DeleteSymptom.bind(this)}
          /> : null}
          {(this.state.isHistoryClicked) ?
            <CommonPopUp width={"75vw"}
              backgroundColor={Colors.white}
              popUpClose={this.historyPopUpClose.bind(this)}
              title={t("History")}
              component={this._getHistory.bind(this, this.state.SelectedHistory)} />
            : null
          }
        </Box>
      )
    }
    catch (e) {

    }
  }
}
export default withTranslation()(SymptomsLeft)
