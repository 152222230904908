import { Autocomplete, Box, Button, FormControl, IconButton, InputAdornment, InputLabel, Menu, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import CommonValidation from '../../Components/CommonFunctions/CommonValidation';
import { Colors } from '../../Styles/Colors';
import RestAPIService from '../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../Utility/API/Serviceurls';
import { getCachevalue, localGetItem, setCachevalue } from '../../Utility/Services/CacheProviderService';
import ToastMsg from '../../Components/ToastMsg/ToastMsg';
import { CommonPatientCard, CommonPatientDetails, ConsultantPatientCard } from '../../Components/Common Components/CommonComponents';
import { ImagePaths } from '../../Utility/ImagePaths';


class SurgeonDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SurgeonOne: "",
            SurgeonTwo: "",
            SurgeonThree: "",
            Technician: "",
            Assistant: "",
            JrAnaesthetist: "",
            anaesthesia_type: "",
            Duration: "",
            durationType: "Hours",
            BloodRequirement: "",
            Notes: "",
            ClinicID: null,
            DoctorData: [],
            OTpatientData: null,
            ot_id: null,
            OTDetails: [],
        }
    }

    componentDidMount = () => {
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        let Data = JSON.parse(getCachevalue("OTSurgeonData")) ? JSON.parse(getCachevalue("OTSurgeonData")) : JSON.parse(getCachevalue("OTpatientData"))
        var clinic_id = myUser?.clinic_id ? myUser?.clinic_id : localGetItem("SelectedDoctorClinicId") ? localGetItem("SelectedDoctorClinicId") : null
        var OTID = JSON.parse(getCachevalue("OTpatientData"))
        if (clinic_id) {
            this.setState({
                ClinicID: clinic_id,
                OTpatientData: Data?.additional_detail,
                ot_id: OTID?.id
            }, () => {
                this.getDoctorList()
            })
        }
        if (myUser?.module_name === "ot_management") {
            let patientDatas = getCachevalue("OTpatientData")
            let patients = JSON.parse(patientDatas)
            this.setState({
                OTDetails: patients
            })
        }
    }

    getDoctorList = () => {
        try {
            RestAPIService.getAll(Serviceurls.HOME_GET_CLINIC_DOCTORS + "?clinic_id=" + this.state.ClinicID).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            DoctorData: response.data.data,
                        }, () => {
                            this.setState({
                                SurgeonOne: this.state?.OTpatientData?.surgeon1,
                                SurgeonTwo: this.state?.OTpatientData?.surgeon2,
                                SurgeonThree: this.state?.OTpatientData?.surgeon3,
                                Technician: this.state?.OTpatientData?.Technician,
                                Assistant: this.state?.OTpatientData?.Assistant,
                                JrAnaesthetist: this.state?.OTpatientData?.JrAnaesthetist,
                                anaesthesia_type: this.state?.OTpatientData?.anaesthesia_type,
                                Duration: this.state?.OTpatientData?.Duration,
                                durationType: this.state?.OTpatientData?.durationType ? this.state?.OTpatientData?.durationType : "Hours",
                                BloodRequirement: this.state?.OTpatientData?.BloodRequirement,
                                Notes: this.state?.OTpatientData?.Notes
                            })
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    PostSurgeonDetails = () => {
        try {
            let Data = {
                id: this.state.ot_id,
                additional_detail: {
                    "surgeon1": this.state.SurgeonOne,
                    "surgeon2": this.state.SurgeonTwo,
                    "surgeon3": this.state.SurgeonThree,
                    "Technician": this.state.Technician,
                    "Assistant": this.state.Assistant,
                    "JrAnaesthetist": this.state.JrAnaesthetist,
                    "anaesthesia_type": this.state.anaesthesia_type,
                    "Duration": this.state.Duration,
                    "durationType": this.state.durationType,
                    "BloodRequirement": this.state.BloodRequirement,
                    "Notes": this.state.Notes
                }
            }
            RestAPIService.updateWithOutId(Data, Serviceurls.DOC_OT_REQUEST_GET)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.successMessage(response.data.message)
                        setCachevalue(JSON.stringify(Data), "OTSurgeonData");
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    ClearData = () => {
        this.setState({
            SurgeonOne: "",
            SurgeonTwo: "",
            SurgeonThree: "",
            Technician: "",
            Assistant: "",
            JrAnaesthetist: "",
            anaesthesia_type: "",
            Duration: "",
            durationType: "Hours",
            BloodRequirement: "",
            Notes: ""
        })
    }

    renderTextInput = (Title, value, key) => {
        let states = this.state
        return (
            <FormControl size='small' style={{ width: '18vw', margin: "0.65vw" }}>
                <InputLabel>{Title}</InputLabel>
                <Select
                    value={value ? value : ""}
                    label={Title}
                    onChange={(event) => {
                        states[key] = event.target.value;
                        this.setState({
                            states
                        })
                    }}
                    MenuProps={{
                        style: { maxHeight: 350 },
                    }}
                >
                    <MenuItem value={""}>{"Select"}</MenuItem>
                    {this.state.DoctorData?.length > 0 ?
                        this.state.DoctorData.map((item, index) => {
                            return <MenuItem key={index} value={item?.doctor_id}>{(item.title ? item.title : "") + " " + (item.initial ? item.initial : "") + " " + (item.first_name ? item.first_name : "")}</MenuItem>
                        }) : null}
                </Select>
            </FormControl>
        )
    }

    renderNurseNotesHeader = () => {
        const { t } = this.props
        let vital_attender_data = {
            lineOne: t("AttenderName"),
            lineTwo: `${this.state.vital_attender_name ? this.state.vital_attender_name : this.state.OTDetails.attender_name ? this.state.OTDetails.attender_name : "-"}`,
            lineFour: t('ContactNumber'),
            LineFive: `${this.state.vital_attender_mobile ? this.state.vital_attender_mobile : this.state.OTDetails.attender_mobile ? this.state.OTDetails.attender_mobile : "-"}`
        }
        let vital_doctor_data = {
            image: ImagePaths.Doctor_Icon.default,
            height: "2.3vw",
            width: "2.3vw",
            lineOne: t("ConsultantName"),
            lineTwo: `${this.state.vital_doctor_name ? this.state.vital_doctor_name : this.state.OTDetails.admitting_consultant ? this.state.OTDetails.admitting_consultant : "-"}`
        }
        return (
            <Box display={'flex'} >
                <Box className='eMed_nur_notes_details'>
                    {
                        <CommonPatientDetails data={this.state.OTDetails} />
                    }
                </Box>
                {
                    <>
                        <CommonPatientCard details={vital_attender_data} showDetailed={true} />
                        <ConsultantPatientCard name={vital_doctor_data} />
                    </>
                }
            </Box>
        )
    }

    render() {
        const { t } = this.props;
        let states = this.state
        return (
            <Stack component={"div"} style={{ marginTop: "1vw" }}>
                <Box component={'div'} sx={{height:"5vw"}}>
                    {this.renderNurseNotesHeader()}
                </Box>
                <Paper>
                    <Box sx={{ height: "50vh" }}>
                        <Box component={'div'}>
                            <Typography style={{ backgroundColor: "#ffffff", borderBottom: "1px solid #D3D3D3" }} className='eMed_refdoc_title'>{t("OT Details")}</Typography>
                        </Box>
                        <Box>
                            <Box>
                                <Typography style={{ height: "4vh", color: "#616161", paddingLeft: "1vw", fontWeight: "600", display: "flex", alignItems: "center", marginTop: "0.5vw", fontSize: "0.85vw" }}>{t("OT Team")}</Typography>
                                <div style={{ display: "flex", flexDirection: 'row', alignItems: "center", justifyContent: "space-between" }}>
                                    {this.renderTextInput("Assistant Surgeon 1", this.state.SurgeonOne, "SurgeonOne")}
                                    {this.renderTextInput("Assistant Surgeon 2", this.state.SurgeonTwo, "SurgeonTwo")}
                                    {this.renderTextInput("Assistant Surgeon 3", this.state.SurgeonThree, "SurgeonThree")}
                                    {this.renderTextInput("OT Technician", this.state.Technician, "Technician")}
                                    {this.renderTextInput("OT Assistant", this.state.Assistant, "Assistant")}
                                </div>
                            </Box>
                            <Box>
                                <Typography style={{ height: "4vh", color: "#616161", paddingLeft: "1vw", fontWeight: "600", display: "flex", alignItems: "center", marginTop: "0.5vw", fontSize: "0.85vw" }}>{t("Anaesthetist Deatils")}</Typography>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    {this.renderTextInput("Jr Anaesthetist", this.state.JrAnaesthetist, "JrAnaesthetist")}
                                    <TextField
                                        inputProps={{ maxLength: 30 }}
                                        sx={{ width: "18vw", marginLeft: "1vw" }}
                                        size='small'
                                        label={"Anaesthesia Type"}
                                        value={this.state.anaesthesia_type}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            this.setState({
                                                anaesthesia_type: e.target.value
                                            })
                                        }}
                                    />
                                    <div style={{ display: "flex", flexDirection: "row", width: "35%", marginLeft: "1vw" }}>
                                        <TextField
                                            size='small'
                                            sx={{ width: '12vw' }}
                                            value={this.state.Duration}
                                            label='Approx Duration'
                                            onChange={(e) => {
                                                let isValid = CommonValidation.numberOnly(e.target.value)
                                                if (isValid || e.target.value == "") {
                                                    this.setState({ Duration: e.target.value })
                                                }
                                            }}
                                        />
                                        <FormControl sx={{ width: "8vw", marginLeft: "-0.1vw" }}>
                                            <Select
                                                value={this.state.durationType}
                                                onChange={(event) => { this.setState({ durationType: event.target.value }) }}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                size='small'
                                            >
                                                <MenuItem value="Hours">Hours</MenuItem>
                                                <MenuItem value="Minutes">Minutes</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </Box>
                            <Box>
                                <Typography style={{ height: "4vh", color: "#616161", paddingLeft: "1vw", fontWeight: "600", display: "flex", alignItems: "center", marginTop: "0.5vw", fontSize: "0.85vw" }}>{t("Other Details")}</Typography>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <div style={{ marginLeft: "1vw" }}>
                                        <TextField
                                            inputProps={{ maxLength: 300 }}
                                            className='eMed_small_textFields'
                                            sx={{ width: "22vw" }}
                                            size='small'
                                            label={"Blood Requirements made By"}
                                            value={this.state.BloodRequirement}
                                            autoComplete="off"
                                            onChange={(e) => {
                                                this.setState({
                                                    BloodRequirement: e.target.value
                                                })
                                            }}
                                        />
                                    </div>
                                    <TextField
                                        inputProps={{ maxLength: 300 }}
                                        className='eMed_small_textFields'
                                        style={{ width: "22vw", marginLeft: "1vw" }}
                                        size='small'
                                        label={"Notes"}
                                        value={states?.Notes}
                                        onChange={(e) => {
                                            states.Notes = e.target.value
                                            this.setState({
                                                states
                                            })
                                        }}
                                        autoComplete="off"
                                    />
                                </div>
                            </Box>
                        </Box>
                    </Box>
                    <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-end" height={"6vh"} marginTop={"1vw"} marginRight={"3vw"}>
                        <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
                        <Button variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.PostSurgeonDetails() }}>{t("Save")}</Button>
                    </Stack>
                </Paper>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Stack>
        )
    }
}
export default withTranslation()(SurgeonDetails)