import React, { Component } from 'react'
import { CommonDeleteButton, CommonEditButton, DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import { withTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import ShowComponents from '../Common/ShowComponent';
import { formatDate } from '../../../../Components/CommonFunctions/CommonFunctions';


class DocAllergiesLeft extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Allerydata: [],
            isDeleteCliked: false,
            SelectedList: {},
            PatientData: this.props?.PatientData,
            EditItem:{},
        }
    }

    componentDidUpdate(prevprops) {
        if (prevprops.GetAPiCall !== this.props.GetAPiCall && this.props.GetAPiCall) { this.GetAllerydata() }
        if (prevprops.SelectedComponent !== this.props.SelectedComponent && this.props.SelectedComponent) { 
            this.setState({ EditItem : {}})
         }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    GetAllerydata(keepEditId = false) {
        try {
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_ALLERGIES + `?patient_id=${this.state.PatientData?.patient_id}`).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            EditItem : keepEditId ? this.state.EditItem : {},
                            Allerydata: response.data.data,
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    onEditHandler(data) {
        this.setState({
            EditItem: data.row,
        }, ()=>{ this.props.EditAllergyData(data.row) })
    }
    onDeleteHandler(data) {
        try {
            this.setState({
                isDeleteCliked: true,
                SelectedList: data.row,
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    popupClose() {
        this.setState({ isDeleteCliked: false, SelectedList: {}, })
    }
    DeleteAllergy() {
        try {
            var states = this.state
            RestAPIService.delete(Serviceurls.DOC_DOCTOR_NOTES_ALLERGIES+`?id=${states.SelectedList?.id}`, {} ).
                then((response) => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message)
                        this.setState({ isDeleteCliked: false }, () => { this.GetAllerydata(true) })
                    } else {
                        this.errorMessage(response.data.message)
                    }
                }).catch(error => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    render() {
        try {
            const { t } = this.props
            this.state.Allerydata.forEach((element, index) => { element.sno = index + 1 })
            const columns = [
                {
                    field: "allergy_type", flex: 0.17, headerName: t("Allergy Type"),
                    renderCell: (params) => (
                        <Box component={'div'}>
                            <Typography fontSize={'0.9vw'}>{params?.row?.allergy_type === 2 ? "Non Medication Allergy" : "Medication Allergy"}</Typography>
                            <Typography fontSize={'0.9vw'}>{params?.row?.diagnosed_date ? formatDate(params?.row?.diagnosed_date) : "-"}</Typography>
                        </Box>
                    )
                },
                {
                    field: "name", flex: 0.14, headerName: t("Allergy Name"), headerAlign: "center", align: 'center',
                    renderCell: (params) => (<Box component={'div'}>
                        {params?.row?.name ? params?.row?.name?.length > 18 ?
                            <Tooltip placement='top' title={params?.row?.name} arrow>
                                <div>{params?.row?.name.slice(0, 18) + "..."}</div></Tooltip> :
                            params?.row?.name : "-"}
                    </Box>)
                },
                {
                    field: "is_active", flex: 0.1, headerName: t("Status"),
                    renderCell: (params) => (<Box component={'div'}>
                        <Typography color={params?.row?.is_active ? "red" : "orange"}>{params?.row?.is_active ? "Active" : "Inactive"}</Typography>
                    </Box>)
                },
                {
                    field: "comments", flex: 0.22, headerName: t("Comments"),
                    renderCell: (params) => (<Box component={'div'}>
                        {params?.row?.comments ? params?.row?.comments?.length > 35 ?
                            <Tooltip placement='top' title={params?.row?.comments} arrow>
                                <div>{params?.row?.comments.slice(0, 35) + "..."}</div></Tooltip> :
                            params?.row?.comments : "-"}
                    </Box>)
                },
                {
                    field: "action", flex: 0.14, headerName: t("Action"), headerAlign: "center", align: "center",
                    renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                        <CommonEditButton testID={"allergy_edit"} size={"1.5vw"} disable={this.props?.isLocked} onClick={this.onEditHandler.bind(this, params)} />
                        <CommonDeleteButton testID={"allergy_delete"} size={"1.5vw"} disable={this.props?.isLocked || this.state.EditItem?.id === params?.row?.id} onClick={this.onDeleteHandler.bind(this, params)} />
                    </Box>
                }
            ]
            return (
                <ShowComponents onIntersection={this.GetAllerydata.bind(this)}>
                    <Box height={'30vh'} overflow={'auto'}>
                        <DataGrid
                            rows={this.state.Allerydata}
                            columns={columns}
                            getRowId={(row) => row['sno']}
                            headerHeight={40}
                            hideFooter={true}
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack className='eMed_DocNotes_nodata'>
                                        {t("NoRecordsFound")}
                                    </Stack>
                                )
                            }}
                            disableSelectionOnClick
                        />
                    </Box>
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.isDeleteCliked ? <DeletePopup
                        DeletTitle={`${t("deleteMsg")} ${t("Allergy")} ${this.state.SelectedList ? this.state.SelectedList?.name : "-"} ?`}
                        deleteAlertPopupClose={this.popupClose.bind(this)}
                        removeData={this.DeleteAllergy.bind(this)}
                    /> : null}
                </ShowComponents>
            )
        }
        catch (e) {

        }
    }
}
export default withTranslation()(DocAllergiesLeft)
