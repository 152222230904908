import React, { Component } from 'react';
import { ClickableCard } from '../Components/Common Components/CommonComponents';
import { Button, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { ImagePaths } from '../Utility/ImagePaths';
import './Dashboard.css';
import { Trans, withTranslation } from 'react-i18next'
import RestAPIService from '../Utility/Services/RestAPIService';
import { Serviceurls } from '../Utility/API/Serviceurls';
import { localClearAll, localClearItem, localGetItem, localSetItem } from '../Utility/Services/CacheProviderService';
import ToastMsg from '../Components/ToastMsg/ToastMsg';
import axios from "axios";
import Loader from '../Components/Loader'

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedClinicID: null,
      SelectedClinicName: null,
      SelectedModuleName: null,
      SelectedModuleID: null,
      SelectedModuleIDKey: null,
      ClinicList: [],
      ModuleList: [],
      isLoader:true,
      nurseClinicID: null,
    }
  }
  componentDidMount() {
    this.getModulesAPI()
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    }, () => {
      setTimeout(() => {
        this.setState({ isErrorMsg: false, isErrorMsgText: "" })
      }, 2000);
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    }, () => {
      setTimeout(() => {
        this.setState({ successMsg: false, successMsgText: "" })
      }, 2000);
    })
  }

  
  msgClose() {
    this.setState({
        isErrorMsg: false,
        isErrorMsgText: '',
        successMsg: false,
        successMsgText: ''
    })
}

  getModulesAPI = () => {
    try {
      RestAPIService.LoginAuthGetModules(Serviceurls.GET_MODULES_LIST).
        then((response) => {
          if (response.data.status === "success") {
            // Store Modules List in LocalStorage
            if(response.data.data?.length > 0){
              var ModulesList = JSON.stringify(response.data.data);
              localSetItem("ModulesList", ModulesList)
            }

            if(response.data.data?.length === 1 && response.data.data[0].userlinked_module){
              this.modulesData(response.data?.data[0]?.userlinked_module[0]?.moduels,response.data?.data[0]?.userlinked_module[0]?.id)
            }else if(response.data.data?.length === 1 && response.data.data[0].data?.length === 1){
              this.setState({
                SelectedClinicID: response.data?.data[0]?.branch_id,
                SelectedClinicName:response.data?.data[0]?.barnch_name
              },()=>{
                this.modulesData(response.data?.data[0]?.data[0]?.moduels,response.data?.data[0]?.data[0]?.id, response.data?.data[0]?.data[0]?.clinic_id)
              })
            }else{
              this.setState({
                ClinicList: response.data.data,
                isLoader:false
              }, ()=>{
                if(response.data?.data.length === 1){
                  this.setState({
                    SelectedClinicID: response.data?.data[0]?.branch_id,
                    SelectedClinicName:response.data?.data[0]?.barnch_name,
                    ModuleList: response.data?.data[0]?.data,
                  })
                }
              })
            }
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
          setTimeout(()=>{
            localClearAll()
            this.props.history.push({ pathname: '/'})
            }, 2000)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  modulesData = (ModuleName , ModuleId, nurseClinicID = null) => {
   let module_name = ModuleName === "Standalone Pharmacy" ? "Pharmacy" : ModuleName;
    try {
      this.setState({
        isLoader:true
      }, ()=>{
        RestAPIService.LoginAuthGetModules(Serviceurls.MODULES_DATA_GET + "?linked_modules=" + module_name + "&id=" + ModuleId + "&clinic_id=" + nurseClinicID).
        then((response) => {
          if (response.data.status === "success") {
            localSetItem("isLoggedIn", true);
            localSetItem("AccessToken", response.data.data.token);
            localSetItem("BranchId", this.state.SelectedClinicID);
            localSetItem("BranchName", this.state.SelectedClinicName);
            var loginUser = JSON.stringify(response.data.data);
            let smsConfig = JSON.stringify(response.data.data?.sms_config);
            localSetItem("loggedInUserInfo", loginUser)
            localSetItem("smsConfig", smsConfig)
            localSetItem('auto_log_out_time',response.data.data?.auto_log_out_time ? response.data.data?.auto_log_out_time  : 15)
            this.setState({
              isLoader:false
            })
            this.PushHistory(response.data.data.module_name)
          }
        }).catch((error) => {
          // if (error.response.data.status === "fail") {
          //   this.errorMessage(error.response.data.message)
          // } else {
            this.setState({
              isLoader:false
            }, ()=>{ this.errorMessage(error.message) })
          // }
        })
      })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  getDoctorsList() {
    try {
      RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME).
        then((response) => {
          if (response.data.status === "success") {
            let doctor_ist = response.data.data ? response.data.data.filter((item) => (item.specialization_active === true)) : []
            let doctorList = JSON.stringify(doctor_ist);
            localSetItem("DoctorsList", doctorList)
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  PushHistory = (Module) => {
    if (Module === "front_office") {
      this.getDoctorsList();
      this.props.history.push({ pathname: '/OPHome', state: { NewLogin: true } })
    } else if (Module === "Laboratory") {
      this.getDoctorsList();
      this.props.history.push({ pathname: '/MainHome', state: { NewLogin: true } })
    } else if (Module === "nurse") {
      this.props.history.push({ pathname: '/OPHome', state: { NewLogin: true } })
    } else if (Module === "Pharmacy") {
      this.props.history.push({ pathname: '/PharmacyBilling', state: { NewLogin: true } })
    }else if (Module === "sub_location") {
      this.props.history.push({ pathname: '/SubLocationBilling', state: { NewLogin: true } })
    }else if(Module === "ot_management"){
      this.props.history.push({ pathname: '/OTHome', state: { NewLogin: true } })
    }else if(Module === "counsellor"){
      this.props.history.push({ pathname: '/Counsellor', state: { NewLogin: true } })
    }else if(Module === 'Doctor' || Module === 'Optometry'){
      this.props.history.push({ pathname: '/DoctorHome', state: {NewLogin : true } })
    }else if(Module === 'Radiology'){
      this.props.history.push({ pathname: '/RadiologyHome', state: { NewLogin: true } })
    }else if(Module === 'Organization'){
      this.props.history.push({ pathname: '/AdminDashboard', state: {NewLogin : true } })
    }else if(Module === "canteen"){
      this.props.history.push({ pathname: '/CanteenHome', state: {NewLogin : true } })
    }else if(Module === 'asset_management'){
      this.props.history.push({ pathname: '/AssetHome', state: {NewLogin : true } })
    }
  }

  ClinicSelection = (clinic) => {
    this.setState({
      SelectedClinicID: clinic.branch_id,
      SelectedClinicName:clinic.barnch_name,
      ModuleList: clinic.data,
      SelectedModuleName: null,
      SelectedModuleID: null,
      SelectedModuleIDKey: null,
      nurseClinicID: null,
    })
  }

  ModuleSelection = (clinic) => {
    this.setState({
      SelectedModuleName: clinic.moduels,
      SelectedModuleID: clinic.id,
      SelectedModuleIDKey: clinic.id + clinic.moduels,
      nurseClinicID: clinic.clinic_id,
    },()=>{
      this.modulesData(this.state.SelectedModuleName , this.state.SelectedModuleID, this.state.nurseClinicID ? this.state.nurseClinicID : '')
    })
  }

  returnIcon(Module){
    switch(Module){
      case "clinic" : return ImagePaths.Hospital.default 
      case "front_office" : return ImagePaths.FrontOfficeLogo.default
      case "Laboratory" : return ImagePaths.Laboratory.default
      case "Pharmacy" : return ImagePaths.PharmacyCard.default
      case "sub_location" : return ImagePaths.PharmacyDashboard.default
      case "ot_management" : return ImagePaths.OT.default
      case "counsellor" : return ImagePaths.Counsellor.default
      case 'Doctor' : return ImagePaths.DoctorIcon.default
      case "Radiology" : return ImagePaths.Radiology.default
      case 'canteen' : return ImagePaths.CanteenModule.default
      case 'asset_management' : return ImagePaths.AssetManagement.default
      default : return ImagePaths.FrontOffice.default
    }
  }

  render() {
    const { history } = this.props;
    const { t } = this.props
    return (
      <div className='eMed_ModuleScreen_Wrapper'>
        <div className='eMed_Main_div'>
          <div>
            <Divider textAlign='left' sx={{ margin: '0.7vw' }}>
              <Typography component={'p'}>{t('SelectClinic')}</Typography>
            </Divider>
          </div>
          <div className='eMed_Cards_Wrapper' style={{ maxHeight : '20vh'}}>
            {
              this.state.ClinicList.map((item, index) => (
                <ClickableCard
                  isSelected={item.branch_id === this.state.SelectedClinicID ? true : false}
                  icon={this.returnIcon("clinic")}
                  label={item.barnch_name?.split('_').join(' ')}
                  onClick={this.ClinicSelection.bind(this, item)}
                  alt={item.barnch_name}
                  testID={`${'dash_' + item.barnch_name}`}/>
              ))
            }
          </div>
          {
            this.state.SelectedClinicID != null ?
              <div>
                <Divider textAlign='left' sx={{ margin: '0.7vw' }}>
                  <Typography component={'p'}>{t('SelectModule')}</Typography>
                </Divider>
              </div> : null
          }
          <div className='eMed_Cards_Wrapper'>
            {
              this.state.SelectedClinicID != null ?
                this.state.ModuleList.map((item, index) => (
                  <ClickableCard
                    isSelected={item?.id + item?.moduels === this.state.SelectedModuleIDKey ? true : false}
                    icon={this.returnIcon(item.moduels)}
                    label={item.label}
                    onClick={this.ModuleSelection.bind(this, item)}
                    alt={item.moduels}
                    testID={`${'dash_' + item.moduels}`} />
                )) : null
            }
          </div>
        </div>
        {/* {
          this.state.SelectedModuleID !== null ?
            <div className='eMed_Proceed_Btn'>
              <Button emed_tid='dash_proceed' sx={{ textTransform: 'capitalize' }} variant='contained'
                onClick={() => {
                  this.modulesData(this.state.SelectedModuleName , this.state.SelectedModuleID, this.state.nurseClinicID ? this.state.nurseClinicID : '')
                }}
              >{t('ProceedBtn')}</Button>
            </div> : null
        } */}
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
         <Loader loaderOpen={this.state.isLoader} />
      </div>
    )
  }
}
export default withTranslation()(Dashboard)