import React, { Component } from 'react'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { Autocomplete, Box, Button, Chip, CircularProgress, FormControl, InputLabel, MenuItem, Modal, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import { Colors } from '../../../../Styles/Colors'
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService'
import '../DoctorNotes.css'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import { Allergy_Type_List, Status } from '../../../../Utility/Constants'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { DateTime } from 'luxon'
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import DescriptionIcon from '@mui/icons-material/Description';
import ShowComponents from './ShowComponent';

export default class DocAllergies extends Component {
  constructor(props) {
    super(props)
    this.state = {
      patientDetails: {},
      clinicId: this.props.clinicId,
      appointmentId: this.props.appointmentId,
      patientId: this.props.patientId,
      openPopUp: false,
      allergyTypeList: Allergy_Type_List,
      status: Status,
      allergyNameList: [],
      allergiesData: [],
      selectedAllergyName: '',
      selectedAllergyType: '',
      selectedStatus: '',
      selectedId: null,
      loading: false,
      allergyDescrp: '',
      deletePopup: false,
      removeData: false,
      isEdit: false,
      postLoading: false,
      isLocked: false
    }
  }

  componentDidMount() {
    // let clinicId = getCachevalue('SelectedDoctorClinic')
    // let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
    // this.setState({
    //   clinicId: clinicId,
    //   appointmentId: appointmentId?.appointment_id,
    //   patientId: appointmentId?.patient_id,
    //   isLocked: appointmentId?.is_locked
    // }, () => {
    //   this.getAllergiesData()
    // })
    // this.apiCalls();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLocked !== this.props.isLocked) {
      this.setState({ isLocked: this.props.isLocked })
    }
  }

  apiCalls = () => {
    this.getAllergiesData()
    this.getAllergyNameList()
  }

  getAllergyNameList = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_ALLERGY_FEQ)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              allergyNameList: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getAllergiesData = () => {
    try {
      let appointmentId = this.props.prevAppointmentId ? this.props.prevAppointmentId : this.props.appointmentId
      this.setState({ loading: true })
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_ALLERGIES + `?patient_id=${this.props.patientId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              allergiesData: response.data.data,
              loading: false
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ loading: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ loading: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ loading: false })
      this.errorMessage(error.message)
    }
  }

  postAllergiesData = () => {
    try {
      let data = {
        appointment_id: this.props.appointmentId,
        patient_id: this.props.patientId,
        allergies: {
          allergy_type: this.state.selectedAllergyType,
          comments: this.state.allergyDescrp,
          diagnosed_date: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
          id: this.state.selectedId ? this.state.selectedId : null,
          is_active: this.state.selectedStatus,
          name: this.state.selectedAllergyName
        }
      }
      let validate = this.checkValidation()
      if (validate === null) {
        this.setState({ postLoading: true })
        RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_ALLERGIES)
          .then((response) => {
            if (response?.data?.status === 'success') {
              this.successMessage(response?.data?.message)
              this.onClearHandler()
              this.getAllergiesData()
              this.setState({ openPopUp: false, postLoading: false, isEdit: false })
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.setState({ postLoading: false })
              this.errorMessage(error.response.data.message)
            } else {
              this.setState({ postLoading: false })
              this.errorMessage(error.message)
            }
          })
      } else {
        this.errorMessage(validate)
      }
    } catch (error) {
      this.setState({ postLoading: false })
      this.errorMessage(error.message)
    }
  }

  DeleteAllergiesData = () => {
    try {
      let data = {}
      this.setState({ removeData: true })
      RestAPIService.delete(Serviceurls.DOC_DOCTOR_NOTES_ALLERGIES + `?id=${this.state.selectedId}`, data)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.successMessage(response?.data?.message)
            this.getAllergiesData()
            this.setState({ deletePopup: false, removeData: false })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ removeData: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ removeData: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ removeData: false })
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  popupClose = () => {
    try {
      this.setState({
        deletePopup: false
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearHandler = () => {
    try {
      this.setState({
        selectedAllergyName: '',
        selectedAllergyType: '',
        selectedStatus: '',
        selectedId: null,
        allergyDescrp: ''
      }, () => this.setState({ openPopUp: true }))
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onEditHandler = (item) => {
    try {
      this.setState({
        isEdit: true,
        selectedAllergyName: item?.name,
        selectedAllergyType: (item?.allergy_type)?.toString(),
        selectedStatus: item?.is_active,
        selectedId: item?.id,
        allergyDescrp: item?.comments
      }, () => this.setState({ openPopUp: true }))
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onDeleteHandler = (item) => {
    try {
      if (!this.state.isEdit) {
        this.setState({
          deletePopup: true,
          selectedId: item?.id
        })
      } else {
        this.errorMessage("You have clicked edit Button.So,You cannot able to delete")
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  checkValidation = () => {
    try {
      let validate = null;
      let states = this.state
      if ((states.selectedAllergyName === '') && (states.selectedAllergyType === '') && (states.selectedStatus === '')) {
        validate = 'Select Allergy Name,Type and Status'
      } else if (states.selectedAllergyName === '') {
        validate = 'Select Allergy Name'
      } else if (states.selectedAllergyType === '') {
        validate = 'Select Allergy Type'
      } else if (states.selectedStatus === '') {
        validate = 'Select Status'
      }
      return validate;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderPopUpScreen = () => {
    try {
      return (
        <Box>
          <Modal
            open={this.state.openPopUp}
          >
            <Box component={'div'} className='eMed_Doc_Notes_Pop_Up'>
              <Box p={'1vw'} component={'div'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Add New Allergies</Typography>
                <Button
                  className='Common_Btn_Min_Width'
                  onClick={() => this.setState({ openPopUp: false, isEdit: false })}
                >
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                </Button>
              </Box>
              <Box component={'div'} height={'24vw'} overflow={'scroll'} pl={'1vw'}>
                <Box component={'div'}>
                  <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Allergy Type</Typography>
                  </Box>
                  <Box component={'div'}>
                    {this.state.allergyTypeList?.map((item, index) => (
                      <Chip
                        className={`eMed_chip ${item.value === this.state.selectedAllergyType ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                        label={item.label}
                        variant={item.value === this.state.selectedAllergyType ? 'contained' : 'outlined'}
                        key={index}
                        onClick={() => {
                          if (item.value === this.state.selectedAllergyType) {
                            this.setState({ selectedAllergyType: '' })
                          } else {
                            this.setState({ selectedAllergyType: item?.value })
                          }
                        }}
                        // color={'primary'}
                        clickable
                      />
                    ))}
                  </Box>
                </Box>
                <Box component={'div'}>
                  <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Allergy Name</Typography>
                  </Box>
                  <Box component={'div'}>
                    <Autocomplete
                      clearIcon
                      size='small'
                      sx={{ width: '23vw' }}
                      freeSolo
                      options={this.state.allergyNameList.map((item) => item)}
                      value={this.state.selectedAllergyName}
                      limitTags={3}
                      autoComplete='off'
                      onChange={(event, newValue) => {
                        this.setState({
                          selectedAllergyName: newValue
                        })
                      }}
                      onInputChange={(event, newValue) => {
                        this.setState({ selectedAllergyName: newValue })
                      }}
                      renderInput={(params) => <TextField {...params} label='Select or Enter Allergy Name *' />}
                    />
                  </Box>
                </Box>
                <Box component={'div'}>
                  <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Status</Typography>
                  </Box>
                  <Box component={'div'}>
                    {this.state.status?.map((item, index) => (
                      <Chip
                        className={`eMed_chip ${item.value === this.state.selectedStatus ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                        label={item.label}
                        variant={item.value === this.state.selectedStatus ? 'contained' : 'outlined'}
                        key={index}
                        onClick={() => {
                          if (item.value === this.state.selectedStatus) {
                            this.setState({ selectedStatus: '' })
                          } else {
                            this.setState({ selectedStatus: item?.value })
                          }
                        }}
                        // color={'primary'}
                        clickable
                      />
                    ))}
                  </Box>
                </Box>
                <Box component={'div'}>
                  <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Description</Typography>
                  </Box>
                  <Box component={'div'}>
                    <TextField
                      multiline
                      label='Description'
                      rows={3}
                      sx={{ width: '23vw' }}
                      value={this.state.allergyDescrp}
                      onChange={(e) => {
                        let value = e.target.value
                        if (value?.length < 250) {
                          this.setState({ allergyDescrp: e.target.value })
                        } else {
                          this.errorMessage('Allowed only 250 characters')
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
                <Button
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                  variant='outlined'
                  onClick={() => this.onClearHandler()}
                >Clear</Button>
                <Button
                  disabled={this.state.postLoading}
                  sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                  variant='contained'
                  onClick={() => this.postAllergiesData()}
                >Save</Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    return (
      <ShowComponents onIntersection={this.apiCalls.bind(this)}>
        <Box component={'div'} sx={{ backgroundColor: Colors.Background }} p={'0.5vw'}>
          <Box component={'div'} height={'2.8vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
            <Box component={'div'}>
              <Typography fontWeight={600} fontSize={'.8vw'}>Allergies</Typography>
              <Typography fontSize={'0.8vw'} color={'gray'}>Allergies data added by
                <Typography component={'span'} fontWeight={600} fontSize={'0.8vw'} color={Colors.SecondaryText}> Hospital Nurse</Typography>
              </Typography>
            </Box>
            <Box component={'div'}>
              <Button
                variant='contained'
                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                onClick={() => this.postAllergiesData()}
                disabled={this.state.isLocked || this.props.isLocked}
              >Add</Button>
            </Box>
          </Box>
          <Box display={'flex'} justifyContent={'space-between'}>
            <FormControl size='small'>
              <InputLabel id="de-simple-select-label">Allergy Type</InputLabel>
              <Select
                labelId="de-simple-select-label"
                id="demo-simp-select"
                sx={{ width: '10vw' }}
                value={this.state.selectedAllergyType}
                label="Allergy Type"
                onChange={(e) => {
                  this.setState({
                    selectedAllergyType: e.target.value
                  })
                }}
              >
                {this.state.allergyTypeList?.map((item, index) => (
                  <MenuItem key={index} value={item?.value}>{item?.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Autocomplete
              clearIcon
              size='small'
              sx={{ width: '10vw' }}
              freeSolo
              options={this.state.allergyNameList.map((item) => item)}
              value={this.state.selectedAllergyName}
              limitTags={3}
              autoComplete='off'
              onChange={(event, newValue) => {
                this.setState({
                  selectedAllergyName: newValue
                })
              }}
              onInputChange={(event, newValue) => {
                this.setState({ selectedAllergyName: newValue })
              }}
              renderInput={(params) => <TextField {...params} label='Allergy Name *' />}
            />
            <FormControl size='small'>
              <InputLabel id="de-simple-select-label">Allergy Type</InputLabel>
              <Select
                labelId="de-simple-select-label"
                id="demo-simp-select"
                sx={{ width: '10vw' }}
                value={this.state.selectedStatus}
                label="Allergy Type"
                onChange={(e) => {
                  this.setState({
                    selectedStatus: e.target.value
                  })
                }}
              >
                {this.state.status?.map((item, index) => (
                  <MenuItem key={index} value={item?.value}>{item?.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box component={'div'} sx={{ backgroundColor: Colors.white }} pt={'0.5vw'}>
            <Box component={'div'} sx={{ border: '1px solid lightgray', maxHeight: '10vw', overflow: 'auto' }}>
              <Table stickyHeader size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                      <Typography fontWeight={600} fontSize={'0.8vw'} color={Colors.SecondaryText}>Allergy Type</Typography>
                    </TableCell>
                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                      <Typography fontWeight={600} fontSize={'0.8vw'} color={Colors.SecondaryText}>Allergy Name</Typography>
                    </TableCell>
                    <TableCell sx={{ backgroundColor: Colors.Background }}>
                      <Typography fontWeight={600} fontSize={'0.8vw'} color={Colors.SecondaryText}>Status</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    this.state.allergiesData?.length > 0 ? this.state.allergiesData?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Box component={'div'} display={'flex'} alignItems={'center'}>
                            <Button className='Common_Btn_Min_Width'
                              onClick={() => this.onDeleteHandler(item)}
                            >
                              <Box height={'1vw'} width={'1vw'} component={'img'} src={ImagePaths.DeleteOutline.default} />
                            </Button>
                            {item?.allergy_type === 1 ?
                              <Typography fontSize={'0.8vw'} fontWeight={600}>Medication</Typography> :
                              <Typography fontSize={'0.8vw'} fontWeight={600}>Non-Medication Allergy</Typography>
                            }
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box component={'div'} display={'flex'} alignItems={'center'}>
                            {item?.name ?
                              item?.name?.length > 10 ?
                                <Tooltip placement='top' title={item?.name}>
                                  <Typography fontSize={'0.8vw'} fontWeight={600} mr={'0.5vw'}>{item?.name?.slice(0, 10) + '...'}</Typography>
                                </Tooltip> :
                                <Typography fontSize={'0.8vw'} fontWeight={600} mr={'0.5vw'}>{item?.name}</Typography> : '-'
                            }
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box component={'div'} display={'flex'} alignItems={'center'}>
                            <Typography fontSize={'0.8vw'} fontWeight={600} mr={'0.5vw'}>{item?.is_active? "Active": "Inactive"}</Typography>
                            <Button className='Common_Btn_Min_Width'
                              disabled={this.state.isLocked || this.props.isLocked}
                              onClick={() => this.onEditHandler(item)}
                            >
                              <Tooltip placement='top' title={'Edit'}>
                                <Box height={'1.1vw'} width={'1.1vw'} component={'img'} src={ImagePaths.UserEditOn.default} />
                              </Tooltip></Button>
                            {item?.comments ? <Tooltip placement='top' title={item?.comments}><DescriptionIcon color='primary' /></Tooltip> : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    )) : <TableRow>

                      <TableCell align='right'>No Records To Be Shown</TableCell>
                      <TableCell />
                    </TableRow>}
                </TableBody>
              </Table>
            </Box>
          </Box>
          {this.renderPopUpScreen()}
          {this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.deletePopup ? <DeletePopup
            DeletTitle={`Are you sure you want to delete ?`}
            deleteAlertPopupClose={this.popupClose.bind(this)}
            removeData={this.DeleteAllergiesData.bind(this)}
            disable={this.state.removeData}
          /> : null}
        </Box>
      </ShowComponents>
    )
  }
}
