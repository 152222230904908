import { Autocomplete, Box, Button, Stack, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { Colors } from '../../../../Styles/Colors';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents';
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';

class DocCounsellingRight extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clinicId: localGetItem('SelectedDoctorClinicId'),
      surgeryNameData: [],
      doctorNameData: [],
      anaesthetistNameData: [],
      counsellorData: {},
      surgery: '',
      package: '',
      doctor: '',
      anaesthetist: '',
      counsellor: '',
      notes: '',
      isLocked: false,
      postLoad: false,
      TansmitDisable: false,
      transmitCounsellor: null,
      appointmentDetails: {},
      surgeryName: null
    }
  }

  componentDidMount() {
    if (this.props.EditCounsellingData && Object.keys(this.props.EditCounsellingData).length !== 0) {
      let PropsData = this.props.EditCounsellingData?.surgery_details
      this.setState({
        surgeryName: PropsData?.surgery_name,
        doctor : PropsData?.surgeon_name,
        package: PropsData?.package_name,
        anaesthetist : PropsData?.anaesthetist,
        notes : PropsData?.notes,
        counsellorData : this.props.EditCounsellingData
      }, ()=>{ 
        this.getCounsellorData(PropsData?.counsellor_id)
        this.props.ClearPropsData()
       })
    }else{
      this.getCounsellorData()
    }
    this.getDoctorListData()
  }

  componentDidUpdate(PrevProps){
    if (PrevProps?.EditCounsellingData !== this.props.EditCounsellingData && this.props.EditCounsellingData && Object.keys(this.props.EditCounsellingData).length !== 0) {
      let PropsData = this.props.EditCounsellingData?.surgery_details
      this.setState({
        surgeryName: PropsData?.surgery_name,
        doctor : PropsData?.surgeon_name,
        package: PropsData?.package_name,
        anaesthetist : PropsData?.anaesthetist,
        notes : PropsData?.notes,
        counsellorData : this.props.EditCounsellingData,
        transmitCounsellor : this.state.counsellorData?.counsellor_details?.find((list)=>(list?.counsellor_id === PropsData?.counsellor_id))
      })
      this.props.ClearPropsData()
    }
  }


  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  ClearData() {
    this.setState({
      surgery: '',
        package: '',
        doctor: '',
        anaesthetist: '',
        counsellor: '',
        notes: '',
        surgeryNameData: [],
        surgeryName: "",
        isDropdownOpen: false,
        transmitCounsellor: null
    })
  }


  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  getDoctorListData = () => {
    try {
      RestAPIService.getAll(Serviceurls.HOME_GET_CLINIC_DOCTORS + `?clinic_id=${this.state.clinicId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              doctorNameData: response?.data?.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getSurgeryNameList = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_COUNSELLING_SURGERY_SEARCH + `?surgery_key=${this.state.surgeryName}&clinic_id=${this.state.clinicId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              isDropdownOpen: true,
              surgeryNameData: response?.data?.data ? response?.data?.data : []
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getCounsellorData = (counsellor_ID = null) => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_SURGERY_DETAILS + `?patient_id=${this.props.PatientData?.patient_id}&appointment_id=${this.props.PatientData?.appointment_id}&clinic_id=${this.state.clinicId}`)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              counsellorData: response?.data?.data,
              transmit: response?.data?.data?.surgery_details?.id ? true : false,
              status: response?.data?.data?.surgery_details?.status
            }, () => {
              this.setState({
                TansmitDisable: response?.data?.data?.surgery_details?.status === "Transmitted" || response?.data?.data?.surgery_details?.status === "Approved" ? true : false,
                transmitCounsellor : counsellor_ID ? response?.data?.data?.counsellor_details.find((list)=>(list?.counsellor_id === counsellor_ID)) : null
              })
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  validate = () => {
    try {
      let message = null
      if ((this.state.transmitCounsellor?.counsellor_name || this.state.counsellor) &&
        (this.state.doctor) && (this.state.anaesthetist) &&
        (this.state.transmitCounsellor?.counsellor_id || this.state.counsellor_ID)) {
          message = null
      } else {
        message = 'Enter all mandatory fields'
      }
      return message;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  handleOptionSelect = (option) => {
    this.setState({
      surgeryName: option?.surgery_names,
      isDropdownOpen: false,
      surgery: option,
      package: option?.package_name,
      transmit: false
    }, () => {
      this.setState({ surgeryNameData: [] })
    });
  };

  postCounsellerTransmit = () => {
    try {
      let data = {
        anaesthetist: this.state.anaesthetist,
        appointment_id: this.props.PatientData?.appointment_id,
        clinic_id: this.state.clinicId,
        counsellor_name: this.state.transmitCounsellor?.counsellor_name ? this.state.transmitCounsellor?.counsellor_name : this.state.counsellor,
        counsellor_id: this.state.transmitCounsellor?.counsellor_id ? this.state.transmitCounsellor?.counsellor_id : this.state.counsellor_ID,
        notes: this.state.notes ? this.state.notes : null,
        package_id: this.state.package == "" ? null : this.state.surgery?.id ? this.state.surgery?.id : this.state.counsellorData?.surgery_details?.package_id ? this.state.counsellorData?.surgery_details?.package_id : null,
        patient_id: this.props.PatientData?.patient_id,
        surgeon_name: this.state.doctor ? this.state.doctor : this.state.counsellorData?.surgery_details?.surgeon_name,
        surgery_id: this.state.package == "" ? null : this.state.surgery?.id ? this.state.surgery?.id : this.state.counsellorData?.surgery_details?.surgery_id ? this.state.counsellorData?.surgery_details?.surgery_id : null,
        status: null,
        id: this.state.counsellorData?.surgery_details?.id ? this.state.counsellorData?.surgery_details?.id : null
      }

      let check = this.validate()
      if (check === null) {
        RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_SURGERY_DETAILS)
          .then((response) => {
            if (response?.data?.status === 'success') {
              this.successMessage(response?.data?.message)
              this.props.CallgetApi('counselling')
              this.ClearData()
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      } else {
        this.errorMessage(check)
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    const { t } = this.props
    let states = this.state
    return (
      <Box>
        <Typography className='eMed_refdoc_title'>{t("Counselling")}</Typography>
        <Box height={"56.3vh"} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', overflowY: 'scroll' }}>
          <Box component={'div'} maxHeight={'100vh'} overflow={'scroll'}>
            <Box component={'div'} display={'flex'} justifyContent={'space-between'} m={'0.5vw'} flexDirection={'column'}>
              <Box>
                <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Select Surgery</Typography>
                </Box>
                <Box component={'div'} ml={'0.5vw'}>
                  <div className="custom-dropdown" ref={this.dropdownRef}>
                    <TextField
                      label='Search and Select Surgery'
                      autoComplete="off"
                      size='small'
                      sx={{ width: '27vw' }}
                      // onClick={() => { this.handleToggleDropdown() }}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        this.setState({ package: '', surgeryName: newValue }, () => {
                          if (newValue.trim() === "") {
                            this.setState({ surgeryNameData: [], isDropdownOpen: false, surgery: null });
                          } else if (this.state.surgeryName.length > 0) {
                            this.getSurgeryNameList();
                          }
                        })
                      }}
                      value={this.state.surgeryName ? this.state.surgeryName : ""}
                    />
                    {(this.state.surgeryNameData.length > 0 && this.state.isDropdownOpen) && (
                      <div className="dropdown-content">
                        {this.state.surgeryNameData.map((option, index) => (
                          <div
                            className="dropdown-item"
                            key={index}
                            onClick={() => { this.handleOptionSelect(option) }}
                          >
                            {`${option?.surgery_names} ${option?.package_name ? (" - " + option?.package_name) : ""}`}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </Box>
              </Box>
              <Box>
                <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Select Package </Typography>
                </Box>
                <Box component={'div'} ml={'0.5vw'}>
                  <TextField
                    size='small'
                    sx={{ width: '27vw' }}
                    disabled={true}
                    value={this.state.package}
                    label='Package Name'
                    InputProps={{ readOnly: true }}
                  />
                </Box>
              </Box>
              <Box>
                <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Surgeon Name *</Typography>
                </Box>
                <Box component={'div'} ml={'0.5vw'}>
                  <Autocomplete
                    clearIcon
                    size='small'
                    sx={{ width: '27vw' }}
                    options={this.state.doctorNameData?.map((item) => item?.first_name)}
                    value={this.state.doctor}
                    limitTags={3}
                    autoComplete='off'
                    onChange={(event, newValue) => {
                      this.setState({
                        doctor: newValue,
                        transmit: false
                      })
                    }}
                    renderInput={(params) => <TextField {...params}
                      label='Select Surgeon'
                    />}
                  />
                </Box>
              </Box>
            </Box>
            <Box component={'div'} display={'flex'} justifyContent={'space-between'} ml={'0.5vw'} flexDirection={'column'}>

              <Box component={'div'} mr={'1vw'}>
                <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Select Anaesthetist *</Typography>
                </Box>
                <Box component={'div'} ml={'0.5vw'}>
                  <Autocomplete
                    clearIcon
                    size='small'
                    sx={{ width: '27vw' }}
                    options={this.state.doctorNameData?.map((item) => item?.first_name)}
                    value={this.state.anaesthetist}
                    limitTags={3}
                    autoComplete='off'
                    onChange={(event, newValue) => {
                      this.setState({
                        anaesthetist: newValue,
                        transmit: false
                      })
                    }}
                    renderInput={(params) => <TextField {...params}
                      label='Select Anaesthetist'
                    />}
                  />
                </Box>
              </Box>
              <Box component={'div'}>
                <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Select Counsellor *</Typography>
                </Box>
                <Autocomplete
                  clearIcon
                  size='small'
                  sx={{ width: '27vw', ml: '0.2vw' }}
                  options={this.state.counsellorData?.counsellor_details}
                  getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.counsellor_name}
                  value={this.state.transmitCounsellor}
                  limitTags={3}
                  autoComplete='off'
                  onChange={(event, newValue) => {
                    this.setState({
                      transmitCounsellor: newValue,
                      transmit: false,
                      counsellor_ID: "",
                      counsellor: ""
                    })
                  }}
                  renderInput={(params) => <TextField {...params}
                    label='Select Counsellor'
                  />}
                />
              </Box>
              <Box width={'27vw'} ml={'1vw'}></Box>
            </Box>
            <Box component={'div'}>
              <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Select Notes</Typography>
              </Box>
              <TextField
                multiline
                rows={3}
                fullWidth
                value={this.state.notes}
                sx={{ p: '0.5vw' }}
                onChange={(e) => this.setState({ notes: e.target.value, transmit: false })}
                label='Enter Notes'
              />
            </Box>
          </Box>
        </Box>
        <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
          <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
          <Button variant='contained' size="small" disabled={this.state.TansmitDisable} id="eMed_Config_btn" onClick={() => { this.postCounsellerTransmit() }}>{t("Add")}</Button>
        </Stack>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.isDeleteClicked ? <DeletePopup
          DeletTitle={`${t("deleteMsg")}  ${t("Symptom")} ${this.state.SelectedFreq ? this.state.SelectedFreq : "-"} From Suggestion List ?`}
          deleteAlertPopupClose={this.popupClose.bind(this)}
          removeData={this.DelectSymptomFreq.bind(this)}
        /> : null}
      </Box>
    )
  }
}
export default withTranslation()(DocCounsellingRight)

