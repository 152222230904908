import React, { Component } from "react";
import './Configuration.css';
import { ClickableCard } from "../../Components/Common Components/CommonComponents";
import { ImagePaths } from '../../Utility/ImagePaths';
import { withTranslation } from 'react-i18next';
import { Colors } from "../../Styles/Colors";
import { Box, Typography } from "@mui/material";
import { CheckAccessFunc } from "../../Components/CommonFunctions/CommonFunctions";
import { localGetItem } from "../../Utility/Services/CacheProviderService";

let NewServiceConfigList = [
    {
        id: 0, main_title: 'Clinic Configuration',
        sub_tab: [
            { id: 0, sub_title: 'Hospital Configuration', value: 'HospitalConfig', icon: ImagePaths.HospitalConfig.default, Access: CheckAccessFunc("front_office", "Settings", "Hospital Configuration", null, "Tab"), path: "/Settings/HospitalConfig/HospitalConfig" },
            { id: 1, sub_title: "Referral Configuration", value: "ReferralConfig", icon: ImagePaths.referral.default, Access: CheckAccessFunc("front_office", "Settings", "Referral Configuration", null, "Tab"), path: "/Settings/ReferralConfig/SourceConfig" },
            { id: 2, sub_title: "Corporate Configuration", value: "CorporateConfig", icon: ImagePaths.corporateConfig.default, Access: CheckAccessFunc("front_office", "Settings", "Corporate Configuration", null, "Tab"), path: "/Settings/CorporateConfig/CorporateEnroll" },
            { id: 3, sub_title: "Room Configuration", value: "RoomConfig", icon: ImagePaths.RoomConfig.default, Access: CheckAccessFunc("front_office", "Settings", "Room Configuration", null, "Tab"), path: "/Settings/RoomConfig/HospitalConfig" },
            { id: 4, sub_title: "Insurance Configuration", value: "InsuranceConfig", icon: ImagePaths.Insurance.default, Access: CheckAccessFunc("front_office", "Settings", "Insurance Configuration", null, "Tab"), path: "/Settings/InsuranceConfig" },
            { id: 5, sub_title: "SMS Configuration", value: "SmsConfig", icon: ImagePaths.SmsConfig.default, Access: CheckAccessFunc("front_office", "Settings", "SMS Configuration", null, "Tab"), path: "/Settings/SmsConfig/Request" },
        ]
    },
    {
        id: 1, main_title: 'User Configuration',
        sub_tab: [
            { id: 0, sub_title: "User Access Configuration", value: "UserAccess", icon: ImagePaths.UserAccess.default, Access: CheckAccessFunc("front_office", "Settings", "User Access Configuration", null, "Tab"), path: '/Settings/UserAccess/UserSettings' },
            // { id: 1, sub_title: "User Configuration", value: "UserConfig", icon: ImagePaths.userConfig.default, Access: CheckAccessFunc("front_office", "Settings", "User Configuration", null, "Tab") },
            // { id: 1, TabName: "Print Configuration", value: "PrintConfig", icon: ImagePaths.UserAccess.default, Access: CheckAccessFunc("front_office", "Settings", "Print Configuration", null, "Tab") },
            // { id: 2, TabName: "Custom Configuration", value: "CustomConfig", icon: ImagePaths.UserAccess.default, Access: CheckAccessFunc("front_office", "Settings", "Custom Configuration", null, "Tab") },

        ]
    },
    {
        id: 2, main_title: 'Service Configuration',
        sub_tab: [
            { id: 0, sub_title: "OP Service Configuration", value: "OPService", icon: ImagePaths.opConfig.default, Access: CheckAccessFunc("front_office", "Settings", "OP Service Configuration", null, "Tab"), path: '/Settings/OPService/ServiceNameTypeConfig' },
            { id: 1, sub_title: "IP Service Configuration", value: "IPService", icon: ImagePaths.opConfig.default, Access: CheckAccessFunc("front_office", "Settings", "IP Service Configuration", null, "Tab"), path: '/Settings/IPService/IPServiceNameTypeConfig' },
        ]
    },
    {
        id: 3, main_title: 'Layouts/Templates Configuration',
        sub_tab: [
            { id: 0, sub_title: "Layout Configuration", value: "LayoutConfig", icon: ImagePaths.demographicConfig.default, Access: CheckAccessFunc("front_office", "Settings", "Layout Configuration", null, "Tab"), path: '/Settings/DemographicsConfig/DemographicsLayout' },
            { id: 1, sub_title: "Template Configuration", value: "TemplateConfig", icon: ImagePaths.demographicConfig.default, Access: CheckAccessFunc("front_office", "Settings", "Template Configuration", null, "Tab"), path: '/Settings/TemplateConfig/DisSumTemplateConfig' },
            // { id: 4, TabName: "Demographics Layout", value: "DemographicsConfig", icon: ImagePaths.demographicConfig.default, Access: CheckAccessFunc("front_office", "Settings", "Demographics Layout", null, "Tab") },
            //  { id: 0, TabName: "Expense Configuration", value: "ExpenseConfig", icon: ImagePaths.opConfig.default, Access: CheckAccessFunc("front_office", "Settings", "Expense Configuration", null, "Tab") },

        ]
    }
]
class ConfigHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedConfigScreen: "",
            isSelected: false,
            selectedIndex: null,
        }
    }

    RoleWiseAccess(item, ListName) {
        try {
            var UserDetails = JSON.parse(localGetItem("loggedInUserInfo"))
            if (item.value === "UserAccess" && ListName === "UserConfigList") {
                return UserDetails?.is_user ? UserDetails?.is_user : true
            }
            else {
                return true
            }
        }
        catch (e) {
            return true
        }
    }

    newUserConfigNavigation(id, item) {
        this.setState({
            isSelected: true,
            selectedIndex: id ?? null,
            selectedConfigScreen: item?.value
        }, () => {
            let { history } = this.props
            if (this.state.selectedIndex === item?.id) {
                history.push({ pathname: item?.path })
            }
        })
    }

    render() {
        let { isSelected, selectedIndex } = this.state;
        return (
            <Box component='div' className="eMed_Config_Wrapper" style={{ backgroundColor: Colors.configHomeBg }}>
                {NewServiceConfigList?.map((item, index) => {
                    return (
                        <Box component={'div'} className="eMed_Config_Div" key={index}>
                            <Box component={'div'} display={item.sub_tab?.some(item => CheckAccessFunc("front_office", "Settings", item.sub_title, null, "Tab")?.viewAccess) ? 'inline' : 'none'}>
                                <Typography id="eMed_Config_header">{item?.main_title}</Typography>
                            </Box>
                            <Box component={'div'} display={'flex'} flexWrap={'wrap'}>
                                {item?.sub_tab?.map((sub, subIndex) => {
                                    if (CheckAccessFunc("front_office", "Settings", sub.sub_title, null, "Tab")?.viewAccess) {
                                        return (
                                            <Box component={'div'} key={subIndex} id="eMed_Config_mainDiv">
                                                <ClickableCard label={sub.sub_title} icon={sub.icon} width={"4vw"} height={"4vw"} isSelected={isSelected && selectedIndex === subIndex ? true : false} onClick={() => this.newUserConfigNavigation(sub.id, sub)} />
                                            </Box>
                                        )
                                    }
                                })}
                            </Box>
                        </Box>
                    )
                })}
            </Box>
        )
    }
}
export default withTranslation()(ConfigHome);
