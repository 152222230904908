import React, { Component } from "react";
import { Autocomplete, Box, Button, Drawer, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { formatDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import { DateTime } from 'luxon';
import { DataGrid } from '@mui/x-data-grid'
import { withTranslation } from 'react-i18next';
import { CommonGridToolBarWithFilterText, CommonPatientDetails } from "../../../../Components/Common Components/CommonComponents";

const Report_Status = [
    { name: "Approved", value: "Approved" },
    { name: "Claimed", value: "Claimed" },
    { name: "Not Approved", value: "Not Approved" },
]

class InsPatientReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            FilterOpen: false,
            dateIndex: 1,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            InsurancePatientData: [],
            InsuranceFilterList: [],
            TotalBillAmt: 0,
            TotalApprovedAmt: 0,
            TotalDeduction: 0,
            TotalReceivedAmt: 0,
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            companyName: [],
            Status: [],
            IPNumber: ""
        }
    }

    componentDidMount() {
        this.getInsurancePatientData()
        this.getInsuranceList()
    }

    getInsurancePatientData = () => {
        let company = this.state.companyName?.length > 0 ? this.state.companyName?.map(element => element) : [];
        let status = this.state.Status?.length > 0 ? this.state.Status?.map(element => (element.value)) : [];
        try {
            this.LoaderFunction(true);
            RestAPIService.getAll(`${Serviceurls.FO_REPORTS_INSURANCE_PATIENT}?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&insurance_company_name=${company}&status=${status}&ip_number=${this.state.IPNumber}&export_type=`)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.LoaderFunction(false);
                        this.setState({
                            InsurancePatientData: response?.data?.data,
                            TotalBillAmt: response?.data?.bill_amount,
                            TotalApprovedAmt: response?.data?.approved_amount,
                            TotalDeduction: response?.data?.deduction_amount,
                            TotalReceivedAmt: response?.data?.claimed_amount
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getInsuranceList = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_INSURANCE_LIST).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            InsuranceFilterList: response.data.data,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    InsPatientReportPrint = () => {
        let company = this.state.companyName?.length > 0 ? this.state.companyName?.map(element => element) : [];
        let status = this.state.Status?.length > 0 ? this.state.Status?.map(element => (element.value)) : [];
        try {
            RestAPIService.getAll(`${Serviceurls.FO_REPORTS_INSURANCE_PATIENT}?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&insurance_company_name=${company}&status=${status}&ip_number=${this.state.IPNumber}&export_type=pdf`)
                .then((response) => {
                    if (response) {
                        //Create a Blob from the PDF Stream
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        //Open the URL on new Window
                        window.open(fileURL);
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.fromDate) },
            { label: "To Date", value: formatDate(this.state.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} noExport={true} />
            </Box>
        )
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            label={"Bill No"}
                            sx={{ width: "20vw" }}
                            size='small'
                            value={this.state.IPNumber}
                            onChange={(e) => {
                                this.setState({
                                    IPNumber: e.target.value
                                })
                            }}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.InsuranceFilterList}
                            getOptionLabel={(option) => (option?.insurance_name)}
                            value={this.state.companyName}
                            onChange={(event, newValue) => {
                                this.setState({ companyName: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("Select Insurance Company")}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={Report_Status}
                            getOptionLabel={(option) => (option?.name)}
                            value={this.state.Status}
                            onChange={(event, newValue) => {
                                this.setState({ Status: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("Select Status")}
                                />
                            )}
                        />
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                companyName: [], Status: [], IPNumber: "",
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1
                            }, () => this.getInsurancePatientData())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small"
                        onClick={() => {
                            this.getInsurancePatientData()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    render() {
        this.state.InsurancePatientData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "patient_detail", headerName: t("PatientDetails"), flex: 0.21, density: 'compact',
                renderCell: ({ row }) => (<Box component={'div'}><CommonPatientDetails data={row} />
                </Box>)
            },
            {
                field: "admission_date", headerName: t("Insurance Details"), flex: 0.14, density: 'compact',
                renderCell: (params) => (
                    <Box>
                        {params?.row?.policy_company_name ? params?.row?.policy_company_name?.length > 25 ?
                            <Tooltip placement='top' title={params?.row?.policy_company_name} arrow>
                                <Typography fontSize={"0.8vw"} fontWeight={600}>{(params?.row?.policy_company_name ? params?.row?.policy_company_name.slice(0, 25) + '...' : "-")}</Typography>
                            </Tooltip> :
                            <Typography fontSize={"0.8vw"} fontWeight={600}>{(params?.row?.policy_company_name ? params?.row?.policy_company_name : "-")}</Typography> : "-"}
                        <Typography fontSize={"0.8vw"}>{(params?.row?.policy_number ? params?.row?.policy_number : "-")}</Typography>
                    </Box>)
            },
            {
                field: "invoice_number", headerName: t("Admission Details"), flex: 0.143,
                renderCell: (params) => (
                    <Box>
                        <Typography fontSize={"0.8vw"} fontWeight={600}>{(params?.row?.ip_number ? params?.row?.ip_number : "-")}</Typography>
                        <Typography fontSize={"0.8vw"}>{(params?.row?.admission_date ? params?.row?.admission_date : "-")}</Typography>
                    </Box>)
            },
            {
                field: "invoice_date", headerName: t("Claim Details"), flex: 0.143,
                renderCell: (params) => (
                    <Box>
                        <Typography fontSize={"0.9vw"}>{"Approved Amt: " + (params?.row?.approved_amount ? params?.row?.approved_amount : "0")}</Typography>
                        <Typography fontSize={"0.9vw"}>{"Received Amt: " + (params?.row?.claimed_amount ? params?.row?.claimed_amount : "0")}</Typography>
                    </Box>
                )
            },
            {
                field: "module_type", headerName: t("Deductions"), flex: 0.143,
                renderCell: (params) => (
                    <Box>
                        <Typography fontSize={"0.9vw"}>{"Disallowance: " + (params?.row?.disallowance_amount ? params?.row?.disallowance_amount : "0")}</Typography>
                        <Typography fontSize={"0.9vw"}>{"TDS: " + (params?.row?.tds_amount ? params?.row?.tds_amount : "0")}</Typography>
                    </Box>
                )
            },
            {
                field: "policy_status", headerName: `${t("Status")}`, flex: 0.143, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.policy_status ? params?.row?.policy_status : "-"}</Box>)
            }
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard(t("Bill Amount"), this.state.TotalBillAmt)}
                        {AmountsCard(t("Approved Amount"), this.state.TotalApprovedAmt)}
                        {AmountsCard(t("Deductions"), this.state.TotalDeduction)}
                        {AmountsCard(t("Received Amount"), this.state.TotalReceivedAmt)}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        <Button className='eMed_usrconf_btn' onClick={() => { this.InsPatientReportPrint() }}>
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </Button>
                        {/* <Button className='eMed_usrconf_btn'>
                            <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
                        </Button> */}
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.InsurancePatientData}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }

}

export default withTranslation()(InsPatientReport)